
<template>
  <div class="products">
    <div class="products-nav-bar">
      <div class="sort" @mouseover="sortbarState = true" @mouseleave="sortbarState = false">
        <img src="../assets/images/icons/sort.png" alt="">
        <p>{{ sortbarText }}</p>
        <transition name="fade">
          <div class="sort-bar-full" v-if="sortbarState">
            <label for="sort0"> По популярности
              <input type="radio" id="sort0" value="0" v-model="sortType">
            </label>
            <label for="sort1"> По возрастанию цены
              <input type="radio" id="sort1" value="1" v-model="sortType">
            </label>
            <label for="sort2"> По убыванию цены
              <input type="radio" id="sort2" value="2" v-model="sortType">
            </label>
            <label for="sort3"> Сначала новые
              <input type="radio" id="sort3" value="3" v-model="sortType">
            </label>
            <label for="sort4"> Сначала старые
              <input type="radio" id="sort4" value="4" v-model="sortType">
            </label>
          </div>
        </transition>
      </div>

      <div class="search-bar">
        <img src="../assets/images/icons/search.png" alt="">
        <input @keyup.enter="search" type="search" v-model="searchQuery" placeholder="Поиск по названию">
        <p v-if="isNoMatches">По вашему запросу не найдено результатов</p>
      </div>
      <!--     <div class="filter">
        <img src="../assets/images/icons/filter.png" alt="">
      </div> -->
    </div>
    <div class="products-container">
      <ul ref="productsList">

        <li v-for="product in showedProducts" :key="product.id">
          <ProductCard :productInfo="product" ref="productCardItem" class="product-item  "/>
        </li>

      </ul>
    </div>

    <div ref="showMoreProductsBtn" class="products-show-more" v-if="showedProductsCount < searchResults.length">
      <button @click="showMoreProducts">Показать еще</button>
    </div>

    <transition name="modal-fade">
      <LoadingModal v-if="loadingState">
      </LoadingModal>
    </transition>

  </div>
</template>

<script>
// @ is an alias to /src
import ProductCard from '@/components/products/ProductCard.vue'
import LoadingModal from '@/components/modals/loadingModal.vue'
import { mapState } from 'vuex'

export default {
  name: 'ProductsView',
  components: {
    ProductCard,
    LoadingModal
  },
  data() {
    return {
      sortbarState: false,
      sortType: 0,
      searchQuery: '',
      isNoMatches: false,
      showedProducts: [],
      searchResults: [],
      showedProductsIncrement: 10,
      showedProductsCount: 2,
      scrollPosition: 0,
      loadingState:true
    }
  },
  computed: {
    // смешиваем результат mapState с внешним объектом
    sortbarText() {
      switch (Number(this.sortType)) {
        case 0:
          return 'По популярности';
        case 1:
          return 'По возрастанию цены';
        case 2:
          return 'По убыванию цены';
        case 3:
          return 'Сначала новые';
        case 4:
          return 'Сначала старые';
        default:
          return 'По популярности';
      }
    },
    ...mapState({
      products: state => state.products.all,
      showedProductsCountState: state => state.showedProductsCount
      // ...
    })
  },
  watch: {
    products(newValue) {
      this.showedProducts = newValue.slice(0, this.showedProductsCount);
      this.searchResults = newValue;
    },
    showedProductsCountState(newValue) {
      this.showedProductsCount = newValue;
    },
    sortType(newValue) {
      let results = JSON.parse(JSON.stringify(this.searchResults));
      switch (Number(newValue)) {
        case 0:
          this.searchResults = results.sort((a, b) => a.position - b.position);
          break;
        case 1:
          this.searchResults = results.sort((a, b) => a.price - b.price);
          break;
        case 2:
          this.searchResults = results.sort((a, b) => b.price - a.price);
          break;
        case 3:
          this.searchResults = results.sort((a, b) => b.id - a.id);
          break;
        case 4:
          this.searchResults = results.sort((a, b) => a.id - b.id);
          break;
      }
      this.showedProducts = this.searchResults.slice(0, this.showedProductsCount);
    }
  },
  methods: {
    scrollListener(){
      this.scrollPosition = window.scrollY;
    },
    showSortBar() {
      this.sortbarState = true;
    },
    sort() {

    },
    search() {
      this.searchResults = this.products.filter(product => product.name.toLowerCase().indexOf(this.searchQuery.toLowerCase()) >= 0);
      this.isNoMatches = false;
      if (this.searchResults.length == 0) {
        this.searchResults = this.products;
        this.isNoMatches = true;
      }
      this.showedProducts = this.searchResults.slice(0, this.showedProductsCount);
    },

    showMoreProducts() {

      if (this.showedProductsCount + this.showedProductsIncrement
        <= this.searchResults.length) {
        this.showedProductsCount += this.showedProductsIncrement;
        /*  this.$store.state.showedProductsCount = this.showedProductsCount; */
      }
      else {
        this.showedProductsCount = this.searchResults.length;
      }

      this.showedProducts = this.searchResults.slice(0, this.showedProductsCount);
      this.$store.commit('setShowedProductsCount', this.showedProductsCount)

    },
  },
  created() {
    this.showedProductsCount = this.showedProductsCountState;
    this.$store.commit('setActivePage', 'products');
    if (this.$store.state.products?.all.length == 0) {
      this.$store.dispatch('products/getAll').then(() => {
        this.products = this.$store.state.products.all;
        this.searchResults = this.$store.state.products.all;
        this.showedProducts = this.searchResults.slice(0, this.showedProductsCount);
        this.loadingState = false;
      });
    }
    else {
      this.products = this.$store.state.products.all;
      this.searchResults = this.$store.state.products.all;
      this.showedProducts = this.searchResults.slice(0, this.showedProductsCount);
      this.loadingState = false;
    }

  },

  mounted() {
    window.addEventListener('scroll', this.scrollListener)
    
    window.scroll({
      left: 0, // до какого количества пикселей прокрутить вправо
      top: this.$store.state.productsScroll, // до какого количество пикселей прокрутить вниз
      behavior: 'smooth' // определяет плавность прокрутки: 'auto' - мгновенно (по умолчанию), 'smooth' - плавно
    });

  },
  beforeUnmount(){
    this.$store.commit('setProductsScroll', this.scrollPosition);
  },
  unmounted(){
    window.removeEventListener('scroll', this.scrollListener)
  }

}

</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.products-animated-active {
  opacity: 1 !important;
  z-index: 1 !important;
  transform: none !important;
}

.products-scroll-animated {
  opacity: 0.3;
  z-index: -1 !important;
  transform: scale(0.5);
  transition: all 1s ease-in-out !important;

}

.products {
  padding: 100px 30px 80px 30px;
  min-height: 100vh;
  /* background-image: url("../assets/images/mainPage/11.jpg");*/
  background-color: rgba(189, 229, 255, 0.108);
  background: linear-gradient(to bottom, white, rgb(255, 239, 228),white);
  background-size: cover;
  position: relative;
}

.products-container ul {
  padding-left: 0;
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, 300px);
  grid-auto-rows: 500px;
  justify-items: stretch;
  gap: 20px 20px;

}


.products-nav-bar {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 20px;
  gap: 20px;
}

.products-nav-bar img {
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}

.products-nav-bar img:hover {
  transform: scale(1.1);
}

.sort {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  cursor: pointer;
  min-width: 230px;
}

.sort p {
  margin: 0;
  margin-top: 5px;
  margin-left: 10px;

}

.sort-bar-full {
  width: calc(100% + 50px);
  min-width: 280px;
  top: 30px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.771);
  border-radius: 10px;
  backdrop-filter: blur(3px);
  z-index: 20;
}

.sort-bar-full label {
  display: flex;
  cursor: pointer;
}

.sort-bar-full input {
  order: -5;
}

.search-bar {
  width: 50%;
  height: 100%;
  position: relative;
}

.search-bar input {
  width: 100%;
  padding: 8px;
  padding-left: 40px;
  border-radius: 10px;
  border: 1px solid rgba(128, 128, 128, 0.468);
  background-color: rgba(255, 255, 255, 0.823);
  font-size: 1.1rem;

}

.search-bar img {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: transparent;
}

.filter-bar-container {
  width: 250px;
  height: 500px;
  background-color: rgb(255, 255, 255);
  position: fixed;
  z-index: 5;
  top: 100px;
  right: 10px;
  border-radius: 10px;

}

.products-show-more {
  width: 100%;
  display: flex;
  justify-content: center;
}

.products-show-more button {
  margin-top: 30px;
  padding: 15px;
  width: 200px;
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  cursor: pointer;
  border: 2px solid rgba(128, 128, 128, 0.156);
  box-shadow: 5px 5px 10px rgba(128, 128, 128, 0.119);
  transition: all 0.5s ease-in-out;
}

.products-show-more button:hover {
  background-color: #0c022c;
  color: white;
}

@media(max-width:980px) {

  .products-container ul {
    padding-left: 0;
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(auto-fill, 250px);
    grid-auto-rows: 400px;
    justify-items: stretch;
    gap: 20px 20px;

  }

}

@media(max-width:580px) {

  .products-container ul {
    padding-left: 0;
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: auto;
    justify-items: stretch;
    gap: 20px 20px;

  }

  .products-nav-bar {
    flex-direction: column;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .search-bar {
    order: -5;
  }

  .products-nav-bar div {
    width: 100%;
  }
}



@media(max-width:480px) {

  .search-bar img {
    position: absolute;
    top: 8px;
    left: 10px;
    background-color: transparent;
  }
  

  .products-container ul {
    padding-left: 0;
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(1, 1fr);
    grid-auto-rows: auto;
    justify-items: stretch;
    gap: 20px 20px;
  }

}

@media(max-width:280px) {

  .products-nav-bar{
    padding-left: 5px;
    padding-right: 5px;
  }

}
</style>
