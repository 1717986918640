
<template>
    <div class="products">

        <div class="products-add-btn">
            <router-link to="/admin">
                <button>На главную</button>
            </router-link>
        </div>


        <div class="products-container">

            <div class="products-add-btn">
                <router-link to="/admin/products/-1">
                    <button>Добавить товар</button>
                </router-link>
            </div>

            <ul ref="productsList">
                <transition-group name="product-list" mode="out-in">
                    <li v-for="product in showedProducts" :key="product.id">
                        <productItem :product="product"></productItem>
                    </li>
                </transition-group>
            </ul>
        </div>

        <div ref="showMoreProductsBtn" class="products-show-more">
            <button @click="showMoreProducts">Показать еще</button>
        </div>


    </div>
</template>
  
<script>
// @ is an alias to /src
import UserDataService from "@/service/UserDataService";
import productItem from '@/components/admin/productItem.vue'
import { mapState } from 'vuex'

export default {
    name: 'ProductsView',
    components: {
        productItem
    },
    data() {
        return {
            searchQuery: '',
            isNoMatches: false,
            showedProducts: [],
            searchResults: [],
            showedProductsIncrement: 10,
            showedProductsCount: 10
        }
    },
    computed: {
        // смешиваем результат mapState с внешним объектом
        ...mapState({
            products: state => state.products.all,
            user: state => state.user.all
            // ...
        })
    },
    watch: {
        products(newValue) {
            this.showedProducts = newValue.slice(0, this.showedProductsCount);
            this.searchResults = newValue;
        },
        user(newValue) {
            if (newValue?.id) {
                if (newValue.role != 'admin') this.$router.push('/');
            }
            else this.$router.push('/');
        }
    },
    methods: {
        search() {
            this.searchResults = this.products;
            this.isNoMatches = false;
            if (this.searchResults.length == 0) {
                this.searchResults = this.products;
                this.isNoMatches = true;
            }
        },

        showMoreProducts() {

            if (this.showedProductsCount + this.showedProductsIncrement
                <= this.searchResults.length) {
                this.showedProductsCount += this.showedProductsIncrement;
                /*  this.$store.state.showedProductsCount = this.showedProductsCount; */
            }
            else {
                this.showedProductsCount = this.searchResults.length;
                /* this.$store.state.showedProductsCount = this.showedProductsCount; */
                if (this.showedProductsCount >= this.searchResults.length) {
                    setTimeout(() => {
                        this.$refs.showMoreProductsBtn.style.display = 'none';
                    }, 500);
                }
            }

            this.showedProducts = this.products.slice(0, this.showedProductsCount);

        },
        /*  changePosition(id) {
 
         } */
    },
    created() {
        UserDataService.checkSession().then((res) => {
            if (res.data == 'session unset') {
                this.$router.push('/');
            }
        });
        /* this.$store.dispatch('products/getAll'); */
        if (this.user?.id) {
            if (this.user.role != 'admin') this.$router.push('/');
        }

        if (this.$store.state.products?.all.length == 0) {
            this.$store.dispatch('products/getAll');
        }
        else {
            this.products = this.$store.state.products.all;
            this.searchResults = this.$store.state.products.all;
            this.showedProducts = this.searchResults.slice(0, this.showedProductsCount);
        }
    },
    mounted() {

        /* window.scrollTo(0, 0) */


    }
}

</script>
  
<style scoped>
.products-animated-active {
    opacity: 1 !important;
    z-index: 1 !important;
    transform: none !important;
}

.products-scroll-animated {
    opacity: 0.3;
    z-index: -1 !important;
    transform: scale(0.5);
    transition: all 1s ease-in-out !important;

}

.products {
    padding: 100px 30px 80px 30px;
    /* background-image: url("../assets/images/mainPage/11.jpg");*/
    background-color: rgba(189, 229, 255, 0.108);
    background: linear-gradient(to bottom, white, rgba(138, 138, 255, 0.127),white);
    background-size: cover;
    position: relative;
}

.products-container ul {
    padding-left: 0;
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(auto-fill, 500px);
    grid-auto-rows: 500px;
    justify-items: stretch;
    gap: 20px 20px;


}


.search-bar {
    width: 100%;
    display: flex;
    justify-content: center;
}

.search-bar div {
    width: 50%;
    margin-left: 100px;

    position: relative;
}

.search-bar input {
    width: 100%;
    padding: 5px;
    padding-left: 40px;
    margin-bottom: 20px;
    border-radius: 10px;
    border: 1px solid rgba(128, 128, 128, 0.468);
    background-color: rgba(255, 255, 255, 0.823);
    font-size: 1.1rem;

}

.search-bar img {
    position: absolute;
    top: 8px;
    left: 10px;
    background-color: transparent;
}

.filter-bar-container {
    width: 250px;
    height: 500px;
    background-color: rgb(255, 255, 255);
    position: fixed;
    z-index: 5;
    top: 100px;
    right: 10px;
    border-radius: 10px;

}

.products-show-more {
    width: 100%;
    display: flex;
    justify-content: center;
}

.products-show-more button {
    margin-top: 30px;
    padding: 15px;
    width: 200px;
    border-radius: 10px;
    background-color: rgba(236, 235, 235, 0.732);
    border: 1px solid rgba(112, 112, 112, 0.655) !important;
    color: rgb(0, 0, 0);
    cursor: pointer;
    box-shadow: 5px 5px 10px gray;
    transition: all 0.5s ease-in-out;
}

.products-show-more button:hover {
    background-color: #0c022c;
    color: white;
}

.products-add-btn {
    width: 100%;
    display: flex;
    justify-content: center;
}

.products-add-btn button {
    padding: 10px;
    border-radius: 10px;
    border: 2px solid gray;
    background-color: rgba(255, 255, 255, 0.834);
    margin-bottom: 30px;
    color: #0c022c;
    width: 200px;
    cursor: pointer;
    box-shadow: 5px 5px 10px #0c022c3e;
    transition: all 0.5s ease-in-out;
}

.products-add-btn button:hover {
    background-color: #0c022c;
    border: 2px solid rgb(255, 255, 255);
    color: white;
}
</style>
  