
<template>
  <div class="log-in">
    <div class="log-in-container">
      <h2>Добро пожаловать</h2>
      <form action="" @submit.prevent>

        <label for="">
          <span>Введите логин</span>
          <input type="text" v-model="login" required>
        </label>

        <label for="">
          <span>Введите имя пользователя</span>
          <input type="text" v-model="name" required>
        </label>

<!--         <label for="">
          <span>Введите email</span>
          <input type="email" v-model="email" required>
        </label> -->

        <label for="">
          <span>Введите пароль</span>
          <input type="password" v-model="password" required>
        </label>

        <label for="">
          <span>Подтвердите пароль</span>
          <input type="password" v-model="passwordCheck" required>
        </label>

        <div class="errors-block">
          <p v-for="error in errors" :key=error>{{ error }}</p>
        </div>
        <button class="log-in-btn" @click="register">Зарегистрироваться</button>

        <router-link :to="'/sign-in'">Уже есть аккаунт</router-link>
      </form>
    </div>

    <transition name="modal-fade">
      <ThankModal v-if="thankState" @onCloseModal="thankState = false"
        :message="`Вы успешно зарегистрировались, пожалуйста войдите в свою учетную запись`">
      </ThankModal>
    </transition>

    <transition name="modal-fade">
      <LoadingModal v-if="loadingState">
      </LoadingModal>
  </transition>

  </div>
</template>

<script>
// @ is an alias to /src
/* import GalleryModal from '@/components/modals/GalleryModal.vue'
import { mapState } from 'vuex' */
import ThankModal from '@/components/modals/ThankModal.vue';
import LoadingModal from '@/components/modals/loadingModal.vue';
import UserDataService from "@/service/UserDataService";
export default {
  name: 'LogInView',
  components: {
    ThankModal,
    LoadingModal
  },
  data() {
    return {
      login: "",
      name: "",
      email: "",
      password: "",
      passwordCheck: "",
      errors: [],
      thankState: false,
      loadingState: false

    }
  },

  methods: {
    register() {
      this.errors = [];
      if (this.password.length < 6) this.errors.push('Пароль должен быть больше 6 символов');
      if (this.password != this.passwordCheck) this.errors.push('Пароли не совпадают');
      if (this.errors.length == 0) {
        this.loadingState = true;
        UserDataService.create({
          login: this.login,
          name: this.name,
/*           email: this.email, */
          password: this.password,
        }).then((res) => {
          this.loadingState = false;
          this.errors.push(res.data);
          if (this.errors == 'Пользователь успешно зарегистрирован') {
            this.thankState = true;
            setTimeout(() => {
              this.$router.push('/sign-in')
            }, 3000)
          }

        })
      }
    }
  },

  created() {
    this.$store.commit('setActivePage', 'user');
  },
  mounted() {
    window.scroll({
      left: 0, // до какого количества пикселей прокрутить вправо
      top: 0, // до какого количество пикселей прокрутить вниз
      behavior: 'smooth' // определяет плавность прокрутки: 'auto' - мгновенно (по умолчанию), 'smooth' - плавно
    });

  }
}
</script>

<style scoped>
a {
  padding: 10px;
  display: block;
  text-decoration: none;
  transition: color .5s ease-in-out;
}

a:hover {
  color: rgb(0, 4, 255);
}

.log-in {
  min-height: 100vh;
  margin: 0;
  padding-top: 100px;
  padding-bottom: 100px;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to bottom, rgb(244, 255, 210), rgba(253, 255, 188, 0.849),rgb(255, 217, 192));
  z-index: 0;
}

.log-in-container {
  width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.844);
  border: 1px solid rgb(248, 251, 206);
  box-shadow: 5px 5px 10px rgba(248, 217, 199, 0.781);
  padding: 50px;
  z-index: 1;
  filter: blur(3x);
  border-radius: 10px;
}

.log-in-container h2 {
  text-align: center;
}

.log-in-container form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.log-in-container label {
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 300px;
}

.log-in-container input {
  margin-top: 7px;
  border-radius: 5px;
  padding: 7px;
  width: 100%;
}

.log-in-btn {
  padding: 10px;
  width: 200px;
  background-color: white;
  border-radius: 5px;
  border: 1px solid gray;
  margin-top: 30px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}

.log-in-btn:hover {
  background-color: rgb(9, 9, 100);
  color: white;
}



@media(max-width:650px) {

  .log-in-container {
    width: 400px;
  }

}


@media(max-width:430px) {

  .log-in-container {
    min-width: 250px;
    width: 90%;
    padding: 10px;
    padding-top: 20px;
  }

  .log-in-container label {
    width: 80%;
    padding: 5px;
  }

  .log-in-container input {
    padding: 3px;
  }
}

@media(max-height:550px) {

  .log-in {
    padding-top: 70px;
  }

  .log-in-container {
    margin-bottom: 50px;
    padding-top: 10px;
    padding-bottom: 10px;
  }


}
</style>
