import { createStore, createLogger } from 'vuex'
import products from './modules/products'
import feedback from './modules/feedback'
import discounts from './modules/discounts'
import gallery from './modules/gallery'
import user from './modules/user'
import cart from './modules/cart'
import order from './modules/order'
import activities from './modules/activities'


const debug = process.env.NODE_ENV !== 'production'

export default createStore({
    modules: {
        products,
        feedback,
        discounts,
        gallery,
        user,
        cart,
        order,
        activities

    },

    state: {
        /* serverRoot: 'http://localhost/new_git/', */
        serverRoot: 'https://marysiahandmade.ru/new/',
        productsScroll: 0,
        galleryScroll: 0,
        homeScroll: 0,
        activePage: 'home',
        homeActivities: [{
                title: 'Украшения',
                description: `Мы производим украшения ручной работы из высококачественных материалов. Каждое изделие создается с любовью и вниманием к деталям. 
                
                Наша коллекция украшений включает в себя ожерелья, браслеты, серьги и кольца, которые можно носить ежедневно или на особые случаи. 
                
                Мы с гордостью можем сказать, что наши украшения неповторимы и будут служить долго благодаря их изяществу и качеству. 
                
                Мы предлагаем украшения, которые подойдут для всех возрастов и стилей. 
                
                Выберите наше ручной работы, чтобы подчеркнуть свою индивидуальность и украсить свой гардероб.
                `,
                content: [{
                    src: 'IMG20230520151817.jpg',
                    type: 'img'
                }]
            },
            {
                title: 'Одежда',
                description: `Мы создаем уникальную одежду с рисунками, выполненными вручную. 
                
                Мы используем только высококачественные материалы и экологически чистые красители. 
                
                Вы получаете не только красивую и стильную одежду, но и эксклюзивный дизайн, который невозможно найти в массовом производстве. 
                
                Наша работа - это наше призвание, и мы уверены, что вы останетесь довольны потрясающей качеством и уникальностью нашей одежды.
                `,
                content: [{
                    src: 'IMG20230520150640.jpg',
                    type: 'img'
                }]
            },
            {
                title: 'Изготовление на заказ',
                description: `Мы готовы выполнить ваш заказ на создание уникальных рисунков ручной работы на одежде и украшений.
                
                Мы используем только качественные материалы и стараемся учитывать пожелания каждого клиента. 
                
                Вы можете быть уверены, что ваша одежда и украшения будут иметь оригинальный дизайн и выглядеть неповторимо. 
                
                Мы гарантируем высокое качество и быстрое исполнение заказов. 
                
                Свяжитесь с нами и мы с удовольствием поможем воплотить ваши идеи в жизнь!
                `,
                content: [{
                    src: 'IMG20230520151208.jpg',
                    type: 'img'
                }]
            }
        ],
        showedProductsCount: 10,
        showedGalleryCount: 10,
    },
    mutations: {
        setActivePage(state, newPage) {
            state.activePage = newPage
        },
        setShowedProductsCount(state, count) {
            state.showedProductsCount = count;
        },
        setShowedGalleryCount(state, count) {
            state.showedGalleryCount = count;
        },
        setProductsScroll(state, position) {
            state.productsScroll = position;
        },
        setGalleryScroll(state, position) {
            state.galleryScroll = position;
        },
        setHomeScroll(state, position) {
            state.homeScroll = position;
        }
    },
    actions: {},

    strict: debug,
    plugins: debug ? [createLogger()] : []
})