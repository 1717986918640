import ActivitiesDataService from "@/service/ActivitiesDataService.js";


// initial state
const state = () => ({
    all: []
})

// getters
const getters = {

}

// actions
const actions = {

    getAll({ commit }) {

        let items = [];
        ActivitiesDataService.getAll().then((res) => {
            let itemsArray = [];
            res.data.forEach(item => {
                let storeItem = new Object();
                let itemImages = [];
                if (item.images != "") {
                    item.images.split(" $;$ ").forEach(image => {

                        let imageItem = new Object();
                        imageItem.type = image.slice(1, 4);
                        imageItem.src = image.slice(5, image.length);

                        if (imageItem.src != "") {
                            itemImages.push(imageItem)
                        }

                    });
                }

                storeItem = JSON.parse(JSON.stringify(item));
                storeItem.images = itemImages;

                itemsArray.push(storeItem);
            });
            items = itemsArray;
            commit('setActivities', items)
        });
    },

    updateText({ commit }, item) {
        ActivitiesDataService.updateText({
            id: item.id,
            text: item.text

        }).then(() => {
            commit('setRecordText', item);

        });
    },
    updateImages({ commit }, item) {
        let images = "";
        let files = [];
        item.images.forEach(image => {
            images += '|' + image.type + '|' + image.src + ' $;$ '
            files.push(image.file)
        });
        let formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            let file = files[i];
            formData.append('files[' + i + ']', file);
        }

        ActivitiesDataService.updateImages({
            id: item.id,
            images: images
        }).then((res) => {
            console.log(res.data);
            if (item.images.length > 0) {
                ActivitiesDataService.saveFile(formData).then((res) => {
                    console.log(res.data)
                    commit('setRecordImages', item)
                });
            } else commit('setRecordImages', item);
        })
    },
    delete({ commit }, id) {
        ActivitiesDataService.delete(id).then(() => {
            commit('deleteRecord', id)
        });
    }
}

// mutations
const mutations = {
    setActivities(state, items) {
        state.all = items;
    },
    setNewFeedback() {

    },

    setRecordText(state, record) {
        state.all.find(item => item.id === record.id).text = record.text;

    },
    setRecordImages(state, record) {
        state.all.find(item => item.id === record.id).images = record.images;

    },
    deleteRecord(state, id) {
        const item = state.all.findIndex(el => el.id === id);
        console.log(item);
        state.all.splice(item, 1);
    }

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}