
<template>
    <div class="admin-products-item">

        <ProductCard :productInfo="product" ref="productCardItem" class="product-item  " />

        <div class="product-item-actions">
            <router-link v-if="product.id!= undefined" :to="'/admin/products/'+product.id">
                <button>Редактировать</button>
            </router-link>
            <button class="delete-btn"
            @click="openModal()">Удалить</button>
            <p>Позиция №{{ product.position }}</p>
            <input type="number" v-model="position">
            <button class="change-position-btn" @click="changePosition()">Сменить позицию</button>
        </div>

        <transition name="modal-fade">
            <AcceptModal v-if="modalState" 
            @onCloseModal="closeModal"
            @onAccept="deleteItem"
             :item="modalItem" :message="`Вы уверены что хотите удалить \r\n` + `'` + product.name + `'`"
             :contentLocation="'products'">
            </AcceptModal>
        </transition>

    </div>
</template>
  
<script>
// @ is an alias to /src
import ProductCard from '@/components/products/ProductCard.vue'
import AcceptModal from '@/components/modals/AcceptModal.vue'


export default {
    name: 'adminProductItem',
    components: {
        ProductCard,
        AcceptModal
    },
    props: ['product'],
    data() {
        return {
            position: "",
            modalItem: {},
            modalState: false
        }
    },
    computed: {

    },
    methods: {
        openModal() {
            this.modalItem = this.product.images[0];
            this.modalState = true;
        },
        closeModal() {
            this.modalState = false;
        },
        changePosition() {

            this.$store.dispatch('products/updatePosition', {
                id: this.product.id,
                position: this.position,
                oldPosition: this.product.position
            });
            setTimeout(() => {
                this.$store.dispatch('products/getAll')
            }, 500);
        },
        deleteItem(){
            this.$store.dispatch('products/delete',this.product.id);
            setTimeout(() => {
                this.$store.dispatch('products/getAll')
            }, 500);
        }
        
    },
    created(){
        /* console.log(this.product); */
    }
}

</script>
  
<style scoped>
.admin-products-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    border: 1px solid gray;
    border-radius: 22px;

    background: linear-gradient(0deg, #070b0f, #111b29);
    color: #fff;
    line-height: 150%;
    letter-spacing: 0.05em;
    box-shadow: 0 0 1px #f00, 0 0 1px #f00, 0 0 1px #f00, 0 0 1px #f00, 0 0 10px #d0e9 inset, 0 0 35px #53d5;
}

.product-item {
    width: 320px;
    background-color: white !important;
}

.product-item-actions {
    display: flex;
    flex-direction: column;
    width: 180px;
    height: 100%;
    padding: 15px;
    justify-content: center;
    align-items: center;

}

.product-item-actions button,
.product-item-actions input,
.product-item-actions a {
    width: 90%;
    padding: 7px;
    margin: 5px;
    border-radius: 5px;
    border: 1px solid gray;
    background-color: white;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
}

.product-item-actions a button {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 7px;
    border: none;
}

.product-item-actions a {
    padding: 0px;
}

.product-item-actions a:hover,
.change-position-btn:hover {
    background-color: #0c022c;
    border: 1px solid rgb(255, 255, 255);
    color: white;
}

.product-item-actions a button:hover {
    background-color: #0c022c;
    color: white;
}

.delete-btn:hover {
    background-color: red;
    color: white;
}

.modal-fade-leave-to,
.modal-fade-enter-from {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: all 0.5s ease-in-out;
}


</style>
  