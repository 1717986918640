import ProductDataService from "@/service/ProductDataService";

// initial state
const state = () => ({
    all: []
})

// getters
const getters = {

}

// actions
const actions = {

    async getAll({ commit }) {

        let items = [];
        await ProductDataService.getAll().then((res) => {
            /* console.log(res.data); */
            res.data.forEach(item => {
                let storeItem = new Object();
                let itemImages = [];

                item.images.split(" $;$ ").forEach(image => {

                    let imageItem = new Object();
                    imageItem.type = image.slice(1, 4);
                    imageItem.src = image.slice(5, image.length);

                    if (imageItem.src != "") {
                        itemImages.push(imageItem)
                    }

                });

                storeItem.id = item.id;
                storeItem.name = item.name.replace(/&quot;/g, '"');
                storeItem.position = item.position;
                storeItem.description = item.description.replace(/&quot;/g, '"');
                storeItem.size = item.size.replace(/&quot;/g, '"');
                storeItem.composition = item.composition.replace(/&quot;/g, '"');
                storeItem.price = item.price;
                storeItem.discountPrice = item.discountPrice;
                storeItem.category = item.category;
                storeItem.images = itemImages;

                items.push(storeItem);
            });

            const comparer = (a, b) => a.position - b.position;
            items.sort(comparer);
            /* 
                        items = itemsArray; */
        });
        /* console.log(items); */
        await commit('setItems', items)
    },

    async update({ commit }, record) {
        await ProductDataService.update({
            id: record.id,
            name: record.name,
            images: record.images,
            description: record.description,
            size: record.size,
            composition: record.composition,
            price: record.price,
            discountPrice: record.discountPrice,
            category: record.category,
            /* files: record.files */

        }).then(() => {
            commit('setRecordUpdate', record)
        });
    },
    async create({ commit }, record) {
        await ProductDataService.create({
            name: record.name,
            images: record.images,
            description: record.description,
            size: record.size,
            composition: record.composition,
            price: record.price,
            discountPrice: record.discountPrice,
            category: record.category,
            files: record.files

        }).then((res) => {
            record.id = res.data.id;
            commit('setNewRecord', record)
        });
    },
    async updatePosition({ commit }, record) {

        await ProductDataService.updatePosition({
            id: record.id,
            position: record.position,
            oldPosition: record.oldPosition
        }).then(() => {
            commit('setRecordPosition', record)
        });
    },

    delete({ commit }, id) {
        ProductDataService.delete(id).then(() => {
            commit('deleteRecord', id)
        });
    }
}

// mutations
const mutations = {
    setItems(state, items) {
        state.all = items;
    },

    setRecordPosition(state, record) {
        state.all.find(product => product.id === record.id).position = record.position;
    },

    setNewRecord(state, record) {
        const item = new Object();
        item.id = Number(record.id);
        item.name = record.name;

        let itemImages = [];

        record.images.split(" $;$ ").forEach(image => {

            let imageItem = new Object();
            imageItem.type = image.slice(1, 4);
            imageItem.src = image.slice(5, image.length);

            if (imageItem.src != "") {
                itemImages.push(imageItem)
            }

        });

        item.images = itemImages;
        item.position = 1;
        item.description = record.description;
        item.size = record.size;
        item.composition = record.composition;
        item.price = record.price;
        item.discountPrice = record.discountPrice;
        item.category = record.category;
        state.all.unshift(item);
    },

    setRecordUpdate(state, record) {
        const item = state.all.find(product => product.id == Number(record.id));
        item.id = Number(record.id);
        item.name = record.name;

        let itemImages = [];

        record.images.split(" $;$ ").forEach(image => {

            let imageItem = new Object();
            imageItem.type = image.slice(1, 4);
            imageItem.src = image.slice(5, image.length);

            if (imageItem.src != "") {
                itemImages.push(imageItem)
            }

        });

        item.images = itemImages;
        item.description = record.description;
        item.size = record.size;
        item.composition = record.composition;
        item.price = record.price;
        item.discountPrice = record.discountPrice;
        item.category = record.category;

    },

    deleteRecord(state, id) {
        const item = state.all.findIndex(product => product.id === id);
        state.all.splice(item, 1);
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}