
<template>
    <div class="admin-item" v-if="item !== undefined">

        <div class="info">
            <div class="item-img">
                <img v-if="item.type == 'img'" :src="$store.state.serverRoot+'images/gallery/' + item.src" alt="">

                <video v-else autoplay="autoplay" loop controls muted preload="auto">
                    <source :src="$store.state.serverRoot+'images/gallery/' + item.src" type="video/mp4">
                </video>
            </div>

            <div class="item-actions">
                <button class="change-position-btn" @click="openUpdateModal()">Изменить</button>
                <button class="delete-btn" @click="openModal()">Удалить</button>
                <p>Позиция №{{ item.position }}</p>
                <input type="number" v-model="position">
                <button class="change-position-btn" @click="changePosition()">Сменить позицию</button>
            </div>
        </div>


        <transition name="modal-fade">
            <AcceptModal v-if="modalState" @onCloseModal="closeModal" @onAccept="deleteItem" :item="modalItem"
                :message="`Вы уверены что хотите удалить это фото?`" :contentLocation="'gallery'">
            </AcceptModal>
        </transition>

        <transition name="modal-fade">
            <addGalleryItemModal v-if="modalUpdateState" @onCloseUpdateModal="closeUpdateModal" @onAcceptUpdate="updateItem" :item="modalItem"
                :message="`Вы уверены что хотите изменить это фото?`" :contentLocation="'gallery'">
            </addGalleryItemModal>
        </transition>


    </div>
</template>
  
<script>
// @ is an alias to /src
import AcceptModal from '@/components/modals/AcceptModal.vue'
import addGalleryItemModal from '@/components/modals/addGalleryItemModal.vue'

export default {
    name: 'adminProductItem',
    components: {
        AcceptModal,
        addGalleryItemModal
    },
    props: ['item'],
    data() {
        return {
            position: "",
            modalItem: {},
            modalState: false,
            modalUpdateState: false,
            isRedact: false
        }
    },
    computed: {

    },
    methods: {
        editImage(){
            this.isRedact = true;
        },
        updateItem(){
            
        },
        cancelRedact(){
            this.isRedact = false;
        },
        openModal() {
            this.modalItem = this.item;
            this.modalState = true;
        },
        openUpdateModal() {
            this.modalItem = this.item;
            this.modalUpdateState = true;
        },
        closeModal() {
            this.modalState = false;
        },
        closeUpdateModal(){
            this.modalUpdateState = false;
        },  
        changePosition() {
            this.$store.dispatch('gallery/updatePosition', {
                id: this.item.id,
                position: this.position,
                oldPosition: this.item.position
            });
            setTimeout(() => {
                this.$store.dispatch('gallery/getAll')
            }, 500);
        },
        deleteItem() {
            this.$store.dispatch('gallery/delete', this.item.id);
            setTimeout(() => {
                this.$store.dispatch('gallery/getAll')
            }, 500);
        }

    },
    created() {
        /* console.log(this.item); */
    }
}

</script>
  
<style scoped>
.admin-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    border: 1px solid gray;
    border-radius: 22px;

    background: linear-gradient(0deg, #070b0f, #111b29);
    color: #fff;
    line-height: 150%;
    letter-spacing: 0.05em;
    box-shadow: 0 0 1px #f00, 0 0 1px #f00, 0 0 1px #f00, 0 0 1px #f00, 0 0 10px #d0e9 inset, 0 0 35px #53d5;
}

.info{
    display: flex;
    flex-direction: row;
    height: 100%;
}

.item-img {
    width: 320px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 22px;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.888);
    backdrop-filter: blur(3px);
}

.item-img img,
.item-img video {
    margin: 5%;
    min-height: 80%;
    max-height: 80%;
    width: 90%;
    border-radius: 22px;
    object-fit: cover;
}

.item-actions {
    display: flex;
    flex-direction: column;
    width: 180px;
    height: 100%;
    padding: 15px;
    justify-content: center;
    align-items: center;

}

.item-actions button,
.item-actions input,
.item-actions a {
    width: 90%;
    padding: 7px;
    margin: 5px;
    border-radius: 5px;
    border: 1px solid gray;
    background-color: white;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
}

.item-actions a button {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 7px;
    border: none;
}

.item-actions a {
    padding: 0px;
}

.item-actions a:hover,
.change-position-btn:hover {
    background-color: #0c022c;
    border: 1px solid rgb(255, 255, 255);
    color: white;
}

.item-actions a button:hover {
    background-color: #0c022c;
    color: white;
}

.delete-btn:hover {
    background-color: red;
    color: white;
}



label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

input,
textarea,
select {
    border-radius: 10px;
    padding: 7px;
    border: 1px solid gray;
}

.modal-fade-leave-to,
.modal-fade-enter-from {
    opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
    transition: all 0.5s ease-in-out;
}
</style>
  