<template>
    <div class="user-modal" @click="closeModal">

        <div class="user-modal-container" @click="(e) => e.stopPropagation()">
            <div class="user-modal-title">Редактирование профиля</div>

            <div class="user-modal-content">
                <div class="user-image">
                    <template v-if="user.photo != '' || isNew">

                        <template v-if="!isNew">
                            <img :src="$store.state.serverRoot + 'images/users/' + user.photo" alt="">
                        </template>
                        <!-- //Динамически загруженное изображение -->
                        <template v-else>
                            <img :src="newImage.url" alt="">
                        </template>

                    </template>

                    <template v-else>
                        <img src="../../assets/images/icons/empty-photo.jpg" alt="">
                    </template>

                    <div class="user-image-edit">

                        <div v-if="!isRedact" class="image-btns">
                            <button class="btn-standart" @click="editImage">Изменить</button>
                        </div>


                        <div v-else class="images-user-card">
                            <div class="image-info">
                                <label for="imageFile">Выбрать файл</label>
                                <input @change="newImageChange" class="image-file" id="imageFile" type="file"
                                    accept="image/*" hidden>

                            </div>
                            <div class="image-btns">
                                <p v-if="wrongTypeFormat" style="color: red;">*Поддерживаемые типы файлов - png, jpg*</p>
                                <button class="btn-standart" @click="saveImage">Сохранить</button>
                                <button class="btn-cancel" @click="cancelRedact">Отменить</button>
                            </div>

                        </div>
                    </div>
                </div>

                <div class="user-info">
                    <label for="">Имя
                        <input type="text" v-model="name" required>
                    </label>
<!--                     <label for="">Email
                        <input type="email" v-model="email" required>
                    </label>
                    <label for="">Номер телефона
                        <input type="tel" v-model="phoneNumber">
                    </label> -->


                    <template v-if="isNewPassword">
                        <label for="">Новый пароль
                            <input type="password" v-model="password" required>
                        </label>
                        <label for="">Потдверждение пароля
                            <input type="password" v-model="passwordConfirm" required>
                        </label>
                        <div class="image-btns">
                            <button class="btn-standart" @click="isNewPassword = false">Отменить</button>
                        </div>
                    </template>
                    <template v-else>
                        <div class="image-btns">
                            <button class="btn-standart" @click="isNewPassword = true">Изменить пароль</button>
                        </div>
                    </template>
                    <p v-for="error in errors" :key="error" class="errors">{{ error }}</p>
                    <div class="image-btns">
                        <button class="btn-standart" @click="editInfo">Сохранить</button>
                    </div>

                </div>

            </div>



            <!-- <div class="accept-modal-btns">
                <button class="accept" @click="accept">Да</button>
                <button class="cancel" @click="closeModal">Отмена</button>
            </div> -->
        </div>
        <transition name="modal-fade">
            <LoadingModal v-if="loadingState">
            </LoadingModal>
        </transition>
    </div>
</template>

<script>
/* import GalleryDataService from '@/service/GalleryDataService'; */
import LoadingModal from '@/components/modals/loadingModal.vue'

export default {
    name: 'updateUserInfoModal',
    props: ['user'],
    components: {
        LoadingModal
    },
    data() {
        return {
            modalState: true,
            isRedact: false,
            newImage: {},
            isNew: false,
            loadingState: false,
            name: this.user.name,
/*             email: this.user.email,
            phoneNumber: this.user.phoneNumber, */
            password: '',
            passwordConfirm: '',
            isNewPassword: false,
            errors: [],
            wrongTypeFormat: false
        }
    },
    methods: {
        closeModal() {
            this.modalState = false;
        },
        editInfo() {
            this.errors = [];
            if (this.name == '') this.errors.push('Имя не заполнено')
/*             if (this.email == '') this.errors.push('Email не заполнен') */
            if (this.isNewPassword) {
                if (this.password != this.passwordConfirm) {
                    this.errors.push('Пароли не совпадают')
                }
                if (this.password.length < 6) {
                    this.errors.push('Пароль должен быть длинее 6 символов')
                }
            }
            if (this.errors.length == 0) {
                this.$store.dispatch('user/updateInfo', {
                    id: this.user.id,
                    name: this.name,
/*                     email: this.email,
                    phoneNumber: this.phoneNumber, */
                    password: this.password
                }).then(() => {
                    this.closeModal();
                })
            }
        },
        editImage() {
            this.isRedact = true;
        },
        cancelRedact() {
            this.isNew = false;
            this.isRedact = false;
        },
        newImageChange(e) {

            let src = e.target.files[0].name;
            let file = e.target.files[0];
            var url = '';
            let type = src.split('.');
            type = type[type.length - 1];
            if (type == 'jpg' || type == 'png' || type == 'jpeg') {
                this.isNew = true;
                this.wrongTypeFormat = false;
                var fileReader = new FileReader();
                fileReader.onload = function () {
                    url = fileReader.result;
                }
                let watcher = setInterval(() => {
                    if (url != '') {
                        this.newImage.src = src;
                        this.newImage.file = file;
                        this.newImage.url = url;
                        clearInterval(watcher);
                    }
                }, 100);
                console.log(this.newImage);

                fileReader.readAsDataURL(e.target.files[0]);
            }
            else {
                this.wrongTypeFormat = true;
            }
        },
        async saveImage() {

            if (this.newImage?.src) {
                this.loadingState = true;
                this.newImage = {
                    src: this.newImage.src,
                    url: this.newImage.url,
                    file: this.newImage.file
                }
                this.$store.dispatch('user/updatePhoto', {
                    id: this.user.id,
                    photo: this.newImage
                }).then(() => {
                    setTimeout(() => {
                        this.loadingState = false;
                        this.closeModal();
                    }, 1000);
                })
                this.isRedact = false;
            }

        },
    },
    watch: {
        // eslint-disable-next-line
        modalState(newValue) {
            if (!newValue) {
                this.$emit('onCloseUpdateModal');
            }

        }
    },
    created() {
        console.log(this.user)
    },
    mounted() {

    }
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
input,
textarea,
select {
    border-radius: 10px;
    padding: 7px;
    border: 1px solid gray;
}

.errors {
    margin: 0;
    padding: 10px;
    text-align: center;
}

.user-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.704);
    z-index: 120;
    cursor: no-drop;
}

.user-modal-container {
    min-height: 50%;
    max-height: 95vh;
    min-width: 60%;
    max-width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.884);
    backdrop-filter: blur(3px);
    border-radius: 15px;
    cursor: default;
    padding-bottom: 30px;
}

.user-modal-title {
    width: 100%;
    height: 50px;
    background: linear-gradient(0deg, #070b0f, #111b29);
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    color: white;
}

.user-modal-content {
    margin-top: 40px;
    height: calc(100% - 90px);
    width: 90%;
    display: flex;
    justify-content: space-evenly;
}

.user-image {
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.user-image img {
    max-width: 100%;
    max-height: calc(100% - 200px);
    border-radius: 10px;
}

.user-image-edit {
    display: flex;
    padding-top: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-self: flex-end;
    max-width: 90%;
    overflow: scroll;
}

.image-info {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
}

.user-image label {
    cursor: pointer;
    padding: 5px 10px 5px 10px;
    text-align: center;
    border-radius: 5px;
    background-color: white;
    border: 1px solid gray;
    width: 100%;
    margin: 10px;
}

.user-image input {
    border: 1px solid transparent !important;
}

.images-user-card {
    background-color: rgba(245, 250, 251, 0.763);
    padding: 10px;
    border-radius: 10px;
    margin-top: 10px;
    max-width: 100%;
}

.image-btns {
    display: flex;
    justify-content: center;
    justify-self: flex-end;
}


.user-info {
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    margin-right: 10px;
    background-color: #f6f6f6;
    border-radius: 10px;
    padding: 10px;
}

.user-info label {
    display: flex;
    flex-direction: column;
    padding: 10px;
}

@media(max-width:760px) {
    .image-btns {
        flex-direction: column;
        align-items: center;
    }

    .image-btns p{
        margin: 5px;
    }
}

@media(max-width:650px) {

    .user-modal-content {
        flex-direction: column;
        overflow: scroll;
        justify-content: flex-start;
        min-width: 80vw;
    }

    .images-user-card {
        width: 80%;
        margin-bottom: 20px;
    }

    .user-info {
        margin-top: 20px;
    }

    .user-image img {
        max-height: 50vh;
    }

}

@media(max-width:400px) {

    .user-modal-content {
        min-width: 95vw;
    }

    .images-user-card {
        width: 90%;
        margin-bottom: 20px;
    }

    .user-image img {
        max-width: 80vw;
    }
}
</style>
  