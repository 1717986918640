import GalleryDataService from "@/service/GalleryDataService";


// initial state
const state = () => ({
    all: []
})

// getters
const getters = {

}

// actions
const actions = {

    async getAll({ commit }) {

        let items = [];
        await GalleryDataService.getAll().then((res) => {
            res.data.forEach(item => {
                let galleryItem = new Object();

                galleryItem.id = item.id;
                galleryItem.type = item.content.slice(1, 4);
                galleryItem.src = item.content.slice(5, item.content.length);
                galleryItem.position = item.position;

                items.push(galleryItem);
            });

            const comparer = (a, b) => a.position - b.position;
            items.sort(comparer);
            /* galleryItems = itemsArray; */

        });
        await commit('setItems', items)
    },
    async update({ commit }, record) {
        await GalleryDataService.update({
            id: record.id,
            content: record.content
        }).then(() => {
            commit('setRecordUpdate', record)
        });
    },
    async create({ commit }, record) {
        await GalleryDataService.create({
            content: record.content
        }).then((res) => {
            record.id = res.data.id;
            commit('setNewRecord', record)
        });
    },
    updatePosition({ commit }, record) {
        GalleryDataService.updatePosition({
            id: record.id,
            position: record.position,
            oldPosition: record.oldPosition
        }).then(() => {
            commit('setRecordPosition', record)
        });
    },
    delete({ commit }, id) {
        GalleryDataService.delete(id).then(() => {
            commit('deleteRecord', id)
        });
    }
}

// mutations
const mutations = {
    setItems(state, items) {
        state.all = items;
    },

    setRecordPosition(state, record) {
        state.all.find(product => product.id === record.id).position = record.position;
    },

    setNewRecord(state, record) {
        console.log(record);
        const item = new Object();
        item.id = Number(record.id);
        item.type = record.content.slice(1, 4);
        item.src = record.content.slice(5, record.content.length);
        item.position = 1;
        state.all.unshift(item);
    },

    setRecordUpdate(state, record) {
        console.log(record);
        const item = state.all.find(el => el.id == Number(record.id));
        item.type = record.content.slice(1, 4);
        item.src = record.content.slice(5, record.content.length);
        console.log(item);
    },
    deleteRecord(state, id) {
        const item = state.all.findIndex(el => el.id === id);
        console.log(item);
        state.all.splice(item, 1);
    }

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}