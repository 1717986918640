import axios from 'axios'
import serverRoot from "@/service/serverRoot.js";

let path = serverRoot.getRoot();
const USER_API_URL = path + 'mysql-crud-api/operations/user'

class UserDataService {

    getItem(login) {

        return axios.get(`${USER_API_URL}/single_record.php`, { params: { login: login } });
    }

    updateInfo(item) {

        return axios.post(`${USER_API_URL}/updateInfo.php`, item);
    }
    updatePhoto(item) {

        return axios.post(`${USER_API_URL}/updatePhoto.php`, item);
    }

    create(item) {

        return axios.post(`${USER_API_URL}/create.php`, item);
    }

    auth(item) {

        return axios.post(`${USER_API_URL}/auth.php`, item, { withCredentials: true });
    }

    checkSession() {
        return axios.get(`${USER_API_URL}/checkSession.php`, { withCredentials: true });
    }

    signOut() {
        return axios.get(`${USER_API_URL}/sign_out.php`, { withCredentials: true });
    }

    updateCart(item) {
        return axios.post(`${USER_API_URL}/updateCart.php`, item);
    }

    savePhoto(formData) {
        return axios.post(`${USER_API_URL}/saveUserPhoto.php`,
            formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        )
    }
}

export default new UserDataService()