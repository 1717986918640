import axios from 'axios';
import serverRoot from "@/service/serverRoot.js";

let path = serverRoot.getRoot();
const USER_API_URL = path + 'mysql-crud-api/operations/discount'

class FeedbackDataService {

    getAll() {

        return axios.get(`${USER_API_URL}/read.php`);
    }

    delete(id) {

        return axios.delete(`${USER_API_URL}/delete.php`, { params: { id: id } });
    }

    updateTitle(item) {

        return axios.put(`${USER_API_URL}/update_title.php`, item);
    }

    updateProducts(item) {

        return axios.put(`${USER_API_URL}/update_products.php`, item);
    }

    create(item) {

        return axios.post(`${USER_API_URL}/create.php`, item);
    }

}

export default new FeedbackDataService()