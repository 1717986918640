<template>
    <div class="accept-modal" @click="closeModal">

        <div class="accept-modal-container" @click="(e) => e.stopPropagation()
            ">
            <div class="accept-modal-title">Внимание!</div>
            <div class="accept-modal-text">
                {{ message }}
            </div>
            <div class="accept-modal-content">
                <template v-if="content.type != undefined">
                    <template v-if="!isNew">
                        <img v-if="content.type == 'img'"
                            :src="$store.state.serverRoot+'images/' + contentLocation + `/` + content.src" alt="">

                        <video v-else @click="nothing" autoplay="autoplay" loop controls muted preload="auto">
                            <source :src="$store.state.serverRoot+'images/' + contentLocation + `/` + content.src"
                                type="video/mp4">
                        </video>
                    </template>

                    <!-- //Динамически загруженное изображение -->
                    <template v-else>
                        <img v-if="content.type == 'img'" :src="content.url" alt="">

                        <video v-else autoplay="autoplay" loop controls muted preload="auto">
                            <source :src="content.url" type="video/mp4">
                        </video>
                    </template>


                </template>

                <template v-else>
                    <img src="../../assets/images/icons/empty-photo.jpg" alt="">
                </template>

            </div>

            <div class="content">

                <div v-if="!isRedact" class="images-admin-card">
                    <div class="image-info">
                        <p>Файл: {{ content.src }}</p>
                        <p>Тип: {{ content.type }}</p>
                    </div>
                    <div class="image-btns">
                        <button class="image-btn edit" @click="editImage">Редактировать</button>
                    </div>
                </div>


                <div v-else class="images-admin-card">
                    <div class="image-info">
                        <label for="">
                            <span>Файл</span>
                            <input @change="newImageChange" class="image-file" type="file" accept="image/*, video/*">
                        </label>

                        <label for="">
                            <span>Тип</span>
                            <select v-model="itemType">
                                <option disabled value="">Выберите один из вариантов</option>
                                <option>img</option>
                                <option>vid</option>
                            </select>
                        </label>
                    </div>
                    <div class="image-btns">
                        <button class="image-btn save" @click="saveRedact">Сохранить</button>
                        <button class="image-btn delete" @click="cancelRedact">Отменть</button>
                    </div>

                </div>
            </div>

            <div class="accept-modal-btns">
                <button class="accept" @click="accept">Да</button>
                <button class="cancel" @click="closeModal">Отмена</button>
            </div>
        </div>
        <transition name="modal-fade">
            <LoadingModal v-if="loadingState">
            </LoadingModal>
        </transition>
    </div>
</template>

<script>
import GalleryDataService from '@/service/GalleryDataService';
import LoadingModal from '@/components/modals/loadingModal.vue'

export default {
    name: 'AcceptModal',
    props: ['item', 'message', 'contentLocation',],
    components: {
        LoadingModal
    },
    data() {
        return {
            modalState: true,
            isRedact: false,
            itemSrc: "",
            itemType: "",
            itemUrl: "",
            loadedFile: "",
            content: {},
            isNew: false,
            loadingState: false
        }
    },
    methods: {
        closeModal() {
            this.modalState = false;
        },
        async accept() {
            await this.saveChanges();
            console.log("updated")
            this.$emit('onAcceptUpdate', this.content);
            this.modalState = false;
        },
        editImage() {
            this.isRedact = true;
        },
        cancelRedact() {
            this.isRedact = false;
        },
        newImageChange(e) {
            this.itemSrc = e.target.files[0].name;
            this.loadedFile = e.target.files[0];
            var url = '';
            var fileReader = new FileReader();
            fileReader.onload = function () {
                url = fileReader.result;
            }
            let watcher = setInterval(() => {
                if (url != '') {
                    this.itemUrl = url;
                    clearInterval(watcher);
                }

            }, 100);

            fileReader.readAsDataURL(e.target.files[0]);

        },
        saveRedact() {
            if (this.itemUrl != "" && this.itemType != "") {
                this.isNew = true;
                this.content = {
                    src: this.itemSrc,
                    type: this.itemType,
                    url: this.itemUrl,
                }
                this.isRedact = false;
            }

        },
        async saveChanges() {

            this.loadingState = true;
            let content = '|' + this.content.type + '|' + this.content.src
            console.log(content);
            let formData = new FormData();
            let i = 0;
            formData.append('files[' + i + ']', this.loadedFile);

            await GalleryDataService.saveFile(formData).then((res) => {
                console.log(res);
                if (this.item.id != -1) {
                    this.$store.dispatch('gallery/update', {
                        id: this.item.id,
                        content: content
                    }).then(()=>{
                        this.$store.dispatch('gallery/getAll')
                    });
                }
                else {
                    this.$store.dispatch('gallery/create', {
                        content: content
                    }).then(()=>{
                        this.$store.dispatch('gallery/getAll')
                    });
                }
                console.log("saved + up");
            });
            setTimeout(() => {
                this.loadingState = false;
            }, 1000);

        },
    },
    watch: {
        // eslint-disable-next-line
        modalState(newValue) {
            if (!newValue) {
                this.$emit('onCloseUpdateModal');
            }

        }
    },
    created() {
        this.content = this.item;
    },
    mounted() {

    }
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
input,
textarea,
select {
    border-radius: 10px;
    padding: 7px;
    border: 1px solid gray;
}

.accept-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.704);
    z-index: 20;
    cursor: no-drop;
}

.accept-modal-container {
    min-height: 60%;
    max-height: 90vh;
    min-width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.884);
    backdrop-filter: blur(3px);
    border-radius: 15px;
    cursor: default;
}

.accept-modal-title {
    width: 100%;
    height: 50px;
    background: linear-gradient(0deg, #070b0f, #111b29);
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    color: white;
}

.accept-modal-content {
    margin-top: 20px;
    height: 60%;
    width: 60%;
    display: flex;
    justify-content: center;
}

.accept-modal-text {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;

    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    white-space: pre-line;
}

.accept-modal-btns {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    margin: 10px;
}

.accept-modal-btns button {
    padding: 10px;
    width: 150px;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
    border: 1px solid gray;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
}

.accept {
    background-color: rgb(4, 189, 1);
    color: white;
}

.accept-modal-btns button:hover {
    background-color: #070b0f;
    color: white;
}

.accept-modal-content img,
.accept-modal-content video {
    max-width: 100%;
    max-height: 100%;
    border-radius: 10px;
}



.content {
    width: 80%;
    margin-top: 10px;
    border-radius: 10px;
    color: black !important;
}

.images-admin-card {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid gray;
    border-radius: 22px;
    padding: 10px;
}

.images-admin-card {
    background-color: rgb(240, 240, 240);
    border-top: none;
    border-bottom: none;
}


.image-info {
    max-width: 70%;
    word-wrap: break-word;
    word-spacing: normal;
}

.image-btns {
    width: 120px;
    margin-right: 15px;
}

.image-btn {
    padding: 7px;
    margin: 5px;
    border-radius: 10px;
    border: 2px solid gray;
    background-color: rgba(255, 255, 255, 0.834);
    color: #0c022c;
    width: 120px;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
}

.image-btn:hover {
    background-color: #0c022c;
    border: 2px solid rgb(255, 255, 255);
    color: white;
}

.delete {
    background-color: rgb(190, 0, 0);
    color: white;
}

.image-file {
    border: none;
    width: 85%;
}

.image-redact {
    margin-top: 50px;
    background-color: #0c022c9e !important;
    color: white !important;
}

.save-btn-container {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    margin-top: 10px;
}

.save-btn {
    width: 200px;
    height: 40px;
    font-size: 1.2rem;
    background-color: #0e003b;
    color: white;
}

.save-btn:hover {
    background-color: #ffffff;
    color: rgb(0, 0, 0);
}
</style>
  