<template>
    <div class="accept-modal" @click="closeModal">

        <div class="accept-modal-container" @click="(e) => e.stopPropagation()">
            <div class="accept-modal-title">Спасибо!</div>
            <div class="accept-modal-text">
                {{ message }}
            </div>
            <div class="accept-modal-content">
                <img src="../../assets/images/icons/thank.png" alt="">

            </div>
            <div class="accept-modal-btns">
                <button class="cancel" @click="closeModal">Ок</button>
            </div>
        </div>

    </div>
</template>

<script>

export default {
    name: 'ThankModal',
    props: ['message'],
    data() {
        return {
            modalState: true
        }
    },
    methods: {
        closeModal() {
            this.modalState = false;
        }
    },
    watch: {
        // eslint-disable-next-line
        modalState(newValue, oldValue) {
            if (!newValue) {
                this.$emit('onCloseModal');
            }

        }
    },
    mounted() {

    }
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.accept-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.704);
    z-index: 20;
    cursor: no-drop;
}

.accept-modal-container {
    min-height: 60%;
    max-height: 80vh;
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.884);
    backdrop-filter: blur(3px);
    border-radius: 15px;
    cursor: default;
}

.accept-modal-title {
    width: 100%;
    height: 50px;
    background: linear-gradient(0deg, #070b0f, #111b29);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.3rem;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    color: white;
}

.accept-modal-content {
    margin-top: 20px;
    height: 40%;
    width: 60%;
    display: flex;
    justify-content: center;
}

.accept-modal-text {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(4, 6, 74);
    font-size: 1.3rem;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    white-space: pre-line;
}

.accept-modal-btns {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 20px;
}

.accept-modal-btns button {
    padding: 10px;
    width: 150px;
    border-radius: 10px;
    margin-bottom: 10px;
    border: 1px solid gray;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
}

.accept {
    background-color: red;
    color: white;
}

.accept-modal-btns button:hover {
    background-color: #0a012e;
    color: white;
}

.accept-modal-content img,
.accept-modal-content video {
    max-width: 100%;
    max-height: 100%;
    border-radius: 10px;
}


@media(max-width:1100px) {

    .accept-modal-container {
        min-width: 50vw;
        min-height: 50vh;
        width: 500px !important;
        max-width: 80vw;
        min-height: 350px;
        overflow: scroll;
    }

    .accept-modal-text {
        padding-top: 20px;
        padding-bottom: 20px;
    }

}

@media(max-width:350px) {

    .accept-modal-container {
        min-width: 50vw;
        min-height: 50vh;
        width: 500px !important;
        max-width: 90vw;
    }

}

@media(max-height:300px) {

    .accept-modal-container{
        min-height: 250px;
        height: 250px;
    }


}


</style>
  