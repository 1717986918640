import axios from 'axios';
import serverRoot from "@/service/serverRoot.js";

let path = serverRoot.getRoot();
const USER_API_URL = path + 'mysql-crud-api/operations/feedback'

class FeedbackDataService {

    getAll() {

        return axios.get(`${USER_API_URL}/read.php`);
    }

    delete(id) {

        return axios.delete(`${USER_API_URL}/delete.php`, { params: { id: id } });
    }

    update(item) {

        return axios.put(`${USER_API_URL}/update.php`, item);
    }

    updatePosition(item) {

        return axios.put(`${USER_API_URL}/update_position.php`, item);
    }
    updateStatus(item) {
        return axios.put(`${USER_API_URL}/update_status.php`, item);
    }

    create(item) {

        return axios.post(`${USER_API_URL}/create.php`, item);
    }

    saveFile(formData) {
        return axios.post(`${USER_API_URL}/saveFiles.php`,
            formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        )
    }

}

export default new FeedbackDataService()