
<template>
    <div class="admin-item">

        <div class="info">
            <div class="item-title">
                <h3>{{ item.title }}</h3>
            </div>
            <div class="item-content">
                <div class="item-img-content">

                    <div class="item-img" v-if="!newImageLoad">
                        <img v-if="item.images[0].type == 'img'"
                            :src="$store.state.serverRoot+'images/products/' + item.images[0].src" alt="">

                        <video v-else autoplay="autoplay" loop controls muted preload="auto">
                            <source :src="$store.state.serverRoot+'images/products/' + item.images[0].src" type="video/mp4">
                        </video>
                    </div>
                    <div class="item-img" v-else>
                        <img v-if="newImage.type == 'img'" :src="newImage.url" alt="">

                        <video v-else autoplay="autoplay" loop controls muted preload="auto">
                            <source :src="newImage.url" type="video/mp4">
                        </video>
                    </div>

                    <div v-if="!isImageRedact" class="item-actions">
                        <button class="change-position-btn" @click="isImageRedact = true; newImageLoad = false">Изменить
                            фото</button>
                    </div>

                    <div v-else class="item-img-redact">
                        <div class="image-redact images-admin-card">
                            <span>{{ redactedIndex }}</span>
                            <div class="image-info">
                                <label for="">
                                    <span>Файл</span>
                                    <input @change="newImageChange" class="image-file" type="file"
                                        accept="image/*, video/*">
                                </label>

                                <label for="">
                                    <span>Тип</span>
                                    <select v-model="newImageType">
                                        <option disabled value="">Выберите один из вариантов</option>
                                        <option>img</option>
                                        <option>vid</option>
                                    </select>
                                </label>
                            </div>
                            <div class="item-actions">
                                <button class="change-position-btn" @click="changeImage">Сохранить</button>
                                <button class="delete-btn" @click="isImageRedact = false">Отменть</button>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="item-text">
                    <template v-if="!isTextRedact">
                        <p>{{ item.text }}</p>
                        <div class="item-actions">
                            <button class="change-position-btn" @click="isTextRedact = true">Изменить текст</button>
                        </div>
                    </template>
                    <template v-else>
                        <textarea v-model="text"></textarea>
                        <div class="item-actions">
                            <button class="change-position-btn" @click="changetext">Сохранить</button>
                            <button class="delete-btn" @click="isTextRedact = false"> Отменить</button>
                        </div>
                    </template>
                </div>
            </div>

        </div>



    </div>
</template>
  
<script>
// @ is an alias to /src

export default {
    name: 'activitiItem',
    components: {

    },
    props: ['item'],
    data() {
        return {
            /*             position: "",
                        modalItem: {},
                        modalState: false,
                        modalUpdateState: false, */
            text: this.item.text,
            isTextRedact: false,
            isImageRedact: false,
            isImageAdded: true,
            newImageLoad: false,
            newImage: {},
            newImages: [],
            newImageType: ''
        }
    },
    computed: {

    },
    methods: {
        editImage() {
            this.isRedact = true;
        },
        newImageChange(e) {
            let src = e.target.files[0].name;
            let file = e.target.files[0];
            let url = '';
            let fileReader = new FileReader();
            fileReader.onload = function () {
                url = fileReader.result;
            }

            fileReader.readAsDataURL(e.target.files[0]);
            let watcher = setInterval(() => {
                if (url != '') {
                    this.newImage.src = src;
                    this.newImage.file = file;
                    this.newImage.url = url;
                    clearInterval(watcher);
                }
            }, 100)
        },
        changeImage() {
            this.isImageRedact = false;
            if (this.newImage?.src) {
                this.newImage.type = this.newImageType;
                this.newImages.push({
                    src: this.newImage.src,
                    file: this.newImage.file,
                    url: this.newImage.url,
                    type: this.newImage.type
                })
                this.$store.dispatch('activities/updateImages', {
                    id: this.item.id,
                    images: this.newImages
                }).then(() => {
                    this.newImages = [];
                    this.newImage = {};
                })
            }
        },
        changetext(){
            this.$store.dispatch('activities/updateText', {
                    id: this.item.id,
                    text: this.text
                }).then(() => {
                    this.isTextRedact = false;
                })
        }
        /*   addNewImage() {
              this.newImages.push({
                  src: this.newImage.src,
                  url: this.newImage.url,
                  file: this.newImage.file
              });
              this.isFeedbackNewImage = false;
          }, */

    },
    created() {
    },
    mounted() {
        console.log(this.item);
    }
}

</script>
  
<style scoped>
.admin-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    border: 1px solid gray;
    border-radius: 22px;

    background: linear-gradient(180deg, #f7fafd, #ddebff);
    color: #000000;
    line-height: 150%;
    letter-spacing: 0.05em;
    box-shadow: 5px 5px 10px rgb(222, 222, 222);
    padding-bottom: 50px;
}

.info {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.item-content {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
    align-items: flex-start;
    justify-content: center;
}

.item-img {
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 22px;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.888);
    backdrop-filter: blur(3px);
}

.item-img img,
.item-img video {
    margin: 5%;
    width: 320px;
    max-height: 500px;
    min-height: 500px;
    border-radius: 22px;
    object-fit: cover;
}

.item-img-redact {
    margin-top: 10px;
    width: 400px;
    background-color: rgba(238, 122, 254, 0.403);
    padding: 20px;
    border-radius: 10px;
    border: 1px solid gray;
}

.item-img-redact input,
.item-img-redact select {
    background-color: white;
    width: 90%;
}

.item-text {
    flex-basis: 40%;
    padding: 30px;
    border: 1px solid gray;
    border-radius: 10px;
    box-shadow: 5px 5px 10px rgba(222, 222, 222, 0.466);
    background-color: white;
    margin-left: 30px;
    margin-right: 30px;
    white-space: pre-line;
    line-height: 1.3rem;
}

.item-text textarea {
    width: 100%;
    min-height: 400px;
    min-width: 400px
}

.item-actions {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    padding: 15px;
    justify-content: center;
    align-items: center;

}

.item-actions button,
.item-actions input,
.item-actions a {
    width: 90%;
    padding: 7px;
    margin: 5px;
    border-radius: 5px;
    border: 1px solid gray;
    background-color: white;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
}

.item-actions a button {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 7px;
    border: none;
}

.item-actions a {
    padding: 0px;
}

.item-actions a:hover,
.change-position-btn:hover {
    background-color: #0c022c;
    border: 1px solid rgb(255, 255, 255);
    color: white;
}

.item-actions a button:hover {
    background-color: #0c022c;
    color: white;
}

.delete-btn:hover {
    background-color: red;
    color: white;
}



label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

input,
textarea,
select {
    border-radius: 10px;
    padding: 7px;
    border: 1px solid gray;
}

.modal-fade-leave-to,
.modal-fade-enter-from {
    opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
    transition: all 0.5s ease-in-out;
}
</style>
  