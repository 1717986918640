import FeedbackDataService from "@/service/FeedbackDataService";


// initial state
const state = () => ({
    all: []
})

// getters
const getters = {

}

// actions
const actions = {

    getAll({ commit }) {

        let feedback = [];
        FeedbackDataService.getAll().then((res) => {
            let itemsArray = [];
            res.data.forEach(item => {
                let storeItem = new Object();
                let itemImages = [];
                if (item.images != "") {
                    item.images.split(" $;$ ").forEach(image => {

                        let imageItem = new Object();
                        imageItem.type = image.slice(1, 4);
                        imageItem.src = image.slice(5, image.length);

                        if (imageItem.src != "") {
                            itemImages.push(imageItem)
                        }

                    });
                }

                storeItem = JSON.parse(JSON.stringify(item));
                storeItem.images = itemImages;

                itemsArray.push(storeItem);
            });
            feedback = itemsArray;
            commit('setFeedbackRecords', feedback)
        });
    },

    getAllowed({ commit }) {

        let feedback = [];
        FeedbackDataService.getAll().then((res) => {
            let itemsArray = [];
            res.data.forEach(item => {
                if (item.status == 1) {
                    let storeItem = new Object();
                    let itemImages = [];
                    if (item.images != "") {
                        item.images.split(" $;$ ").forEach(image => {

                            let imageItem = new Object();
                            imageItem.type = image.slice(1, 4);
                            imageItem.src = image.slice(5, image.length);

                            if (imageItem.src != "") {
                                itemImages.push(imageItem)
                            }

                        });
                    }

                    storeItem = JSON.parse(JSON.stringify(item));
                    storeItem.images = itemImages;

                    itemsArray.push(storeItem);
                }
            });
            feedback = itemsArray;
            commit('setFeedbackRecords', feedback)
        });
    },


    async create({ commit }, item) {
        let images = "";
        let files = [];
        item.images.forEach(image => {
            images += '|img|' + image.src + ' $;$ '
            files.push(image.file)
        });
        let formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            let file = files[i];
            formData.append('files[' + i + ']', file);
        }

        await FeedbackDataService.create({
            text: item.text,
            date: item.date,
            images: images,
            user_id: item.user_id

        }).then(() => {
            if (item.images.length > 0) {
                FeedbackDataService.saveFile(formData).then(() => {
                    commit('setNewFeedback')
                });
            } else commit('setNewFeedback');
        })
    },
    updatePosition({ commit }, record) {
        FeedbackDataService.updatePosition({
            id: record.id,
            position: record.position,
            oldPosition: record.oldPosition
        }).then(() => {
            commit('setRecordPosition', record);

        });
    },
    updateStatus({ commit }, record) {
        FeedbackDataService.updateStatus({
            id: record.id,
            status: record.status
        }).then(() => {
            commit('setRecordStatus', record);

        });
    },
    delete({ commit }, id) {
        FeedbackDataService.delete(id).then(() => {
            commit('deleteRecord', id)
        });
    }
}

// mutations
const mutations = {
    setFeedbackRecords(state, feedback) {
        state.all = feedback.sort((a, b) => a.position - b.position);
    },
    setNewFeedback() {

    },
    setRecordPosition(state, record) {
        let oldPos = state.all.find(item => item.id === record.id).position;
        state.all.forEach((el) => {
            if (el.id != record.id) {
                if (oldPos > record.position) {
                    if (el.position >= record.position && el.position < oldPos) el.position++;
                } else {
                    if (el.position <= record.position && el.position >= oldPos) el.position--;
                }
            } else el.position = record.position
        })
        state.all.sort((a, b) => a.position - b.position);

    },
    setRecordStatus(state, record) {
        state.all.find(item => item.id === record.id).status = record.status;

    },
    deleteRecord(state, id) {
        const item = state.all.findIndex(el => el.id === id);
        state.all.splice(item, 1);
    }

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}