<template>
  <div class="container">
    <div class="discount-card-description">
      <!-- <img class="arrow-left" src="../assets/images/icons/arrow-left.png" alt=""> -->
      <p class="discount-card-text">{{ discount.title }}</p>
      <!--       <img class="arrow-right" src="../assets/images/icons/arrow-right.png" alt=""> -->
    </div>
    <div class="discount-list">
      <ul>
        <li v-for="item in discountProducts" :key="item">
          <ProductCard :productInfo="item" class="card-scroll-animated" />
        </li>
      </ul>
    </div>

  </div>
</template>

<script>
import ProductCard from '@/components/products/ProductCard.vue'


export default {
  name: 'discountCard',
  data() {
    return {
      discountProducts: []
    }
  },
  components: {
    ProductCard
  },
  props: ['discount'],
  created() {
    console.log(this.discount.products[0]);

    let watcher = setInterval(() => {
      if (this.discount.products.length == this.discount.productsLength) {
        this.discountProducts = this.discount.products;
        clearInterval(watcher);
      }
    }, 100);


  },
  mounted() {
    function cardScrollAnimation() {
      let scrollAnimated = document.querySelectorAll(".card-scroll-animated");

      for (let i = 0; i < scrollAnimated.length; i++) {
        if (!scrollAnimated[i].classList.contains("card-animated-active")) {
          let windowHeight = window.innerHeight;
          let elementTop = scrollAnimated[i].getBoundingClientRect().top;
          let elementVisible = 50;

          if (elementTop < windowHeight - elementVisible) {
            scrollAnimated[i].classList.add("card-animated-active");
          }
        }
        /*  else {
           scrollAnimated[i].classList.remove("animated-active");
           scrollAnimated[i].classList.add("animated-leave");
         } */
      }
    }

    let scrollAnimated = document.querySelector(".discount-list ul");
    scrollAnimated.addEventListener("scroll", cardScrollAnimation);
    window.addEventListener("scroll", cardScrollAnimation);
    window.scrollBy({ left: 0, top: 10, behavior: 'smooth' })
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.card-animated-active {
  opacity: 1 !important;
  z-index: 1 !important;
  transform: none !important
}

.card-scroll-animated {
  opacity: 0.3;
  z-index: -1 !important;
  transform: scale(0.5) translateY(-250px);
  transition: transform 1s ease-in-out, opacity 1s ease-in-out, box-shadow 0.5s ease-in-out !important;

}


.container {
  width: 100%;

}

.discount-card-description {
  background-color: rgba(255, 255, 255, 0.629);
  padding: 20px;
  padding-left: 40px;
  padding-right: 40px;
  margin-right: 20px;
  margin-left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrow-left {
  padding-right: 20px;
}

.arrow-right {
  padding-left: 20px;
}

.discount-card-text {
  white-space: pre-line;
  text-align: justify;

}

.discount-list {
  width: 100%;
  display: flex;
  justify-content: center;
}

.discount-list ul {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 0;
  width: max-content;
  margin-right: 20px;
  margin-left: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fill, 300px);
  grid-auto-flow: column;
  grid-auto-columns: 300px;
  grid-auto-rows: 500px;
  overflow: scroll;
  gap: 50px 20px;

}

.discount-list:last-child {
  padding-right: 20px;
}


@media(max-width:800px) {
  .discount-list ul{
    grid-template-columns: repeat(auto-fill, 220px);
    grid-auto-flow: column;
    grid-auto-columns: 220px;
    grid-auto-rows: 350px;  
  }

}

@media(max-width:560px) {
  .discount-list ul{
    grid-template-columns: repeat(auto-fill, 180px);
    grid-auto-flow: column;
    grid-auto-columns: 180px;
    grid-auto-rows: 300px;  
  }

}

@media(max-width:460px) {
  .discount-list ul{
    grid-template-columns: 250px;
    grid-auto-flow: row;
    grid-auto-columns: 250px;
    grid-auto-rows: 400px;  
    overflow: scroll;
    height: 100vh;
    gap: 20px 20px;
  }

}

@media(max-width:300px) {
  .discount-list ul{
    grid-template-columns: 200px;
    grid-auto-flow: row;
    grid-auto-columns: 200px;
    grid-auto-rows: 350px;  
    overflow: scroll;
    height: 100vh;
    gap: 20px 20px;
  }

}
</style>
