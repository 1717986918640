import UserDataService from "@/service/UserDataService";
import ProductDataService from "@/service/ProductDataService";



// initial state
const state = () => ({
    all: 'Корзина пуста'
})

// getters
const getters = {
    getCart(state) {
        return state.all;
    }
}



// actions
const actions = {


    async getUserCartInfo({ commit }, userLogin) {
        let userInfo = {};
        await UserDataService.getItem(userLogin).then((res) => {
            userInfo = JSON.parse(JSON.stringify(res.data));
        })
        if (userInfo.cart != "") {
            let productsList = userInfo.cart.split(",");
            let productsObjectList = [];
            productsList.forEach(product => {
                ProductDataService.getItem(product).then((res) => {

                    let itemProduct = new Object();
                    let itemImages = [];

                    res.data.images.split(" $;$ ").forEach(image => {

                        let imageItem = new Object();
                        imageItem.type = image.slice(1, 4);
                        imageItem.src = image.slice(5, image.length);

                        if (imageItem.src != "") {
                            itemImages.push(imageItem)
                        }

                    });

                    itemProduct.id = res.data.id;
                    itemProduct.name = res.data.name.replace(/&quot;/g, '"');
                    itemProduct.position = res.data.position;
                    itemProduct.description = res.data.description;
                    itemProduct.size = res.data.size;
                    itemProduct.composition = res.data.composition;
                    itemProduct.price = res.data.price;
                    itemProduct.discountPrice = res.data.discountPrice;
                    itemProduct.category = res.data.category;
                    itemProduct.images = itemImages;

                    productsObjectList.push(itemProduct);
                });
            });

            productsObjectList.sort((a, b) => Number(a.id) - Number(b.id))
            commit('setUserCartInfo', productsObjectList)
        } else {
            await commit('setUserCartInfo', "Корзина пуста")
        }

    },

    async removeFromCart({ commit, state }, record) {

        let removedIndex = state.all.findIndex((el) => el.id == record.itemID);
        await commit('setCartAfterRemove', removedIndex)
    },

    async addToCart({ commit }, item) {
        await commit('setCartAfterAdded', item)
    },

    async clearCart({ commit }, userID) {
        UserDataService.updateCart({
            id: userID,
            cart: ""
        }).then(() => {
            commit('clear')
        });

    }

}

// mutations
const mutations = {
    setUserCartInfo(state, products) {
        state.all = products;
    },

    setCartAfterRemove(state, index) {
        state.all.splice(index, 1);
        if (state.all.length == 0) state.all = 'Корзина пуста';
    },

    setCartAfterAdded(state, item) {
        console.log(item);
        if (state.all == 'Корзина пуста') {
            state.all = [];
            state.all.push(item);
        } else state.all.push(item);
    },
    clear(state) {
        state.all = 'Корзина пуста';
    }

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}