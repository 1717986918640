<template>
  <div class="accept-modal">

    <div class="accept-modal-container">
      <div class="accept-modal-title">Загрузка</div>
      <div class="accept-modal-text">
        Пожалуйста подождите, идет загрузка
      </div>
      <div class="accept-modal-content">
        <div class="animation__1">
          <span class="animation__1-1"></span>
          <span class="animation__1-2"></span>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'LoadingModal',
  props: ['message'],
  mounted() {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
a {
  text-decoration: none;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #06003e;
  transition: all .5s ease-in-out;
  text-shadow: 5px 5px 10px #1700e84c;
  font-size: 1.4rem;
}

a:hover {
  color: #c800db;
  text-shadow: 5px 5px 10px #c800db;
}

.accept-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.704);
  z-index: 20;
  cursor: no-drop;
}

.accept-modal-container {
  height: 400px;
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.884);
  backdrop-filter: blur(3px);
  border-radius: 15px;
  cursor: default;
  font-size: 1.3rem;
}

.accept-modal-title {
  width: 100%;
  min-height: 40px;
  height: 80px;
  background: linear-gradient(0deg, #070b0f, #111b29);
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  color: white;
}

.accept-modal-content {
  margin-top: 20px;
  height: 320px;
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.accept-modal-text {
  margin-top: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;

  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  white-space: pre-line;
}
.animation__1 {
  width: 200px;
  margin-top: 50px;
  position: relative;
  display: flex;
  justify-content: center;
  height: 200px;
}

.animation__1 span {
  display: block;
  margin: 5px;
  justify-self: center;
  position: absolute;
  border-radius: 10px;
}

.animation__1-1 {
  left: 95px;
  width: 50px;
  height: 100px;
  background-color: blueviolet;
  animation: loading-1 5s ease-in-out infinite;
  z-index: 10;
}

.animation__1-2 {
  left: 35px;
  height: 100px;
  width: 50px;
  background-color: rgb(0, 229, 255);
  animation: loading-2 5s ease-in-out infinite;
}

@keyframes loading-1 {
  0% {}
  12% {
      transform: translateY(60px);
      height: 50px;
      width: 50px;
  }
  24% {
      transform: translateX(-60px) translateY(60px);
      width: 100px;
      height: 50px;
      background-color: rgb(0, 255, 123);
  }
  36% {
      transform: translateX(-60px) translateY(60px);
      height: 50px;
      width: 50px;
  }
  48% {
      background-color: rgb(0, 229, 255);
      transform: translateX(-60px);
      height: 100px;
      width: 50px;
  }
  60% {
      transform: translateX(-60px);
      height: 50px;
      width: 50px;
  }
  75% {
      transform: translateX(-60px);
      height: 50px;
      width: 100px;
      background-color: rgb(77, 255, 0);
  }
  87% {
      transform: none;
      height: 50px;
      width: 50px;
  }
}

@keyframes loading-2 {
  0% {}
  12% {
      height: 50px;
      width: 50px;
  }
  24% {
      width: 100px;
      height: 50px;
      transform: none;
      background-color: rgb(186, 43, 226);
  }
  36% {
      transform: translateX(60px);
      height: 50px;
      width: 50px;
  }
  48% {
      transform: translateX(60px);
      height: 100px;
      width: 50px;
      background-color: rgb(226, 43, 43);
  }
  60% {
      transform: translateY(60px) translateX(60px);
      height: 50px;
      width: 50px;
  }
  75% {
      transform: translateY(60px);
      height: 50px;
      width: 100px;
      background-color: rgb(186, 43, 226);
  }
  87% {
      transform: translateY(60px);
      height: 50px;
      width: 50px;
  }
}



@media(max-width:960px) {

  .accept-modal-container{
    height: 400px;
    width: 400px;
    overflow: scroll;
    max-height: 90vh;
  }
  
}


@media(max-width:480px) {

  .accept-modal-container{
    height: 400px;
    width: 300px;
  }
  
}

@media(max-width:350px) {

  .accept-modal-container{
    height: 400px;
    min-width: 250px;
    width: 90%;
  }
  
}


@media(max-height:320px) {

 .accept-modal-text{
  margin-top: 10px;
 }
 .animation__1{
  margin-top: 10px;
 }
  
}

</style>
