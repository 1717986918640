<template>
    <section @click="changeState" ref="activityCard" class="activity-container">
        <h3>{{ activityInfo.title }}</h3>

        <div class="activity-content" ref="activityContent">
            <img v-if="activityInfo.images[0].type == 'img'" class="card-image" @load="changeSizes"
                :src="$store.state.serverRoot+'images/products/' + activityInfo.images[0].src.split('.')[0] + '500x410.' + activityInfo.images[0].src.split('.').pop()"
                alt="">

            <video v-else class="card-image" autoplay="autoplay" loop controls muted preload="auto">
                <source :src="$store.state.serverRoot+'images/products/' + activityInfo.images[0].src" type="video/mp4">
            </video>
        </div>

        <img v-if="!isShowed" class="arrow-img" src="../assets/images/icons/arrow-to-bottom.png" alt="">
        <div ref="activityCardContent" class="activity-card-content">
            <p>{{ activityInfo.text }}</p>
        </div>
    </section>
</template>

<script>

export default {
    name: 'ActivityCard',
    props: ['activityInfo'],
    data() {
        return {
            isShowed: false
        }
    },
    methods: {
        changeState() {
            if (this.isShowed) this.close();
            else this.show();
        },
        changeSizes() {
            let watcher = setInterval(() => {
                if (this.$refs.activityCard?.style) {
                    this.$refs.activityCard.style.height = 100 + this.$refs.activityContent.clientHeight + 'px';
                    clearInterval(watcher);
                }

            }, 100);

        },
        show() {
            this.isShowed = true;
            this.$refs.activityCard.style.height = 50 +
                this.$refs.activityContent.clientHeight +
                this.$refs.activityCardContent.offsetHeight + 'px';
            clearTimeout();
            this.$emit('onShowed', this.$refs.activityCard.offsetHeight);
        },
        close() {
            this.$refs.activityCard.style.height = 100 + this.$refs.activityContent.clientHeight + 'px'
            setTimeout(() => this.isShowed = false, 200);
        }
    },
    mounted() {
        let watcher = setInterval(() => {
            if (this.$refs.activityCard?.style) {
                this.$refs.activityCard.style.height = 100 + this.$refs.activityContent.clientHeight + 'px';
                window.addEventListener("resize", this.changeSizes);
                clearInterval(watcher);
            }

        }, 100);

    },
    unmounted() {
        window.removeEventListener("resize", this.changeSizes);
    },

}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.activity-container {
    width: 100%;
    background-color: rgba(255, 255, 255, 0.93);
    border: 1px solid white;
    padding: 20px;
    border-radius: 10px;
    height: auto;
    overflow: hidden;
    transition: 0.7s all ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    box-shadow: 3px 3px 10px rgba(225, 225, 225, 0.588);

}

.activity-container:hover {
    overflow: none;
}

.activity-content img,
.activity-content video {
    min-height: max-content;
    margin-top: 30px;
    border-radius: 10px;
    min-width: 20vw;
    max-width: 20vw;
    object-fit: cover;
    min-height: 20vh;
    max-height: 50vh;
}

.activity-content {
    border-radius: 10px;
}

.arrow-img {
    margin-top: 20px;
    height: 35px;
}

p,
h3 {
    color: rgb(23, 2, 58);
    white-space: pre-line;
    line-height: 1.3rem;
    padding: 20px;
    text-align: center;
    padding-top: 0px;
    padding-bottom: 0;
    margin-top: 20px;

}



h3 {
    margin: 0;
    text-align: center;
}

@media(max-width:1050px) {
    .card-image {
        max-width: none !important;
        max-height: 50vh;
    }
}

@media(max-width:800px) {
    .activity-content {
        width: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .card-image {
        max-width: 100% !important;
    }
}

@media(max-width:450px) {
    .activity-content {
        width: 95%;
    }
}
</style>
  