<template>
    <div class="modal-container" @click="closeModal">
        <div class="products-list" @click="nothing">
            <ul>
                <li v-for="item in products" :key="item">
                    <ProductCard :productInfo="item" />
                    <div class="btns-container">
                        <template v-if="this.mode == 'edit'">
                            <button class="btn-standart" v-if="item != editedItem"
                                @click="changeItem(item)">Выбрать</button>
                            <button class="btn-save " v-else @click="changeItem(item)">Выбрано</button>
                        </template>

                        <template v-else>
                            <button class="btn-standart" v-if="newItems.indexOf(item) < 0"
                                @click="addItem(item)">Добавить</button>
                            <button class="btn-save " v-else @click="removeItem(item)">Добавлено</button>
                        </template>

                    </div>
                </li>
            </ul>
            <div class="btns-container">
                <button class="btn-standart btn-big" @click="update">Сохранить</button>
            </div>
        </div>

    </div>
</template>

<script>

import ProductCard from '@/components/products/ProductCard.vue'
import { mapState } from 'vuex'

export default {
    name: 'GalleryModal',
    props: ['item', 'contentLocation', 'mode'],
    components: {
        ProductCard
    },
    data() {
        return {
            modalState: true,
            editedItem: 0,
            newItems: []
        }
    },
    computed: {
        // смешиваем результат mapState с внешним объектом
        ...mapState({
            products: state => state.products.all
            // ...
        })
    },
    watch: {
        // eslint-disable-next-line
        products(newValue) {
            this.showedProducts = newValue.slice(0, this.showedProductsCount);
            this.searchResults = newValue;
        },
        modalState(newValue) {
            if (!newValue) {
                this.$emit('onCloseModal');
            }

        }
    },
    methods: {
        update() {
            if (this.mode == 'edit') this.$emit('onChangeItem', this.editedItem);
            else this.$emit('onAddItem', this.newItems);
            this.modalState = false;
        },
        addItem(item) {
            this.newItems.push(item);
        },
        removeItem(item){
            let index =  this.newItems.findIndex(el=> el.id==item.id);
            this.newItems.splice(index,1);
        },
        changeItem(item) {
            this.editedItem = item;
        },
        closeModal() {
            this.modalState = false;
        },
        nothing(e) {
            e.stopPropagation();
        }
    },
    created() {
        if (this.$store.state.products?.all.length == 0) {
            this.$store.dispatch('products/getAll');
        }
        else {
            this.products = this.$store.state.products.all;
        }
    },
    mounted() {
        console.log(this.products)
        window.addEventListener('keyup', (e) => {
            if (e.key == 'Escape') {
                this.modalState = false;
            }
        })

    }
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.704);
    z-index: 20;
    cursor: no-drop;
    backdrop-filter: blur(3px);
}

.products-list {
    width: 80%;
    height: 100%;
    background-color: aliceblue;
    position: relative;
    z-index: 100;
    cursor: default;
}

ul {
    max-height: 90vh;
    height: calc(100vh - 120px);
    overflow-y: auto;
    display: grid;
    grid-template-columns: repeat(auto-fill, 250px);
    grid-auto-rows: 500px;
    gap: 30px 20px;



}

li {
    height: 480px;
    width: 100%;
    background: linear-gradient(0deg, #070b0f, #111b29);
    border-radius: 20px;
}



.product-card {
    max-height: calc(100% - 80px) !important;
    min-height: calc(100% - 80px) !important;
    height: calc(100% - 80px) !important;

    width: 250px !important;
    background-color: white;
}

.product-card a {
    height: 90% !important;
}
</style>
  