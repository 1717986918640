
<template>
  <div class="user">
    <div class="user-info">
      <div class="user-photo">
        <img v-if="user.photo == '' " src="../assets/images/icons/empty-photo.jpg" alt="">
        <img v-else  :src="$store.state.serverRoot+'images/users/' + user.photo.split('.')[0] + '500x410.'+ user.photo.split('.').pop()" alt="">
      </div>
      <div class="user-data">
        <p>Логин: {{ user.login }}</p>
        <p>Имя пользователя: {{ user.name }}</p>
<!--         <p>Email: {{ user.email }}</p> -->
<!--         <p v-if="user.phoneNumber!='' && user.phoneNumber != 0">Номер телефона: {{ user.phoneNumber }}</p> -->
      </div>
      <div class="user-btns">
        <div class="sign-out" v-if="user.role == 'admin'">
          <button class="sign-out-btn" @click="goAdmin">Панель администратора</button>
        </div>
        <div class="sign-out">
          <button class="sign-out-btn redact-profile" @click="modalUpdateState = true">Редактировать профиль</button>
        </div>
        <div class="sign-out">
          <button class="sign-out-btn" @click="signOut">Выйти</button>
        </div>
      </div>

    </div>
    <div class="user-actions">
      <h3>Активные заказы</h3>
      <div class="active-orders orders">

        <template v-if="activeOrders.length == 0">
          <div class="user-no-orders">
            <p>На данный момент у вас нет активных заказов</p>
          </div>
        </template>

        <template v-else>
          <orderCard v-for="order in activeOrders" :key="order.id" :order="order" class="orders"></orderCard>
        </template>

      </div>

      <h3>Завершенные заказы</h3>
      <div class="completed-orders orders">

        <template v-if="completedOrders.length == 0">
          <div class="user-no-orders">
            <p>На данный момент у вас нет завершенных заказов</p>
          </div>
        </template>

        <template v-else>
          <orderCard v-for="order in completedOrders" :key="order.id" :order="order" class="orders"></orderCard>
        </template>

      </div>
    </div>

    <transition name="modal-fade">
      <updateUserInfoModal v-if="modalUpdateState" @onCloseUpdateModal="modalUpdateState = false" :user="user">
      </updateUserInfoModal>
    </transition>
  </div>
</template>

<script>
// @ is an alias to /src
import updateUserInfoModal from '@/components/modals/updateUserInfoModal.vue'
import orderCard from '@/components/OrderCard.vue'
import { mapState } from 'vuex'

export default {
  name: 'userCardView',
  components: {
    orderCard,
    updateUserInfoModal
  },
  data() {
    return {
      activeOrders: [],
      completedOrders: [],
      modalUpdateState: false,
      windowWidth: window.innerWidth
    }
  },
  computed: {

    // смешиваем результат mapState с внешним объектом
    ...mapState({
      orders: state => state.order.all,
      user: state => state.user.all
      // ...
    })
  },
  watch: {
    user(newValue) {
      this.$store.dispatch('order/getUserOrders', newValue.id);
    },
    orders(newValue) {
      if (newValue != "") {
        newValue.forEach(order => {
          if (order.status <= 4) this.activeOrders.push(order);
          else this.completedOrders.push(order);
        });
      }
    }
  },
  methods: {
    signOut() {
      this.$store.dispatch('user/signOut').then(() => {
        this.$router.push('/sign-in')
      });
    },
    goAdmin() {
      this.$router.push('/admin');
    }
  },
  created() {
    this.$store.commit('setActivePage', 'user');
    if (this.user?.id) {
      this.$store.dispatch('order/getUserOrders', this.user.id);
    }
    window.addEventListener('resize',()=>{
      this.windowWidth = window.innerWidth
    })
  },
  mounted() {
    window.scroll({
      left: 0, // до какого количества пикселей прокрутить вправо
      top: 0, // до какого количество пикселей прокрутить вниз
      behavior: 'smooth' // определяет плавность прокрутки: 'auto' - мгновенно (по умолчанию), 'smooth' - плавно
    });
  }
}

</script>

<style scoped>
h3 {
  text-align: center;
  width: 100%;
  border-bottom: 1px solid gray;
  border-top: 1px solid transparent;
  background-color: rgb(0, 0, 42);
  color: white;
  font-weight: 400;
  margin: 0;
  padding: 5px;

}

h3:first-child {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border: 1px solid transparent;
}

.user {
  padding: 80px 20px 80px 20px;
  min-height: 100vh;
  max-width: 100%;
  /* background-image: url("../assets/images/mainPage/11.jpg");*/
  background-color: rgba(251, 243, 255, 0.678);
  background: linear-gradient(to bottom, rgb(254, 247, 247), rgba(253, 255, 188, 0.129),rgb(255, 246, 246));
  background-size: cover;
  position: relative;
  display: flex;
  flex-direction: row;
}

.user-info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-height: calc(100vh - 150px);
  width: 300px;
  border: 1px solid gray;
  border-radius: 10px;
  padding: 30px 0px 30px 0px;
  background-color: rgba(255, 255, 255, 0.873);
}

.user-photo {
  display: flex;
  justify-content: center;
  width: 100%;
}

.user-photo img {
  border-radius: 50%;
  width: 200px;
  height: 200px;
  object-fit: cover;
}

.user-data {
  width: 100%;
}

.user-data p:first-child {
  border-top: 1px solid gray;
  margin-top: 30px;
}

.user-data p {
  border-bottom: 1px solid gray;
  margin: 0;
  padding: 10px;
}

.user-data p:nth-child(odd) {
  background-color: rgb(255, 254, 249);
}

.user-btns {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.sign-out {
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.sign-out-btn {
  width: 80%;
  padding: 5px 40px 5px 40px;
  border: 1px solid gray;
  border-radius: 10px;
  color: white;
  background-color: rgb(2, 2, 72);
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}

.sign-out-btn:hover {
  color: rgb(2, 2, 72);
  background-color: rgb(255, 255, 255);
}

.redact-profile {
  background-color: white;
  color: rgb(2, 2, 72);
}

.redact-profile:hover {
  color: white;
  background-color: rgb(2, 2, 72);
}

.user-actions {
  width: calc(100% - 300px);
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-height: calc(100vh - 150px);
  max-height: calc(100vh - 100px);
  overflow-y: scroll;
  border: 1px solid gray;
  border-radius: 10px;
  padding: 0px;
  background-color: rgba(255, 255, 255, 0.792);
}

.user-actions div {
  width: 100%;
}

.orders {
  border-bottom: 1px solid gray;
}

.user-no-orders {
  width: 100%;
  min-height: 40vh;
  padding: 30px;
  text-align: center;
  font-size: 1.4rem;
}

.active-orders:last-child {
  border-bottom: 1px solid transparent;
}

.orders:last-child {
  border-bottom: 1px solid transparent;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.orders:nth-child(odd) {
  background-color: rgba(255, 249, 247, 0.784);
}

.completed-orders {
  background-color: rgba(255, 246, 230, 0.908);
  
}

.completed-orders .user-no-orders {
  color: rgb(0, 0, 0);
}



@media(max-width:960px) {
  .user-actions{
    width: 100%;
    margin-left: 0;
  }

  .user{
    flex-direction: column;
  }

  .user-info{
    width: 100%;
    min-height: 0;
    flex-direction: row;
    margin-bottom: 10px;
    align-items: center;
  }

  .user-data{
    padding: 0;
    border: 1px solid gray;
    padding-bottom: 30px;
    border-radius: 10px;
  }
  
}


@media(max-width:800px) {

  .user-info img{
    height: 150px;
    width: 150px;
  }

  
}

@media(max-width:650px) {

  .user-info{
    width: 100%;
    min-height: 0;
    flex-direction: column;
    margin-bottom: 10px;
    align-items: center;
  }
  .user-data{
    border: none;
  }
  
}

@media(max-width:400px) {
  .user{
    padding-left: 5px;
    padding-right: 5px;
  }

  
}
</style>
