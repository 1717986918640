
<template>
    <div class="admin-feedback-item">

        <FeedbackCard :feedbackCardInfo="itemInfo" class="feedback-item  " />

        <div class="feedback-item-actions">

            <div class="actions-block">
                <button v-if="!isDeleted" class="delete-btn" @click="isDeleted = true">Удалить</button>
                <button v-else class="delete-confirm-btn" @click="deleteItem">Точно удалить?</button>

                <button v-if="itemInfo.status == 1" class="delete-btn" @click="changeStatus(0)">Скрыть</button>
                <button v-else class="allow-btn" @click="changeStatus(1)">Отобразить</button>
            </div>


            <div class="position-block">
                <p>Позиция №{{ itemInfo.position }}</p>
                <input type="number" v-model="position">
                <button class="change-position-btn" @click="changePosition">Сменить позицию</button>
            </div>

        </div>

        <!--         <transition name="modal-fade">
            <AcceptModal v-if="modalState" 
            @onCloseModal="closeModal"
            @onAccept="deleteItem"
             :item="modalItem" :message="`Вы уверены что хотите удалить \r\n` + `'` + product.name + `'`"
             :contentLocation="'products'">
            </AcceptModal>
        </transition> -->

    </div>
</template>
  
<script>
// @ is an alias to /src
import FeedbackCard from '@/components/FeedbackCard.vue'


export default {
    name: 'adminProductItem',
    components: {
        FeedbackCard
    },
    props: ['itemInfo'],
    data() {
        return {
            position: 0,
            isDeletedConfirm: false,
            isDeleted: false,
            modalItem: {},
            modalState: false
        }
    },
    computed: {

    },
    methods: {
        deleteItem() {
            this.isDeleted = false
            this.$store.dispatch('feedback/delete', this.itemInfo.id)
        },
        changePosition() {
            this.$store.dispatch('feedback/updatePosition', {
                id: this.itemInfo.id,
                position: this.position,
                oldPosition: this.itemInfo.position
            })

        },
        changeStatus(newValue) {
            this.$store.dispatch('feedback/updateStatus', {
                id: this.itemInfo.id,
                status: newValue
            })
        }

    },
    created() {
    }
}

</script>
  
<style scoped>
.admin-feedback-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    border: 1px solid gray;
    border-radius: 22px;
    background: linear-gradient(0deg, #070b0f, #111b29);
    line-height: 150%;
    letter-spacing: 0.05em;
    box-shadow: 0 0 1px #f00, 0 0 1px #f00, 0 0 1px #f00, 0 0 1px #f00, 0 0 10px #d0e9 inset, 0 0 35px #53d5;
}

.feedback-item {
    width: 100%;
    border-radius: 22px;
    background-color: white !important;
}

.feedback-item-actions {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    padding: 15px;
    justify-content: center;
    align-items: flex-end;
    color: #fff;
}

.actions-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 200px;
}

.position-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 200px;
    align-items: center;
}

.feedback-item-actions button,
.feedback-item-actions input,
.feedback-item-actions a {
    width: 90%;
    padding: 7px;
    margin: 5px;
    border-radius: 5px;
    border: 1px solid gray;
    background-color: white;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
}

.feedback-item-actions a button {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 7px;
    border: none;
}

.feedback-item-actions a {
    padding: 0px;
}

.feedback-item-actions a:hover,
.change-position-btn:hover {
    background-color: #0c022c;
    border: 1px solid rgb(255, 255, 255);
    color: white;
}

.feedback-item-actions a button:hover {
    background-color: #0c022c;
    color: white;
}

.delete-btn:hover {
    background-color: red;
    color: white;
}

.allow-btn {
    background-color: rgb(26, 255, 0) !important;
    color: black !important;
}

.allow-btn:hover {
    background-color: #0c022c !important;
    color: white !important;
}

.delete-confirm-btn {
    background-color: red !important;
    color: white;
}

.modal-fade-leave-to,
.modal-fade-enter-from {
    opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
    transition: all 0.5s ease-in-out;
}
</style>
  