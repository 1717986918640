import axios from 'axios'
import serverRoot from "@/service/serverRoot.js";

let path = serverRoot.getRoot();
const USER_API_URL = path + 'mysql-crud-api/operations/order'

class OrderDataService {

    getALL() {
        return axios.get(`${USER_API_URL}/readAll.php`)
    }

    getUserOrders(user_id) {
        return axios.get(`${USER_API_URL}/readUserOrders.php`, { params: { user_id: user_id } })
    }

    update(item) {

        return axios.post(`${USER_API_URL}/update.php`, item);
    }

    updateProducts(item) {

        return axios.post(`${USER_API_URL}/updateProducts.php`, item);
    }

    create(item) {

        return axios.post(`${USER_API_URL}/create.php`, item);
    }

}

export default new OrderDataService()