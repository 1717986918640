<template>
    <div class="accept-modal" 
    @click="closeModal">

        <div class="accept-modal-container"
        @click="(e)=> e.stopPropagation()">
            <div class="accept-modal-title">Внимание!</div>
            <div class="accept-modal-text">
                {{ message }}
            </div>
            <div class="accept-modal-content">
                <template v-if="item!== undefined">
                    <img v-if="item.type == 'img'" :src="$store.state.serverRoot+'images/' + contentLocation + `/` + item.src"
                        alt="">

                    <video v-else @click="nothing" autoplay="autoplay" loop controls muted preload="auto">
                        <source :src="$store.state.serverRoot+'images/' + contentLocation + `/` + item.src" type="video/mp4">
                    </video>
                </template>

                <template v-else>
                    <img  src="../../assets/images/icons/empty-photo.jpg"
                        alt="">
                </template>

            </div>
            <div class="accept-modal-btns">
                <button class="accept" @click="accept">Да</button>
                <button class="cancel" @click="closeModal">Отмена</button>
            </div>
        </div>

    </div>
</template>

<script>

export default {
    name: 'AcceptModal',
    props: ['item', 'message', 'contentLocation'],
    data() {
        return {
            modalState: true
        }
    },
    methods: {
        closeModal() {
            this.modalState = false;
        },
        accept() {
            this.$emit('onAccept');
            this.modalState = false;
        }
    },
    watch: {
        // eslint-disable-next-line
        modalState(newValue, oldValue) {
            if (!newValue) {
                this.$emit('onCloseModal');
            }

        }
    },
    mounted() {

    }
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.accept-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.704);
    z-index: 20;
    cursor: no-drop;
}

.accept-modal-container {
    min-height: 60%;
    max-height: 80vh;
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.884);
    backdrop-filter: blur(3px);
    border-radius: 15px;
    cursor:default;
}

.accept-modal-title {
    width: 100%;
    height: 50px;
    background: linear-gradient(0deg, #070b0f, #111b29);
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    color: white;
}

.accept-modal-content {
    margin-top: 20px;
    height: 60%;
    width: 60%;
    display: flex;
    justify-content: center;
}

.accept-modal-text {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;

    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    white-space:pre-line;
}

.accept-modal-btns {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 20px;
}

.accept-modal-btns button {
    padding: 10px;
    width: 150px;
    border-radius: 10px;
    margin-bottom: 10px;
    border: 1px solid gray;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
}

.accept {
    background-color: red;
    color: white;
}

.accept-modal-btns button:hover {
    background-color: #070b0f;
    color: white;
}

.accept-modal-content img,
.accept-modal-content video {
    max-width: 100%;
    max-height: 100%;
    border-radius: 10px;
}
</style>
  