import OrderDataService from "@/service/OrderDataService";
/* import ProductDataService from "@/service/ProductDataService"; */



// initial state
const state = () => ({
    all: []
})

// getters
const getters = {
    getOrders(state) {
        return state.all;
    }
}



// actions
const actions = {


    async getUserOrders({ commit }, userID) {
        /* let userInfo = {}; */
        let orders = [];

        await OrderDataService.getUserOrders(userID).then((res) => {
            orders = JSON.parse(JSON.stringify(res.data));
            commit('setOrdersInfo', orders)
        })

    },
    async getAllOrders({ commit }) {
        /* let userInfo = {}; */
        let orders = [];

        await OrderDataService.getALL().then((res) => {
            orders = JSON.parse(JSON.stringify(res.data));
            commit('setOrdersInfo', orders)
        })


    },

    async createOrder({ commit }, order) {

        await OrderDataService.create(order).then(() => {
            commit('setNewOrder', order)
        })

    },

    async updateOrder({ commit }, order) {

        await OrderDataService.update(order).then((res) => {
            console.log(res);
            commit('setOrderChanges', order)
        })

    },
    async updateOrderProducts({ commit }, order) {

        await OrderDataService.updateProducts(order).then((res) => {
            console.log(res.data)
        })
        commit('setOrderChanges', order)

    },
}

// mutations
const mutations = {
    setOrdersInfo(state, orders) {
        state.all = orders;
    },

    setNewOrder(state, order) {
        if (state.all == "") state.all = [];
        state.all.push(order);
    },

    setOrderChanges(state, order) {
        let index = state.all.findIndex(el => el.id == order.id);
        state.all[index] = order;
    }


}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}