import UserDataService from "@/service/UserDataService";

// initial state
const state = () => ({
    all: []
})

// getters
const getters = {
    getUser(state) {
        return state.all;
    }
}

// actions
const actions = {

    async getInfo({ commit }, login) {
        let userInfo = {};
        await UserDataService.getItem(login).then((res) => {
            userInfo = JSON.parse(JSON.stringify(res.data));
            userInfo.cart = userInfo.cart.split(',').sort((a, b) => Number(a) - Number(b)).join(',')
            commit('setUser', userInfo)
        })
    },

    async updatePhoto({ commit }, user) {

        let formData = new FormData();
        let i = 0;
        formData.append('files[' + i + ']', user.photo.file);
        await UserDataService.savePhoto(formData).then((res) => {
            console.log(res);
            UserDataService.updatePhoto({
                id: user.id,
                photo: user.photo.src
            }).then((res) => {
                console.log(res.data);
                setTimeout(() => {
                    commit('updatePhoto', user.photo.src);
                }, 1000);

            });
        });

    },
    updateInfo({ commit }, user) {
        UserDataService.updateInfo({
            id: user.id,
            name: user.name,
/*             email: user.email,
            phoneNumber: user.phoneNumber, */
            password: user.password
        }).then((res) => {
            console.log(res.data);
            commit('updateInfo', user)
        });
    },
    updateCart({ commit }, user) {
        UserDataService.updateCart({
            id: user.id,
            cart: user.cart
        }).then(() => {
            commit('updateCart', user.cart)
        });
    },
    clearCart({ commit }, userID) {
        UserDataService.updateCart({
            id: userID,
            cart: ''
        }).then(() => {
            commit('updateCart', '')
        });
    },

    signOut({ commit }) {
        UserDataService.signOut().then(() => {
            commit('clearUser')
        })
    }

}

// mutations
const mutations = {
    setUser(state, userInfo) {
        state.all = userInfo;
    },
    updateCart(state, cart) {
        state.all.cart = cart;
    },
    updatePhoto(state, photo) {
        state.all.photo = photo;
    },
    updateInfo(state, user) {
        state.all.name = user.name;
/*         state.all.email = user.email;
        state.all.phoneNumber = user.phoneNumber; */
    },
    clearUser(state) {
        state.all = [];
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}