<template>
  <div class="product-card" v-if="productInfo">
    <router-link :to="linkOpen" :class="{ minimized: viewMode == 'order' }">

      <template v-if="productInfo.images != undefined">
        <img v-if="productInfo.images[0].type == 'img'" class="card-image" decoding="async"
          :src="$store.state.serverRoot+'images/products/' + preViewImg" alt="">

        <video v-else class="card-image" autoplay="autoplay" loop controls muted preload="auto">
          <source :src="$store.state.serverRoot+'images/products/' + productInfo.images[0].src" type="video/mp4">
        </video>
      </template>
      <template v-else>
        <img class="card-image" src="../../assets/images/icons/empty-photo.jpg" alt="">
      </template>

      <p class="title" :title="productInfo.name">{{ productInfo.name }}</p>
      <div class="price-block">
        <p v-if="productInfo.discountPrice" class="discount-price">
          {{ productInfo.discountPrice }}₽</p>
        <p class="price" :class="{ 'old-price': productInfo.discountPrice }">{{ productInfo.price }}₽</p>
      </div>

    </router-link>
    <div class="cart-block" v-if="viewMode != 'order'">
      <div @click="addToCart" class="cart-block-btn" :title="!isInCart ? 'Добавить в корзину' : 'Убрать из корзины'">
        <!-- <img src="../../assets/images/icons/add.png" alt=""> -->
        <div class="cart-operations-btn">
          <div class="first-line" :class="{ 'first-line-done': isInCart }"></div>
          <div class="second-line" :class="{ 'second-line-done': isInCart }"></div>
        </div>
        <img src="../../assets/images/icons/cart.png" alt="">
      </div>

    </div>

    <transition name="modal-fade">
      <SignInModal v-if="modalState" @onCloseModal="modalState = false"
        :message="'Для того, чтобы добавить товар в корзину необходимо авторизоваться'">
      </SignInModal>
    </transition>

  </div>
</template>

<script>
import SignInModal from '@/components/modals/SignInModal.vue'

export default {
  name: 'ProductCard',
  components: {
    SignInModal
  },
  props: ['productInfo', 'viewMode'],
  data() {
    return {
      isInCart: false,
      modalState: false,
      preViewImg: ""
    }
  },
  methods: {
    addToCart() {
      let user = JSON.parse(JSON.stringify(this.$store.getters['user/getUser']));
      if (user?.id) {
        if (!this.isInCart) {
          this.$store.dispatch('cart/addToCart', this.productInfo);
          this.isInCart = true;
          let itemID = this.productInfo.id;
          if (user.cart.length == 0) user.cart += itemID
          else user.cart += ',' + itemID;
          this.$store.dispatch('user/updateCart', user)

          /* this.$store.commit('addToCart', this.productId); */
        }
        else this.removeFromCart();
      }
      else{
        this.modalState = true;
      }
    },
    removeFromCart() {
      this.isInCart = false;
      let user = JSON.parse(JSON.stringify(this.$store.getters['user/getUser']));
      let itemID = this.productInfo.id;
      this.$store.dispatch('cart/removeFromCart', {
        user: user,
        itemID: itemID
      });

      user.cart = user.cart.split(',');
      let itemIndex = user.cart.findIndex((el) => el == itemID);
      user.cart.splice(itemIndex, 1);
      if (user.cart.length == 0) user.cart = ''
      else if (user.cart.length == 1) user.cart = String(user.cart[0]);
      else user.cart = user.cart.join(',');

      this.$store.dispatch('user/updateCart', user)

      /* this.$store.commit('removeFromCart', this.productId); */
    }
  },
  computed: {
    linkOpen() {
      return `/products/${this.productInfo.id}`;
    },
    productId() {
      return +this.productInfo.id;
    }/* ,
    isInCart() {
      return this.$store.getters['cart/isInCart', this.productInfo.id];
    } */
  },
  created() {
    let filename = this.productInfo.images[0].src;
    let extension = filename.split('.').pop();
    let newFilename = filename.split('.')[0];
    this.preViewImg = newFilename+'500x410.'+extension;
  },
  mounted() {

    /*  console.log(this.productInfo.id) */
    let cartLength = -1

    let watcherUser = setInterval(() => {
      let user = JSON.parse(JSON.stringify(this.$store.getters['user/getUser']));
      /* console.log(user); */
      if (user?.id) {
        cartLength = user.cart.split(',').length;
        clearInterval(watcherUser);
      }
    }, 100);

    let watcher = setInterval(() => {
      /*  console.log(cartLength); */
      if (this.$store.getters['cart/getCart'].length == cartLength) {
        if (this.$store.getters['cart/getCart'].
          findIndex(el => Number(el.id) == Number(this.productId)) > -1) {
          this.isInCart = true;
        }
        clearInterval(watcher);
      }
    }, 100);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.minimized {
  justify-content: space-between;
  height: 100%;
  border-radius: 10px;
}

.minimized p {
  font-size: 0.9rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.minimized img,
.minimized video {
  padding: 0;
  margin-top: 10px;
  height: 150px;
  border-radius: 10px;
}

.product-card {
  background-color: rgba(255, 255, 255, 0.849);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  border-radius: 20px;
  transition: all 0.5s ease-in-out;
  min-height: 100%;
  max-height: 100%;
  height: 100%;
  box-shadow: 3px 3px 10px rgba(16, 1, 69, 0.153);

}

a {
  text-decoration: none;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 20px;
  width: 100%;
  align-self: stretch;
  justify-self: stretch;
  height: calc(100% - 40px);

}

.card-image {
  width: calc(100% - 20px);
  padding: 10px;
  margin-top: 10px;
  height: 75%;
  object-fit: cover;
  border-radius: 20px;
}

.product-card:hover {
  box-shadow: 5px 5px 15px gray;
}

.product-card p {
  width: 80%;
  word-wrap: break-word;
  text-align: center;
}

.title {
  margin: 0;
}

.price {
  font-weight: bold;
  margin-top: 5px;
  margin-bottom: 5px;
}

.old-price {
  text-decoration: line-through;
  margin-bottom: 5px;
  color: gray;
  font-size: 0.8rem;

}

.discount-price {
  margin: 0px;
  font-weight: bold;
  color: red;
  margin-left: 10%;
}

.price-block {
  width: 100%;
  display: flex;
  justify-content: center;
}

.price-block p {
  width: fit-content;
  padding-left: 5px;
  padding-right: 5px;
}

.cart-block {
  margin-top: 7px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.cart-block-btn {
  display: flex;
  align-items: center;
  background-color: rgba(234, 196, 83, 0);
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 5px;
  margin-bottom: 10px;
  cursor: pointer;


}

.cart-block img {
  width: 30px;
  height: 30px;
}

.remove-btn {
  max-width: 20px;
  max-height: 20px;

}

.cart-block p {
  width: 100px;
}

.cart-operations-btn {
  height: 20px;
  width: 20px;
  position: relative;

}

.cart-operations-btn div {
  transition: 0.3s all ease-in-out;
}

.first-line {
  position: absolute;
  top: calc(50% - 2px);
  background-color: black;
  height: 4px;
  width: 100%;
  border-radius: 5px;
}

.first-line-done {
  transform: rotateZ(45deg) scaleX(0.5) translateX(-10px) translateY(8px);
  background-color: rgb(5, 210, 5);
}

.second-line-done {
  transform: rotateZ(45deg);
  background-color: rgb(5, 210, 5) !important;
}

.second-line {
  position: absolute;
  left: calc(50% - 2px);
  background-color: black;
  height: 100%;
  width: 4px;
  border-radius: 5px;
}



@media(max-width:580px) {

  .title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  
  p{
    font-size: 1.2rem;
  }
  .product-card{
    border-radius: 10px;
  }

  .card-image{
    border-radius: 10px;
    padding: 0;
    padding: 5px;
    margin-top: 10px;
  }

  .cart-operations-btn{
    height: 15px;
    width: 15px;
  }

  .first-line {
    top: calc(50% - 1.5px);
    height: 3px;
    border-radius: 2px;
  }

  .second-line {
    left: calc(50% - 1.5px);
    width: 2.8px;
    border-radius: 2px;
  }
  
  .first-line-done {
    transform: rotateZ(45deg) scaleX(0.5) translateX(-7px) translateY(6px);
    background-color: rgb(5, 210, 5);
  }
  
  .second-line-done {
    transform: rotateZ(45deg);
    background-color: rgb(5, 210, 5) !important;
  }

  .cart-block-btn img{
    height: 25px;
    width: 25px;
  }
}


@media(max-width:480px) {

  
  .product-card{
    border-radius: 15px;
  }

  .card-image{
    border-radius: 20px;
    padding: 0;
    padding: 10px;
    margin-top: 10px;
    height: 80%;
  }

}

@media(max-width:360px) {

  .minimized img{
    width: 100%;
    height: 120px;
    margin-top: 0;
  }


}

</style>
