<template>
    <div class="discounts">


        <div class="container">
            <div class="btns-container">
                <router-link to="/admin">
                    <button class="btn-standart btn-big">На главную</button>
                </router-link>
            </div>
            <div class="discount-card-description">
                <!-- <img class="arrow-left" src="../assets/images/icons/arrow-left.png" alt=""> -->

                <template v-if="!isRedacted">
                    <p class="discount-card-text">{{ discounts.title }}</p>
                    <div>
                        <button class="btn-standart btn-big" @click="redactTitle">Изменить</button>
                    </div>
                </template>


                <template v-else>
                    <textarea v-model="title"></textarea>
                    <div>
                        <button class="btn-save" @click="updateTitle">Сохранить</button>

                        <button class="btn-cancel" @click="isRedacted = false">Отменить</button>
                    </div>
                </template>

                <!--       <img class="arrow-right" src="../assets/images/icons/arrow-right.png" alt=""> -->
            </div>

            <div class="discount-list">
                <ul>
                    <li v-for="item in discountProducts" :key="item">
                        <ProductCard :productInfo="item" class="card-scroll-animated" />
                        <div class="btns-container">

                            <button v-if="deleteConfirm !=item.id" class="btn-cancel" @click="deleteConfirm = item.id">Удалить</button>
                            <button v-else class="btn-cancel" @click="deleteItem(item.id)">Точно удалить</button>

                            <button class="btn-standart" @click="() => {
                                modalState = true;
                                modalMode = 'edit';
                                editedItemID = item.id;
                            }">Изменить</button>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="btns-container">
                <button class="btn-standart btn-big" @click="() => {
                    modalState = true;
                    modalMode = 'add';
                }">Добавить</button>
            </div>
        </div>

        <transition name="modal-fade">
            <productsListModal v-if="modalState" @onCloseModal="closeModal" @onChangeItem="changeItem" @onAddItem="addItem"
                :mode="modalMode"></productsListModal>
        </transition>

    </div>
</template>
  
<script>
import UserDataService from "@/service/UserDataService";
import ProductCard from '@/components/products/ProductCard.vue'
import productsListModal from '@/components/modals/productsListModal.vue'
import { mapState } from 'vuex'

export default {
    name: 'discountCard',
    components: {
        ProductCard,
        productsListModal
    },
    data() {
        return {
            discountProducts: [],
            title: "",
            isRedacted: false,
            modalState: false,
            modalMode: '',
            editedItemID: -1,
            deleteConfirm: -1
        }
    },
    computed: {
        // смешиваем результат mapState с внешним объектом
        ...mapState({
            discounts: state => state.discounts.all[0],
            user: state => state.user.all
            // ...
        })
    },
    watch: {
        discounts() {

        },
        user(newValue) {
            if (newValue?.id) {
                if (newValue.role != 'admin') this.$router.push('/');
            }
            else this.$router.push('/');
        }
    },
    methods: {
        redactTitle() {
            this.title = this.discounts.title;
            this.isRedacted = true;
        },
        updateTitle() {
            this.$store.dispatch('discounts/updateTitle', {
                id: this.discounts.id,
                title: this.title
            });
            this.isRedacted = false;
        },
        async deleteItem(id) {
            this.deleteConfirm = -1;
            let deletedIndex = this.discounts.products.findIndex(el => el.id == id);
            let newItems = [];
            let newItemsObject = [];
            this.discounts.products.forEach(element => {
                newItems.push(element.id);
                newItemsObject.push(element);
            });

            newItems.splice(deletedIndex, 1);
            newItemsObject.splice(deletedIndex, 1);

            await this.$store.dispatch('discounts/updateProducts', {
                id: 1,
                products: newItems.join(','),
                productsObjects: newItemsObject
            });
            this.discountProducts.splice(deletedIndex, 1);
        },
        async changeItem(item) {
            let editedIndex = this.discounts.products.findIndex(el => el.id == this.editedItemID);
            let newItems = [];
            let newItemsObject = [];
            this.discounts.products.forEach(element => {
                newItems.push(element.id);
                newItemsObject.push(element);
            });

            newItems[editedIndex] = item.id;
            newItemsObject[editedIndex] = item;

            await this.$store.dispatch('discounts/updateProducts', {
                id: 1,
                products: newItems.join(','),
                productsObjects: newItemsObject
            });
            this.discountProducts[editedIndex] = item;

        },
        async addItem(items) {
            let newItems = [];
            let newItemsObject = [];
            this.discounts.products.forEach(element => {
                newItems.push(element.id);
                newItemsObject.push(element);
            });

            items.forEach(element => {
                newItems.push(element.id);
                newItemsObject.push(element);
            });

            await this.$store.dispatch('discounts/updateProducts', {
                id: 1,
                products: newItems.join(','),
                productsObjects: newItemsObject
            });
            this.discountProducts = this.discountProducts.concat(items);
        },
        closeModal() {
            this.modalState = false;
        }

    },
    created() {
        UserDataService.checkSession().then((res) => {
            if (res.data == 'session unset') {
                this.$router.push('/');
            }
        });
        if (this.user?.id) {
            if (this.user.role != 'admin') this.$router.push('/');
        }

        if (this.$store.state.discounts?.all.length == 0) {
            this.$store.dispatch('discounts/getAll');
        }
        else {
            this.discounts = this.$store.state.discounts.all[0];
        }

        let watcher = setInterval(() => {
            if (this.discounts.products.length == this.discounts.productsLength) {
                this.discountProducts = JSON.parse(JSON.stringify(this.discounts.products));
                clearInterval(watcher);
            }
        }, 100);


    },
    mounted() {

    }
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
textarea {
    width: 100%;
    padding: 10px;
    height: 300px;
    border-radius: 10px;
    border: 1px solid gray;
}

.product-card {
    max-height: calc(100% - 80px) !important;
    min-height: calc(100% - 80px) !important;
    height: calc(100% - 80px) !important;
}

.discounts {
    background: linear-gradient(360deg, #b9deed, #efefef);
    min-height: 100vh;
    padding-top: 100px;
    padding-bottom: 50px;
    display: flex;
    align-items: center;
}



.container {
    width: 100%;

}

.discount-card-description {
    background-color: rgba(255, 255, 255, 0.629);
    padding: 20px;
    padding-left: 40px;
    padding-right: 40px;
    margin-right: 20px;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.arrow-left {
    padding-right: 20px;
}

.arrow-right {
    padding-left: 20px;
}

.discount-card-text {
    white-space: pre-line;
    text-align: justify;

}

.discount-list {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: auto;

}

.discount-list ul {

    padding-left: 0;
    width: max-content;
    margin-right: 20px;
    margin-left: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fill, 300px);
    grid-auto-flow: column;
    grid-auto-columns: 300px;
    grid-auto-rows: 580px;
    padding-top: 20px;
    padding-bottom: 20px;

    overflow: scroll;
    gap: 50px 20px;

}

.discount-list li {
    border: 1px solid white;
    border-radius: 22px;
    padding: 5px;
    background-color: #e3e3e358;
}

.discount-list:last-child {
    padding-right: 20px;
}
</style>
  