class serverConfig {
    constructor() {
        /* this.serverRoot = 'http://localhost/new_git/' */
        this.serverRoot = 'https://marysiahandmade.ru/new/'
    }

    getRoot() {
        return this.serverRoot
    }
}
export default new serverConfig()