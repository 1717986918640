<template>
  <div id="app">
    <header>
      <NavBar :pages="pages" />
    </header>
    <main>
      <router-view />
    </main>
    <footer>
      <FooterPanel />
    </footer>
  </div>
</template>
<script>
import NavBar from '@/components/NavBar.vue';
import FooterPanel from '@/components/FooterPanel.vue';
import ProductDataService from "@/service/ProductDataService";
import GalleryDataService from "@/service/GalleryDataService";
import FeedbackDataService from "@/service/FeedbackDataService";
import UserDataService from "@/service/UserDataService";

export default {
  name: 'App',
  components: {
    NavBar,
    FooterPanel
  },
  data() {
    return {
      pages: [{
        name: 'Главная',
        path: '/',
        mark: 'home'
      }, {
        name: 'Товары',
        path: '/products',
        mark: 'products'
      },
      {
        name: 'Корзина',
        path: '/cart',
        mark: 'cart'
      },
      {
        name: 'Галерея',
        path: '/gallery',
        mark: 'gallery'
      }]
    }
  },
  methods: {
    getProducts() {
      ProductDataService.getProductItems().then((res) => {
        let itemsArray = [];
        res.data.forEach(item => {
          let storeItem = new Object();
          let itemImages = [];

          item.images.split(" $;$ ").forEach(image => {

            let imageItem = new Object();
            imageItem.type = image.slice(1, 4);
            imageItem.src = image.slice(5, image.length);

            if (imageItem.src != "") {
              itemImages.push(imageItem)
            }

          });

          item.name = item.name.replace(/&quot;/g, '"');
          storeItem.id = item.id;
          storeItem.name = item.name;
          storeItem.position = item.position;
          storeItem.description = item.description;
          storeItem.size = item.size;
          storeItem.composition = item.composition;
          storeItem.price = item.price;
          storeItem.discountPrice = item.discountPrice;
          storeItem.category = item.category;
          storeItem.images = itemImages;

          itemsArray.push(storeItem);
        });
        this.$store.state.allProducts = itemsArray;
        /*       console.log(this.$store.state.allProducts);
              console.log(this.$store.state.galleryItems); */
      });
    },
    getFeedback() {
      FeedbackDataService.getFeedbackItems().then(() => {
      });
    },
    getGallery() {
      GalleryDataService.getGalleryItems().then((res) => {
        let itemsArray = [];
        res.data.forEach(item => {
          let storeItem = new Object();
          storeItem.id = item.id;
          storeItem.position = item.position;
          storeItem.type = item.content.slice(1, 4);
          storeItem.src = item.content.slice(5, item.content.length);
          itemsArray.push(storeItem);
        });
      });
    }
  },
  created() {
    UserDataService.checkSession().then((res) => {
      if(res.data!= 'session unset'){
        this.$store.dispatch('user/getInfo', res.data);
        this.$store.dispatch('cart/getUserCartInfo', res.data);
      }
    })
    /*     this.getProducts();
        this.getFeedback();
        this.getGallery(); */
  },
};
</script>
<style>
@font-face {
  font-family: 'Montserrat';
  src: url('assets/fonts/Montserrat/static/Montserrat-Regular.ttf');
}

.modal-fade-leave-to,
.modal-fade-enter-from {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: all 0.5s ease-in-out;
}

.btn-standart {
  width: 120px;
  margin-right: 15px;
}

.btn-standart {
  box-shadow: 3px 3px 10px rgba(1, 3, 35, 0.296);
  padding: 7px;
  margin: 5px;
  border-radius: 10px;
  border: 1px solid rgb(226, 226, 226);
  background-color: rgb(255, 255, 255);
  color: #0c022c;
  width: 120px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}

.btn-standart:hover {
  background-color: #0c022c;
  border: 1px solid rgb(255, 255, 255);
  color: white;
}

.btn-save {
  width: 120px;
  margin-right: 15px;
}

.btn-save {
  box-shadow: 3px 3px 10px rgba(2, 121, 18, 0.296);
  padding: 7px;
  margin: 5px;
  border-radius: 10px;
  border: 1px solid rgb(255, 255, 255);
  background-color: rgb(1, 202, 28);
  color: #ffffff;
  width: 120px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}

.btn-save:hover {
  background-color: #0c022c;
  border: 1px solid rgb(255, 255, 255);
  color: white;
}

.btn-cancel {
  width: 120px;
  margin-right: 15px;
}

.btn-cancel {
  box-shadow: 3px 3px 10px rgba(51, 0, 0, 0.551);
  padding: 7px;
  margin: 5px;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.516);
  background-color: rgb(211, 0, 0);
  color: #ffffff;
  width: 120px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}

.btn-cancel:hover {
  background-color: #0c022c;
  border: 1px solid rgba(255, 255, 255, 0.53);
  color: white;
}

.btn-big {
  width: 200px;
  height: 50px;
}

.btns-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

body {
  margin: 0;
  overflow-x: hidden;
  width: 100vw;
  max-width: 100vw;
}

main {}

* {
  font-family: 'Montserrat';
  box-sizing: border-box;
}

li {
  list-style: none;
}

p {
  font-size: 1rem;
}

#app {
  width: 100vw;
  max-width: 100vw;
}



::-webkit-scrollbar {
  display: none;
}

@media(max-width:1000px) {
  html {
    font-size: 15px;
  }
}

@media(max-width:700px) {
  html {
    font-size: 14px;
  }
}

@media(max-width:500px) {
  html {
    font-size: 13px;
  }

  .btn-standart{
    width: 150px;
  }
  .btn-cancel{
    width: 150px;
  }
}

@media(max-width:400px) {
  html {
    font-size: 12px;
  }

  
  .btn-standart{
    width: 120px;
  }
  .btn-cancel{
    width: 120px;
  }
}
</style>
