
<template>
    <div class="feedback">
        <div class="btns-container">
            <router-link to="/admin">
                <button class="btn-standart btn-big">На главную</button>
            </router-link>
        </div>
        <div class="feedback-container">

            <ul ref="productsList">
                <transition-group name="feedback-list" mode="out-in">
                    <li v-for="item in feedback" :key="item.id">
                        <feedbackItem :itemInfo="item"></feedbackItem>
                    </li>
                </transition-group>
            </ul>
        </div>

    </div>
</template>
  
<script>
// @ is an alias to /src
import UserDataService from "@/service/UserDataService";
import feedbackItem from '@/components/admin/feedbackItem.vue'
import { mapState } from 'vuex'

export default {
    name: 'ProductsView',
    components: {
        feedbackItem
    },
    data() {
        return {

        }
    },
    computed: {
        // смешиваем результат mapState с внешним объектом
        ...mapState({
            feedback: state => state.feedback.all,
            user: state => state.user.all
            // ...
        })
    },
    watch: {
        feedback(newValue) {
            console.log(newValue);
        },
        user(newValue) {
            if (newValue?.id) {
                if (newValue.role != 'admin') this.$router.push('/');
            }
            else this.$router.push('/');
        }
    },
    methods: {

    },
    created() {
        UserDataService.checkSession().then((res) => {
            if (res.data == 'session unset') {
                this.$router.push('/');
            }
        });
        if (this.user?.id) {
            if (this.user.role != 'admin') this.$router.push('/');
        }
        
        this.$store.dispatch('feedback/getAll');
    },
    mounted() {

    }
}

</script>
  
<style scoped>
.feedback {
    padding: 100px 30px 80px 30px;
    /* background-image: url("../assets/images/mainPage/11.jpg");*/
    background-color: rgba(10, 122, 130, 0.133);
    background-size: cover;
    position: relative;
}

.feedback-container ul {
    padding-left: 0;
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(auto-fill, 500px);
    justify-items: stretch;
    gap: 20px 20px;


}
</style>
  