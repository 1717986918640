<template>
  <div class="cart">
    <transition name="cart-fade">
      <div class="cart-container" v-if="cartStore != 'Корзина пуста'">
        <div class="cart-items-container" ref="cartItemsContainer">
          <transition-group name="cart-list">
            <CartItem @onDeleted="removeitem" @onMount="(n) => setHeight(n)" class="cart-item" v-for="item in cartStore"
              :key="item.id" :item="item"></CartItem>
          </transition-group>
        </div>
        <div class="cart-buy">
          <div class="cart-buy-text">
            <div>
              <p>Итого </p>
              <div class="dots"></div>
              <p>{{ resultSumm }}₽</p>
            </div>

            <div>
              <p>Сумма за {{ cartLength }} товара </p>
              <div class="dots"></div>
              <p>{{ itemsSumm }}₽</p>
            </div>

            <div>
              <p>Скидка </p>
              <div class="dots"></div>
              <p>{{ discountSumm }}₽</p>
            </div>
            <p class="delivery-info">*Доставка осуществляется из Волгограда за счет покупателя*</p>
          </div>
          <div class="cart-buy-form">
            <p>Контактная информация</p>
            <form @submit="createOrder">
<!--               <label for="">
                <span>Имя</span>
                <input type="text" required v-model="name">
              </label> -->

<!--               <label for="">
                <span>Фамилия</span>
                <input type="text" required v-model="surname">
              </label>

              <label for="">
                <span>Отчество</span>
                <input type="text" required v-model="patronymic">
              </label>

              <label for="">
                <span>Номер телефона</span>
                <input type="tel" required v-model="phone_number">
              </label> -->

              <label for="">
                <span>Контакт для связи</span>
                <input type="text" required v-model="email" placeholder="контакт или ссылка на него">
              </label>

              <label for="">
                <span>Предпочтительный способ связи</span>
                <select v-model="messenger" required>
                  <option disabled value="">Выберите один из вариантов</option>
                  <option>Email</option>
                  <option>Telegram</option>
                  <option>ВК</option>
                  <option>WhatsApp</option>
                </select>
              </label>
<!--               <label for="">
                <span>Адрес доставки</span>
                <textarea required v-model="adress"></textarea>
              </label> -->

              <button class="cart-buy-form-btn">Оформить заказ</button>
            </form>
          </div>
        </div>
      </div>

      <div v-else class="empty-cart">
        <h2>Ваша корзина пуста</h2>
        <p>Скорее в каталог, там много потрясающих украшений, которые вам обязательно понравятся!</p>
        <router-link to="/products" class="empty-cart-btn">Перейти в каталог</router-link>
      </div>

    </transition>

    <transition name="modal-fade">
      <LoadingModal v-if="loadingState">
      </LoadingModal>
    </transition>
    <transition name="modal-fade">
      <ThankModal v-if="thankState" @onCloseModal="thankState = false" :message="`Спасибо за заказ нашей продукции! 
      Администратор свяжется с вами для потдверждения заказа и уточнения деталей`">
      </ThankModal>
    </transition>

  </div>
</template>

<script>
// @ is an alias to /src
import CartItem from '@/components/CartItem.vue'
import LoadingModal from '@/components/modals/loadingModal.vue'
import ThankModal from '@/components/modals/ThankModal.vue'
import { mapState } from 'vuex'

export default {
  name: 'ProductsView',
  components: {
    CartItem,
    LoadingModal,
    ThankModal
  },
  data() {
    return {
      cart: [],
/*       name: "",
      surname: "",
      patronymic: "", */
      email: "",
      messenger: "",
/*       adress: "", */
      itemsContainerHeight: 100,
      loadingState: false,
      thankState: false
    }
  },
  methods: {
    removeitem(n, itemID) {
      this.itemsContainerHeight -= n - 1;
      this.$refs.cartItemsContainer.style.height = this.itemsContainerHeight + 'px';
      let removedIndex = this.cart.findIndex((el) => el.id == itemID);
      this.cart.splice(removedIndex, 1);
      if (this.cart.length == 0) this.cart != "Корзина пуста"
    },
    setHeight(n) {
      this.itemsContainerHeight += n + 1;
      this.$refs.cartItemsContainer.style.height = this.itemsContainerHeight + 'px';
    },
    createOrder() {
      this.loadingState = true;
      let now = new Date;
      let day = now.getDate();
      let month = now.getUTCMonth() + 1;
      let year = now.getFullYear();
      let hour = now.getHours();
      let min = now.getMinutes();
      let sec = now.getSeconds();


      let user = JSON.parse(JSON.stringify(this.$store.getters['user/getUser']));
      this.$store.dispatch('order/createOrder', {
        user_id: user.id,
        products: user.cart,
        date: year + '-' + month + '-' + day + ' ' + hour + ':' + min + ':' + sec,
/*         name: this.name,
        surname: this.surname,
        patronymic: this.patronymic, */
        email: this.email,
        messenger: this.messenger,
/*         adress: this.adress */
      }).then(() => {
        this.loadingState = false;
        this.thankState = true;
        this.$store.dispatch('cart/clearCart', user.id);
        this.$store.dispatch('user/clearCart', user.id);
      })

    }

  },
  computed: {
    ...mapState({
      cartStore: state => state.cart.all,
      user: state => state.user.all
      // ...
    }),
    resultSumm() {
      let summ = 0;
      if (this.cart != "Корзина пуста") {
        this.cart.forEach(item => {
          let price = 0;
          if (item.discountPrice) {
            price = item.discountPrice;
          }
          else price = item.price;
          summ += price * 1;
        });
      }
      return summ;
    },
    itemsSumm() {
      let summ = 0;
      if (this.cart != "Корзина пуста") {
        this.cart.forEach(item => {
          let price = item.price;
          summ += price * 1;
        });
      }
      return summ;
    },
    discountSumm() {
      let summ = 0;
      if (this.cart != "Корзина пуста") {
        this.cart.forEach(item => {
          let price = 0;
          if (item.discountPrice) {
            price = item.price
              - item.discountPrice;
          }
          summ += price * 1;
        });
      }
      return summ;
    },
    cartLength() {
      return this.cart.length;
    }
  },
  watch: {
    cartStore(newValue) {
      this.cart = JSON.parse(JSON.stringify(newValue));
      let watcher = setInterval(() => {
        if (JSON.parse(JSON.stringify(newValue)).length != 0) {
          this.cart = JSON.parse(JSON.stringify(newValue));
          clearInterval(watcher);
        }
      }, 100);
    },
    user(newValue) {
      this.email = newValue.email;
      this.name = newValue.name;
      this.phone_number = newValue.phoneNumber;
    }
  },
  mounted() {
    /* this.$store.state.activaPage = 'cart'; */
/*     let user = JSON.parse(JSON.stringify(this.$store.getters['user/getUser'])); */
  /*   this.email = user.email; */
/*     this.name = user.name; */
/*     this.phone_number = user.phoneNumber; */
    this.$store.commit('setActivePage', 'cart');
    if (this.cartStore != "Корзина пуста") this.cart = JSON.parse(JSON.stringify(this.cartStore));
    else this.cart = "Корзина пуста"
    window.scroll({
      left: 0, // до какого количества пикселей прокрутить вправо
      top: 0, // до какого количество пикселей прокрутить вниз
      behavior: 'smooth' // определяет плавность прокрутки: 'auto' - мгновенно (по умолчанию), 'smooth' - плавно
    });
    // Select the node that will be observed for mutations

  }
}
</script>

<style>
.cart {
  padding-top: 100px;
  min-height: calc(100vh);
  height: max-content;
  padding-bottom: 50px;
  background: linear-gradient(to bottom, white, rgb(255, 239, 228), white);
}

.cart-container {
  display: flex;
  justify-content: space-between;
  transition: all 0.5s ease-in-out;
}

.cart-items-container {
  margin-left: 40px;
  width: calc(70% - 80px);
  min-height: calc(100vh - 120px);
  max-height: calc(100vh - 120px);
  height: 100vh;
  overflow: scroll;
  border-radius: 10px;
  position: relative;
  border: 1px solid rgba(192, 161, 118, 0.373);
  transition: all 0.7s ease-in-out;
}

.cart-item {
  border-bottom: 1px solid rgba(192, 161, 118, 0.373);
  transition: all 0.7s ease-in-out;
}

.cart-item:last-child {
  transition: all 0.7s ease-in-out;
  border-bottom: none;
}

.cart-item:nth-child(even) {
  background-color: rgba(255, 255, 255, 0.877);
}

.cart-item:nth-child(odd) {
  background-color: rgba(255, 255, 255, 0.307);
}

.cart-buy {
  width: 30%;

  background-color: rgba(255, 255, 255, 0.877);
  border-radius: 10px;
  border: 1px solid rgba(128, 128, 128, 0.486);
  padding: 20px;
  padding-bottom: 0;
  margin: 20px;
  margin-top: 0;
  margin-bottom: 0;
}

.cart-buy-text {}

.cart-buy-text div {
  display: flex;
  align-items: flex-end;


}

.cart-buy-text p {
  font-size: 1.2rem;

  padding-top: 10px;
  padding-bottom: 0;
  margin: 0;
  white-space: nowrap;
}

.dots {
  border-bottom: 1px dotted gray;
  height: 1em;
  width: 100%;
  margin-bottom: 5px;
  margin-left: 5px;
  margin-right: 5px;
}

.delivery-info {
  font-size: 0.8rem !important;
  width: 100%;
  white-space: break-spaces !important;
}

.cart-buy-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cart-buy-form form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90%;
}

.cart-buy-form label {
  display: flex;
  flex-direction: column;
  padding-top: 7px;
}

.cart-buy-form input,
.cart-buy-form textarea,
.cart-buy-form select {
  width: 100%;
  padding: 5px;
  margin-top: 5px;
  border-radius: 5px;
  border: 1px solid rgba(128, 128, 128, 0.412);
}

.cart-buy-form-btn {
  color: #B87843;
  font-weight: bold;
  font-size: 1rem;
  text-shadow: 5px 5px 10px rgb(244, 235, 173);
  margin-top: 20px;
  padding: 10px;
  margin-bottom: 30px;
  border-radius: 10px;
  border: 1px solid rgba(250, 216, 216, 0.412);
  align-self: center;
  width: 200px;
  background: linear-gradient(90deg, rgb(255, 255, 214), #ffddce, rgb(255, 245, 216));
  background-size: 200% 100%;
  box-shadow: 5px 5px 15px rgb(254, 237, 216);
  transition: 0.5s all ease-in-out;
  cursor: pointer;
}

.cart-buy-form-btn:hover {
  background-position: 100%;
  box-shadow: 5px 5px 15px rgb(255, 216, 216);
}

.empty-cart {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 50vh;
  justify-content: center;
  align-items: center;
}

.empty-cart p {
  width: 50%;
  text-align: center;
}

.empty-cart-btn {
  display: block;
  text-align: center;
  text-decoration: none;
  margin-top: 20px;
  padding: 10px;
  width: 200px;
  border-radius: 7px;
  border: 1px solid rgba(195, 194, 194, 0.212);
  color: rgb(255, 224, 254);
  font-weight: 700;
  letter-spacing: 1px;

  background-color: rgba(3, 2, 57, 0.919);
  box-shadow: 0px 5px 15px rgba(142, 142, 142, 0.37);
  transition: all 0.5s ease-in-out;

}

.empty-cart-btn:hover {
  animation: shake 2s ease-in-out;
  box-shadow: 0px 5px 20px rgb(255, 236, 220);
}

@keyframes shake {
  0% {}

  25%,
  75% {
    transform: translateY(3px);
  }

  50% {

    transform: translateY(-3px);

  }

  100% {}
}

.cart-list-leave-to {
  opacity: 0;
  transform: translateX(-100%) scale(0);

}

.cart-fade-leave-to,
.cart-fade-enter-from {
  opacity: 0;
  transform: translateY(30px);
}

.cart-list-leave-active {
  position: absolute;
}


@media(max-width:1000px) {
  .cart-container {
    flex-direction: column;
    width: 100%;
    margin-right: 0px;
    align-items: center;
  }

  .cart-items-container {
    width: 80%;
    margin-left: 0;
    min-height: max-content;
    height: max-content;
    margin-bottom: 30px;
  }

  .cart-buy {
    width: 80%;
    margin: 0;
    height: auto;
    padding-bottom: 40px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .cart-buy-text {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .cart-buy-form {
    width: 100%;
  }
}

@media (max-width:400px) {
  .cart-buy {
    width: 95%;
  }

  .cart-items-container {
    width: 95%;
  }

}
</style>
