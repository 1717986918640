<template>
  <div class="container">
    <div class="order-description">
      <p>Заказ <span style="font-weight: bold;">№{{ order.id }} от {{ order.date }}</span></p>
      <p>Сумма: <span style="font-weight: bold;">{{ orderPrice }}</span></p>
<!--       <p>ФИО: <span style="font-weight: bold;">{{ order.surname }} {{ order.name }} {{ order.patronymic }}</span></p> -->
      <p>Контактная информация: <span style="font-weight: bold;">{{ order.email }}</span></p>
      <p>Предпочтительный способ связи: <span style="font-weight: bold;">{{ order.messenger }}</span></p>
      <p v-if="order.track_number !=''">Трек-номер: <span style="font-weight: bold;">{{ order.track_number }}</span></p>
      <p>Статус заказа: <span style="font-weight: bold;">{{ orderStatus }}</span></p>
    </div>
    <div class="products-list">
      <ul>
        <li v-for="item in orderProducts" :key="item">
          <ProductCard :productInfo="item" :viewMode="'order'" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import ProductCard from '@/components/products/ProductCard.vue'
import ProductDataService from "@/service/ProductDataService";

export default {
  name: 'orderCard',
  data() {
    return {
      orderProducts: [],
      orderStatus: "",
      isHidden: false,
      orderPrice: 0
    }
  },
  components: {
    ProductCard
  },
  props: ['order'],
  created() {

    switch (this.order.status) {
      case 0:
        this.orderStatus = 'ожидает подтверждения';
        break;
      case 1:
        this.orderStatus = 'подтвержден, ожидает оплаты';
        break;
      case 2:
        this.orderStatus = 'оплачен, ожидает отправки';
        break;
      case 3:
        this.orderStatus = 'отправлен';
        break;
      case 4:
        this.orderStatus = 'ожидает на пункте выдачи';
        break;
      case 5:
        this.orderStatus = 'получен';
        break;
      case 6:
        this.orderStatus = 'отменен';
        break;
    }

    let productsList = this.order.products.split(',');
    productsList.forEach(product => {
      ProductDataService.getItem(product).then((res) => {

        if (res.data?.id) {
          let product = new Object();
          let itemImages = [];

          res.data.images.split(" $;$ ").forEach(image => {

            let imageItem = new Object();
            imageItem.type = image.slice(1, 4);
            imageItem.src = image.slice(5, image.length);

            if (imageItem.src != "") {
              itemImages.push(imageItem)
            }

          });
          product = res.data;
          product.images = itemImages;
          product.name = res.data.name.replace(/&quot;/g, '"');
          this.orderProducts.push(product);
          if (product.discountPrice == 0) this.orderPrice += Number(product.price);
          else this.orderPrice += Number(product.discountPrice);
        }
      });
    })

  },
  mounted() {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container {

  width: 100%;
  padding-left: 20px;
  padding-top: 20px;
}

.order-description {
  padding-left: 10px;
  padding-right: 10px;
  margin: 0;
}

.order-description p {
  margin: 0;
  padding-bottom: 10px;
}

.products-list {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding-right: 20px;
}

.products-list ul {
  padding-bottom: 20px;
  padding-top: 10px;
  padding-left: 0;
  max-width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, 150px);
  grid-auto-flow: column;
  grid-auto-columns: 150px;
  grid-auto-rows: 250px;
  overflow: scroll;
  gap: 20px 10px;
  margin: 0px;
}

.product-card {
  border-radius: 10px;
}


@media(max-width:360px) {

  .products-list ul{
    grid-template-columns: repeat(auto-fill, 100px);
    grid-auto-columns: 100px;
    grid-auto-rows: 180px;
    gap: 15px 15px;
  }
  
}

</style>
