
<template>
    <div class="gallery">

        <div class="gallery-add-btn">
            <router-link to="/admin">
                <button>На главную</button>
            </router-link>
        </div>

        <div class="gallery-container">

            <div class="gallery-add-btn">
                <button @click="openUpdateModal">Добавить изображение</button>
            </div>

            <ul>
                <transition-group name="gallery-list" mode="out-in">
                    <li v-for="item in showedItems" :key="item.id">
                        <galleryItem :item="item"></galleryItem>
                    </li>
                </transition-group>
            </ul>
        </div>

        <div ref="showMoreItems" class="gallery-show-more">
            <button @click="showMoreItems">Показать еще</button>
        </div>

        <transition name="modal-fade">
            <addGalleryItemModal v-if="modalUpdateState" @onCloseUpdateModal="closeUpdateModal" @onAcceptUpdate="updateItem"
                :item="{ id: -1 }" :message="`Вы уверены что хотите добавить это фото?`" :contentLocation="'gallery'">
            </addGalleryItemModal>
        </transition>

    </div>
</template>
  
<script>
// @ is an alias to /src
import UserDataService from "@/service/UserDataService";
import galleryItem from '@/components/admin/galleryItem.vue'
import addGalleryItemModal from '@/components/modals/addGalleryItemModal.vue'

import { mapState } from 'vuex'

export default {
    name: 'ProductsView',
    components: {
        galleryItem,
        addGalleryItemModal
    },
    data() {
        return {
            showedItems: [],
            showedItemsCount: 10,
            showedItemsIncrement: 10,
            modalUpdateState: false,
        }
    },
    computed: {
        // смешиваем результат mapState с внешним объектом
        ...mapState({
            items: state => state.gallery.all,
            user: state => state.user.all
            // ...
        })
    },
    watch: {
        items(newValue) {
            this.showedItems = newValue.slice(0, this.showedItemsCount);
            console.log("hello")
        },
        user(newValue) {
            if (newValue?.id) {
                if (newValue.role != 'admin') this.$router.push('/');
            }
            else this.$router.push('/');
        }
    },
    methods: {
        openUpdateModal() {
            this.modalUpdateState = true;
        },
        closeUpdateModal() {
            this.modalUpdateState = false;
        },
        showMoreItems() {

            if (this.showedItemsCount + this.showedItemsIncrement
                <= this.items.length) {
                this.showedItemsCount += this.showedItemsIncrement;

            }
            else {
                this.showedItemsCount = this.items.length;
                /* this.$store.state.showedProductsCount = this.showedProductsCount; */
                if (this.showedItemsCount >= this.items.length) {
                    setTimeout(() => {
                        this.$refs.showMoreItems.style.display = 'none';
                    }, 500);
                }
            }

            this.showedItems = this.items.slice(0, this.showedItemsCount);

        },
        /*  changePosition(id) {
 
         } */
    },
    created() {
        UserDataService.checkSession().then((res) => {
            if (res.data == 'session unset') {
                this.$router.push('/');
            }
        });
        if (this.user?.id) {
            if (this.user.role != 'admin') this.$router.push('/');
        }
        
        /* this.$store.dispatch('products/getAll'); */
        if (this.$store.state.gallery?.all.length == 0) {
            this.$store.dispatch('gallery/getAll');
        }
        else {
            this.items = this.$store.state.gallery.all;
            this.showedItems = this.$store.state.gallery.all.slice(0, this.showedItemsCount);
        }
    },
    mounted() {

        /* window.scrollTo(0, 0) */


    }
}

</script>
  
<style scoped>
.gallery-animated-active {
    opacity: 1 !important;
    z-index: 1 !important;
    transform: none !important;
}

.gallery-scroll-animated {
    opacity: 0.3;
    z-index: -1 !important;
    transform: scale(0.5);
    transition: all 1s ease-in-out !important;

}

.gallery {
    padding: 100px 30px 80px 30px;
    /* background-image: url("../assets/images/mainPage/11.jpg");*/
    background-color: rgba(189, 229, 255, 0.108);
    background: linear-gradient(to bottom, white, rgba(138, 138, 255, 0.127),white);
    background-size: cover;
    position: relative;
}

.gallery-container ul {
    padding-left: 0;
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(auto-fill, 500px);
    grid-auto-rows: 500px;
    justify-items: stretch;
    gap: 20px 20px;


}



.gallery-show-more {
    width: 100%;
    display: flex;
    justify-content: center;
}

.gallery-show-more button {
    margin-top: 30px;
    padding: 15px;
    width: 200px;
    border-radius: 10px;
    background-color: rgba(236, 235, 235, 0.732);
    border: 1px solid rgba(112, 112, 112, 0.655) !important;
    color: rgb(0, 0, 0);
    cursor: pointer;
    box-shadow: 5px 5px 10px gray;
    transition: all 0.5s ease-in-out;
}

.gallery-show-more button:hover {
    background-color: #0c022c;
    color: white;
}

.gallery-add-btn {
    width: 100%;
    display: flex;
    justify-content: center;
}

.gallery-add-btn button {
    padding: 10px;
    border-radius: 10px;
    border: 2px solid gray;
    background-color: rgba(255, 255, 255, 0.834);
    margin-bottom: 30px;
    color: #0c022c;
    width: 200px;
    cursor: pointer;
    box-shadow: 5px 5px 10px #0c022c3e;
    transition: all 0.5s ease-in-out;
}

.gallery-add-btn button:hover {
    background-color: #0c022c;
    border: 2px solid rgb(255, 255, 255);
    color: white;
}
</style>
  