<template>
    <nav class="header-big nav-block">
        <div class="logo">
            <img src="../assets/images/general/logo3.png" alt="">
        </div>
        <div class="nav-links-big">
            <ul>
                <li v-for="page in pages" :key="page">
                    <router-link :to="page.path" :class="{ active: currentTab === page.mark }">{{ page.name }}</router-link>
                </li>
            </ul>
        </div>
        <div class="account">
            <router-link :to="profilePage" :class="{ active: currentTab == 'user' }">
                <img v-if="userPhoto == 0" src="../assets/images/icons/empty-photo.jpg" alt="">
                <img v-else
                    :src="$store.state.serverRoot + 'images/users/' + user.photo.split('.')[0] + '100x100.' + user.photo.split('.').pop()"
                    alt="">

                <p v-if="user.name == null">Профиль</p>
                <p v-else>{{ user.name }}</p>
            </router-link>
        </div>
    </nav>

    <nav class="header-burger nav-block" :class="!burgerState ? 'burger-hidden' : ''">
        <div class="logo ">
            <img @click="changeBurgerState" :class="!burgerState ? 'logo-close' : ''"
                src="../assets/images/icons/burger-icon.png" alt="">
        </div>
        <div class="nav-links-burger" :class="!burgerState ? 'burger-close' : ''">
            <ul>
                <div class="account-burger" @click="changeBurgerState">
                    <router-link :to="profilePage" :class="{ active: currentTab == 'user' }">
                        <img v-if="userPhoto == 0" src="../assets/images/icons/empty-photo.jpg" alt="">
                        <img v-else
                            :src="$store.state.serverRoot + 'images/users/' + user.photo.split('.')[0] + '100x100.' + user.photo.split('.').pop()"
                            alt="">
                        <p v-if="user.name == null">Профиль</p>
                        <p v-else>{{ user.name }}</p>
                    </router-link>
                </div>

                <li v-for="page in pages" :key="page" @click="changeBurgerState">
                    <router-link :to="page.path" :class="{ active: currentTab === page.mark }">{{ page.name }}</router-link>
                </li>

            </ul>
        </div>

    </nav>
</template>
  
<script>
import { mapState } from 'vuex'

export default {
    name: 'NavBar',
    props: ['pages'],
    data() {
        return {
            burgerState: false,
            profilePage: '/sign-in',
            userPhoto: 0
        }
    },
    methods: {
        changeBurgerState() {
            this.burgerState = !this.burgerState;
        }
    },
    computed: {
        currentTab() {
            return this.$store.state.activaPage;
        },
        // смешиваем результат mapState с внешним объектом
        ...mapState({
            user: state => state.user.all,
            currentTab: state => state.activePage
            // ...
        })
    },
    watch: {
        user(newValue) {
            if (newValue?.id) {
                this.profilePage = '/user'
                this.userPhoto = newValue.photo;
            }
            else {
                this.profilePage = '/sign-in'
                this.userPhoto = 0;
            }
            console.log(newValue);
        }
    },
    created() {

    }
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.account {
    margin-left: 5%;
}

.account a {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.736);
    backdrop-filter: blur(3px);
    padding: 5px 20px 5px 20px !important;
    border-radius: 10px;
    border: 2px solid transparent !important;
    gap: 20px !important;
}

.account a:hover {
    border: 2px solid transparent !important;
    background-color: rgba(25, 0, 255, 0.174) !important;
    color: white !important;
}

.account img {

    height: 40px;
    object-fit: cover;
    width: 40px;
    border-radius: 50%;
}

.account-burger a {
    display: flex !important;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid gray;
}

.account-burger p {
    font-size: 1.3rem;
    margin-left: 20px;
}

.account-burger img {
    height: 40px;
    object-fit: cover;
    width: 40px;
    border-radius: 50%;
}

.active {
    background-color: rgba(188, 188, 249, 0.39) !important;
}

.logo img {
    height: 45px;
    transition: all 0.5s ease-in-out;
}

.nav-block {
    position: fixed;
    width: 100vw;
    z-index: 10;
    box-sizing: border-box;

}

a {
    color: black;
    text-decoration: none;
    font-size: 1.3rem;
}

.header-big {
    /*background-image: url("../assets/images/general/13.jpg");*/

    background-color: rgba(255, 248, 225, 0.808);
    backdrop-filter: blur(5px);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

}

.header-big ul {
    display: flex;
    flex-direction: row;
    padding-left: 10vw;
}

ul {
    margin: 0 !important;
}

li {
    list-style: none;
}

.header-big a {
    height: 50px;
    display: block;
    text-align: center;
    display: flex;
    align-items: center;
    border: 3px solid transparent;
    padding: 0px 20px 0px 20px;
    transition: 0.5s all ease-in-out;
}

.header-big a:hover {
    border-bottom-color: rgb(183, 0, 255);
    background-color: rgb(235, 245, 254);
}

.header-burger {
    display: none;
}

.burger-hidden {}

@media(max-width:960px) {
    .logo {
       /* background-image: url("../assets/images/general/header-bg.jpg");*/
       /*background-image: url("../assets/images/general/13.jpg"); */
       background-color: rgba(255, 248, 225, 0.808);
       backdrop-filter: blur(5px);
        z-index: 10 !important;
    }

    .logo img {
        margin: 5px;

    }

    .header-big {
        display: none;
    }

    .logo-close {
        transform: rotate(180deg);
    }

    .burger-close {
        transform: translateX(-100%);
        z-index: -1;

    }

    .header-burger {
        display: flex;
        flex-direction: column;
        z-index: 10;
        height: 70px;
    }

    .header-burger a {}

    .nav-links-burger {
        background-color: rgb(252, 252, 252);
        display: flex;
        flex-direction: column;
        min-height: 100vh !important;
        transition: all 0.5s ease-in-out;
    }

    .nav-links-burger a {
        padding-top: 2%;
        padding-bottom: 2%;
        padding-left: 20px;
        display: block;

    }

    .nav-links-burger a:hover {
        background-color: aliceblue;
    }

    .nav-links-burger li {
        border-bottom: 1px solid gray;
    }

    .nav-links-burger ul {
        padding: 0;
    }

}

@media(max-width:800px) {
    .logo {
        height: 60px;
    }

    .logo img {
        height: 40px !important;
    }
}

@media(max-width:500px) {
    .logo {
        height: 50px;
    }

    .logo img {
        height: 30px !important;

    }
}
</style>
  