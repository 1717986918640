<template>
  <div class="home">

    <section class="about" :class="{ 'about_vert': windowWidth < 600 }">
      <div class="about-description">
        <h1>Маруся Мастерова</h1>
        <h2>Уникальные украшения ручной работы для индивидуальности в каждой детали</h2>

        <p>Мы стремимся создавать не просто украшения, а маленькие произведения искусства, которые помогут подчеркнуть
          вашу индивидуальность и не оставят равнодушным никого из вашего окружения.</p>
        <div class="title-btn">
          <button>
            <router-link to="/products">Перейти к выбору</router-link>
          </button>
        </div>
      </div>
    </section>

    <section id="discounts" class="discounts ">
      <h2>Акции и скидки</h2>

      <div class="discounts-cards-container scroll-animated">
        <discountCard v-for="discount in discounts" :key="discount" :discount="discount" />
      </div>

    </section>
    <section class="activities " ref="activityContainer">
      <div class="animated-h2">
        <!-- <div class="animation__2-1">
          <div class="front-small"></div>
          <div class="left-small"></div>
          <div class="right-small"></div>
          <div class="bottom-small"></div>
          <div class="top-small"></div>
          <div class="back-small"></div>
        </div> -->
        <h2>Чем мы занимаемся</h2>
        <!--    <div class="animation__2-1">
          <div class="front-small"></div>
          <div class="left-small"></div>
          <div class="right-small"></div>
          <div class="bottom-small"></div>
          <div class="top-small"></div>
          <div class="back-small"></div>
        </div> -->

      </div>


      <ul class="scroll-animated">
        <li v-for="activity in activities" :key="activity">
          <ActivityCard :activityInfo="activity"></ActivityCard>
        </li>
      </ul>



    </section>
    <section class="feedback">
      <h2>Отзывы о нас</h2>

      <div class="feedback-add-btn">
        <div v-if="!isNewFeedback" @click="showFeedbackForm">
          <img src="../assets/images/icons/write.png" alt="">
          <p>Добавить отзыв</p>
        </div>
        <img v-else class="feedback-add-btn-close" @click="isNewFeedback = false" src="../assets/images/icons/close.png"
          alt="">
      </div>

      <transition name="fade-scale" mode="out-in">
        <div v-if="isNewFeedback" class="feedback-add">
          <textarea v-model="newFeedback" placeholder="Напишите ваше мнение о нас!"></textarea>
          <p v-if="emptyFeedback" style="color: red;">*Необходимо написать текст*</p>
          <div class="feedback-add-load-images">
            <div v-for="img in newImages" :key="img">
              <img :src="img.url" alt="">
            </div>
          </div>

          <transition name="fade-scale" mode="out-in">
            <div class="feedback-add-image" v-if="isFeedbackNewImage">
              <div>
                <img v-if="!newImageLoad" src="../assets/images/icons/empty-photo.jpg" alt="">
                <img v-else :src="newImage.url" alt="">
                <label for="feedbackImgInput" class="inputFile"> Выбрать файл
                  <input type="file" hidden id="feedbackImgInput" accept="image/*" @change="newImageChange">
                </label>
                <p v-if="wrongTypeFormat" style="color: red;">*Поддерживаемые типы файлов - png, jpg*</p>
                <button class="btn-standart" @click="addFeedbackImage">Добавить</button>
                <button class="btn-cancel" @click="isFeedbackNewImage = false">Отменить</button>
              </div>
            </div>

            <div class="feedback-add-image-btn" v-else-if="newImages.length < 5">
              <div class="cart-operations-btn" @click="isFeedbackNewImage = true">
                <div class="first-line" :class="{ 'first-line-done': isInCart }"></div>
                <div class="second-line" :class="{ 'second-line-done': isInCart }"></div>
              </div>
              <p>Добавить изображение </p>
            </div>
          </transition>

          <button class="btn-standart" @click="addFeedback">Оставить отзыв</button>
        </div>
      </transition>

      <ul ref="feedbackList" class="feedback-ul">
        <li v-for="feedbackItem in showedFeedback" :key="feedbackItem" class="feedback-scroll-animated">
          <FeedbackCard :feedbackCardInfo="feedbackItem"></FeedbackCard>
        </li>
      </ul>


      <div class="feedback-show-more">
        <button v-if="showedFeedbackCount < feedback.length" @click="showMoreFeedback">Показать еще</button>
      </div>


      <transition name="modal-fade">
        <SignInModal v-if="modalState" @onCloseModal="modalState = false"
          :message="'Для того, чтобы оставить отзыв необходимо авторизоваться'">
        </SignInModal>
      </transition>

      <transition name="modal-fade">
        <ThankModal v-if="thankState" @onCloseModal="thankState = false" :message="`Спасибо за предоставленный отзыв! 
          Он появится на сайте после проверки администратором`">
        </ThankModal>
      </transition>

    </section>


  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'
import discountCard from '@/components/discountCard.vue'
import ActivityCard from '@/components/ActivityCard.vue'
import FeedbackCard from '@/components/FeedbackCard.vue'
import SignInModal from '@/components/modals/SignInModal.vue'
import ThankModal from '@/components/modals/ThankModal.vue'
import { mapState } from 'vuex'
/* import FeedbackDataService from '@/service/FeedbackDataService' */


export default {
  name: 'HomeView',
  components: {
    discountCard,
    ActivityCard,
    FeedbackCard,
    SignInModal,
    ThankModal
  },
  data() {
    return {
      /*    activities: this.$store.state.homeActivities, */
      showedFeedback: [],
      showedFeedbackCount: 2,
      isNewFeedback: false,
      newFeedback: '',
      isFeedbackNewImage: false,
      newImages: [],
      newImage: {},
      newImageLoad: false,
      modalState: false,
      thankState: false,
      emptyFeedback: false,
      wrongTypeFormat: false,
      scrollPosition: 0
    }
  },
  computed: {
    windowWidth() {
      return window.innerWidth
    },
    ...mapState({
      feedback: state => state.feedback.all,
      discounts: state => state.discounts.all,
      activities: state => state.activities.all,
      user: state => state.user.all
      // ...
    })
  },
  watch: {
    feedback(newValue) {
      this.showedFeedback = newValue.slice(0, this.showedFeedbackCount);
    },
    discounts() {
    }
  },
  methods: {

    scrollListener() {
      this.scrollPosition = window.scrollY;
    },
    changeActivityHeight(n, isShowed) {
      if (isShowed) {
        this.$refs.activityContainer.style.height = this.$refs.activityContainer.clientHeight + n + 'px'
      }
    },
    showMoreFeedback() {
      this.showedFeedbackCount += 2;
      this.showedFeedback = this.feedback.slice(0, this.showedFeedbackCount);
      setTimeout(() => {
        if (this.showedFeedbackCount - 1 >= this.feedback.length) {
          this.$refs.feedbackList.children[this.feedback.length - 1].scrollIntoView({ block: 'end', behavior: 'smooth' });
        }
        else this.$refs.feedbackList.children[this.showedFeedbackCount - 1].scrollIntoView({ block: 'end', behavior: 'smooth' });
      }, 100);
    },
    showFeedbackForm() {
      if (this.user?.id) {
        this.isNewFeedback = true;
      }
      else {
        this.modalState = true;
      }
    },
    newImageChange(e) {
      let src = e.target.files[0].name;
      let file = e.target.files[0];
      let url = '';
      let type = src.split('.');
      type = type[type.length - 1];
      if (type == 'jpg' || type == 'png' || type == 'jpeg') {
        this.wrongTypeFormat = false;
        let fileReader = new FileReader();
        fileReader.onload = function () {
          url = fileReader.result;
        }

        fileReader.readAsDataURL(e.target.files[0]);
        let watcher = setInterval(() => {
          if (url != '') {
            this.newImage.src = src;
            this.newImage.file = file;
            this.newImage.url = url;
            this.newImageLoad = true;
            clearInterval(watcher);
          }

        }, 100)
      }
      else {
        this.wrongTypeFormat = true;
      }
    },

    addFeedbackImage() {
      if (this.newImage?.src) {
        this.newImages.push({
          src: this.newImage.src,
          url: this.newImage.url,
          file: this.newImage.file
        });
        this.newImage = {};
        this.newImageLoad = false;
        this.isFeedbackNewImage = false;
      }
    },
    addFeedback() {

      if (this.newFeedback != '') {
        this.emptyFeedback = false;
        let now = new Date;
        let day = now.getDate();
        let month = now.getUTCMonth() + 1;
        let year = now.getFullYear();

        let feedback = {
          text: this.newFeedback,
          date: year + '-' + month + '-' + day,
          images: this.newImages,
          user_id: this.user?.id,
          user: this.user
        }
        this.$store.dispatch('feedback/create', feedback);
        this.newImage = {};
        this.newImages = [];
        this.newFeedback = '';
        this.isNewFeedback = false;
        this.isFeedbackNewImage = false;
        this.thankState = true;
      }
      else {
        this.emptyFeedback = true;
      }
    }
  },
  created() {
    this.$store.dispatch('activities/getAll');
    this.$store.dispatch('feedback/getAllowed');
    if (this.$store.state.discounts?.all.length == 0) {
      this.$store.dispatch('discounts/getAll');
    }
    else {
      /*       this.feedback = this.$store.state.feedback.all; */
      this.discounts = this.$store.state.discounts.all;
      /*  this.showedFeedback = this.feedback.slice(0, this.showedFeedbackCount); */
    }

  },
  mounted() {
    window.scroll({
      left: 0, // до какого количества пикселей прокрутить вправо
      top: this.$store.state.homeScroll, // до какого количество пикселей прокрутить вниз
      behavior: 'smooth' // определяет плавность прокрутки: 'auto' - мгновенно (по умолчанию), 'smooth' - плавно
    });

    this.$store.commit('setActivePage', 'home');



    // Удалить CSS-класс square-transition
    let scrollAnimation = () => {
      try {
        let scrollAnimated = document.querySelectorAll(".scroll-animated");

        for (let i = 0; i < scrollAnimated.length; i++) {
          if (!scrollAnimated[i].classList.contains("animated-active")) {
            let windowHeight = window.innerHeight;
            let elementTop = scrollAnimated[i].getBoundingClientRect().top;
            let elementVisible = 250;

            if (elementTop < windowHeight - elementVisible) {
              scrollAnimated[i].classList.add("animated-active");
            }
          }
          /*  else {
             scrollAnimated[i].classList.remove("animated-active");
             scrollAnimated[i].classList.add("animated-leave");
           } */
        }
      }
      catch {
        window.removeEventListener("scroll", scrollAnimation);
      }
    }

    let feedbackScrollAnimation = () => {
      try {
        let scrollAnimated = document.querySelectorAll(".feedback-scroll-animated");

        for (let i = 0; i < scrollAnimated.length; i++) {
          if (!scrollAnimated[i].classList.contains("feedback-animated-active")) {
            let windowHeight = window.innerHeight;
            let elementTop = scrollAnimated[i].getBoundingClientRect().top;
            let elementVisible = 50;

            if (elementTop < windowHeight - elementVisible) {
              scrollAnimated[i].classList.add("feedback-animated-active");
            }
          }
          /*  else {
             scrollAnimated[i].classList.remove("animated-active");
             scrollAnimated[i].classList.add("animated-leave");
           } */
        }
      }
      catch {
        window.removeEventListener("scroll", feedbackScrollAnimation);
      }

    }

    window.addEventListener("scroll", scrollAnimation);
    window.addEventListener("scroll", feedbackScrollAnimation);

    window.addEventListener('scroll', this.scrollListener);

  },
  beforeUnmount() {
    this.$store.commit('setHomeScroll', this.scrollPosition);
  },
  unmounted() {
    window.removeEventListener('scroll', this.scrollListener);
  },
}
</script>

<style scoped>
.fade-scale-enter-from,
.fade-scale-leave-to {
  transform: scale(0);
  max-height: 0px;
  opacity: 0;
}

.fade-scale-enter-to,
.fade-scale-leave-from {
  max-height: 1000px;
}


.fade-scale-enter-active,
.fade-scale-leave-active {
  transition: all 0.5s ease-in-out;
}

.animated-leave {
  opacity: 0 !important;
  z-index: -1 !important;
  transform: translateY(-100px) !important;
}

.feedback-animated-active {
  opacity: 1 !important;
  z-index: 1 !important;
  transform: none !important
}

.feedback-scroll-animated {
  opacity: 0.3;
  z-index: -1 !important;
  transform: scale(0.5);
  transition: transform 1s ease-in-out, opacity 1s ease-in-out;

}

.animated-active {
  z-index: 1 !important;
  transform: none !important;
  visibility: visible !important;
}

.scroll-animated {
  z-index: -1 !important;
  visibility: hidden;
  transform: translateY(150px);
  transition: transform 1s ease-in-out, opacity 1s ease-in-out;

}

.about {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100vw;
  height: 100vh;
  background-image: url("../assets/images/mainPage/15.png");
  background-size: cover;
}

.about_vert {
  background-image: url("../assets/images/mainPage/15_vert.png");
}

.about::after {
  content: '';
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.about-description h1 {

  background: linear-gradient(45deg, #2d45e0, #50c2ec, #2d07ec);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  letter-spacing: 1px;
  font-size: 2rem;
  text-shadow: 5px 5px 10px rgb(244, 235, 173);
}

.about-description h2 {
  font-size: 1.2rem;
  padding-top: 0;
}

.about-description {

  text-align: center;
  position: relative;
  width: 30%;
  z-index: 2;
  color: rgb(0, 0, 0);
  text-shadow: 0px 0px 15px #efefef;
  padding: 20px;
}

.about-description::after {
  border-radius: 20px;
  content: ' ';
  position: absolute;
  top: -20px;
  left: -20px;
  z-index: -1 !important;
  width: 100%;
  height: 100%;
  padding: 20px;
  /*background: radial-gradient(circle, rgba(0, 0, 0, 0.905) 0%, rgba(240, 187, 187, 0) 100%);*/
  background-color: rgba(255, 255, 255, 0.638);
  background-size: 200%;
  background-position: center;
  backdrop-filter: blur(5px);
}

.title-btn {
  padding-top: 20px;
  display: flex;
  justify-content: center;
}

.title-btn button {
  padding: 10px 30px 10px 30px;
  border-radius: 20px;
  border: 2px solid rgba(248, 211, 178, 0.736);
  background: linear-gradient(to right, rgb(244, 248, 228), rgba(244, 248, 223, 0.896), rgba(255, 238, 223, 0.959), rgba(255, 249, 217, 0.959));
  background-size: 200% 100%;
  box-shadow: 5px 5px 15px rgba(249, 207, 181, 0.507);
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}

.title-btn button:hover {

  background-position: 100% 100%;
  box-shadow: 5px 5px 15px rgba(255, 223, 165, 0.75);
}

.title-btn a {
  text-decoration: none;
  color: #B87843;
  font-weight: bold;
  font-size: 1rem;
  text-shadow: 5px 5px 10px rgb(244, 235, 173);
}

.discounts {
  background: linear-gradient(180deg, #fffdf6a5, #fee1d4b1);
}

h2 {
  margin-top: 0;
  color: #360335;
  text-align: center;
  padding-top: 20px;
  font-size: 1.8rem;
}

.animated-h2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.animation__2-1 {
  width: 30px;
  height: 30px;
  /* animation: rotate-cube 1s ease-in-out infinite; */
  position: relative;
  transform-style: preserve-3d;
  transition: all 1s ease;
  animation: rotation-small 40s ease-in-out infinite;
  margin: 10px;
  margin-left: 40px;
  margin-right: 40px;
}

.animation__2-1>div {
  position: absolute;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: all 1s ease;
  background-color: azure;
  box-shadow: 0 0 1px #f00, 0 0 1px #f00, 0 0 1px #f00, 0 0 1px #f00, 0 0 10px #d0e9 inset, 0 0 35px #53d5;
}

.back-small {
  transform: rotateX(-180deg) translateZ(15px);
}

.top-small {
  transform: rotateX(90deg) translateZ(15px);
}

.front-small {
  transform: translateZ(15px);
}

.bottom-small {
  transform: rotateX(-90deg) translateZ(15px);
}

.left-small {
  transform: rotateY(-90deg) translateZ(15px);
}

.right-small {
  transform: rotateY(90deg) translateZ(15px);
}

@keyframes rotation-small {
  50% {
    transform: perspective(500px) rotateX(360deg) rotateY(720deg);
  }
}

.discounts-cards-container {
  display: flex;
  width: 100%;
  padding-bottom: 40px;
}

.activities {
  padding-top: 20px;
  width: 100%;
  padding-bottom: 100px;
  /*background: linear-gradient(to bottom, rgba(255, 255, 255, 0.156), transparent, transparent, transparent, transparent, transparent, rgba(255, 255, 255, 0.156));*/
  background: linear-gradient(to bottom, #ffebe3, transparent, transparent, transparent, transparent, #fee1d4);
  position: relative;
}

.activities::after {
  content: ' ';
  display: block;
  position: absolute;
  z-index: -1 !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /*background: radial-gradient(circle, rgba(0, 0, 0, 0.905) 0%, rgba(240, 187, 187, 0) 100%);*/
  /* background-color: rgb(2, 2, 46);*/
  background-color: rgba(248, 197, 139, 0.8);

}

.activities ul {
  display: flex;
  width: 100%;
  height: max-content;
  justify-content: center;
  align-items: stretch;
  padding: 0;
}

.activities li {
  margin: 20px;
  flex-basis: 30%;
  height: max-content;
}

.feedback {
  width: 100%;
  padding-bottom: 50px;
  background: linear-gradient(to bottom, #fee1d4, #fff4ef, #ffe7f6);
  transition: all 1s ease-in-out;
  height: auto;
  position: relative;
}

.feedback ul {
  width: 100%;
  margin: 0;
  padding: 0;
}

.feedback li {
  margin: 20px;
  border-radius: 10px;
  border: 1px solid rgba(128, 128, 128, 0.156);
  box-shadow: 5px 5px 10px rgba(128, 128, 128, 0.119);
  backdrop-filter: blur(10px);
  background-color: #ffffff9e;
}

/*
.feedback li:nth-child(even) {
  background-color: #cdedfa35;

}

.feedback li:nth-child(odd) {
  background-color: #cdedfa35;
}
*/
.feedback-show-more {
  width: 100%;
  display: flex;
  justify-content: center;
}

.feedback-show-more button {
  padding: 15px;
  width: 200px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.826);
  color: rgb(0, 0, 0);
  cursor: pointer;
  border: 2px solid rgba(128, 128, 128, 0.156);
  box-shadow: 5px 5px 10px rgba(128, 128, 128, 0.119);
  transition: all 0.5s ease-in-out;
}

.feedback-show-more button:hover {
  background-color: #0c022c;
  color: white;
}

.feedback-add-btn {
  width: 100%;
  padding-right: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 50px;
}

.feedback-add-btn div {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.feedback-add-btn div:hover p {
  color: #6c008d;
}

.feedback-add-btn p {
  margin-left: 15px;
  transition: 0.5s all ease-in-out;
}

.feedback-add-btn-close {
  cursor: pointer;
  transition: all .5s ease-in-out;
}

.feedback-add-btn-close:hover {
  transform: rotateZ(90deg);
}

.feedback-add {
  display: flex;
  flex-direction: column;
  width: calc(100% - 40px);
  border-radius: 10px;
  height: max-content;
  justify-content: center;
  align-items: center;
  margin: 20px;
  transition: all 0.7s ease-in-out;
  border: 2px solid rgba(128, 128, 128, 0.156);
  box-shadow: 5px 5px 10px rgba(128, 128, 128, 0.119);
  padding: 20px;
}

.feedback-add textarea {
  border: 1px solid rgba(128, 128, 128, 0.156);
  box-shadow: 5px 5px 10px rgba(128, 128, 128, 0.119);
  border-radius: 10px;
  height: 150px;
  padding: 15px;
  max-width: 100%;
  min-width: 100%;
  min-height: 50px;
  font-size: 1.1rem;
}

.feedback-add button {
  width: 200px;
  margin-top: 15px;
}

.feedback-add-load-images {
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  flex-wrap: wrap;
}

.feedback-add-load-images div {
  width: 100px;
  height: 130px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
}

.feedback-add-load-images img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.feedback-add-image {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
}

.feedback-add-image img {
  height: 300px;
  width: 250px;
  object-fit: cover;
  border-radius: 10px;
}

.feedback-add-image div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: max-content;
  padding: 20px;
  border-radius: 10px;
  background-color: #f1f1f16a;
}

.inputFile {
  border: 1px solid gray;
  padding: 10px;
  margin: 10px;
  background-color: white;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}

.inputFile:hover {
  box-shadow: 3px 5px 10px gray;
}

.feedback-add-image-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px;
}

.cart-operations-btn {
  height: 50px;
  width: 50px;
  display: flex;
  position: relative;
  border-radius: 50%;
  background-color: white;
  border: 6px solid black;
  box-shadow: 3px 5px 10px rgba(168, 174, 216, 0.452);
  cursor: pointer;
  transition: transform 0.5s ease-in-out;
}

.cart-operations-btn:hover {
  transform: rotateZ(90deg) scale(1.1);
}

.cart-operations-btn div {
  transition: all 0.5s ease-in-out;
}

.first-line {
  position: absolute;
  align-self: center;
  justify-self: center;
  top: calc(50% - 3px);
  left: calc(10%);
  background-color: black;
  height: 6px;
  width: 80%;
  border-radius: 5px;
}

.second-line {
  position: absolute;
  left: calc(50% - 3px);
  top: calc(10%);
  background-color: black;
  height: 80%;
  width: 6px;
  border-radius: 5px;
}

@media(max-width:1250px) {
  .about-description {
    width: 40%;
  }
}

@media(max-width:1050px) {
  .activities ul {
    flex-direction: column;
    width: 70%;
    margin: 0 auto;
  }

}

@media(max-width:900px) {
  .about-description {
    width: 50%;
  }

  .title-btn button {
    width: 200px;
    padding-left: 5px;
    padding-right: 5px;
  }

  .title-btn a {
    font-size: 1rem;
    display: block;
    width: 100%;
  }
}

@media(max-width:800px) {
  .activities ul {
    width: 90%;
  }

  .feedback-add-btn img {
    width: 30px;
    height: 30px;
  }

  .cart-operations-btn {
    width: 40px;
    height: 40px;
  }

  .cart-operations-btn {
    border-width: 4px;
  }

  .first-line {
    height: 4px;
    top: calc(50% - 2px);
  }

  .second-line {
    width: 4px;
    left: calc(50% - 2px);
  }

  .feedback-add-image img {
    width: 200px;
    height: 250px;
  }
}

@media(max-width:700px) {
  .about-description {
    width: 60%;
  }

  .feedback-add-btn img {
    width: 25px;
    height: 25px;
  }


}

@media(max-width:555px) {
  .about-description {
    width: 70%;
    padding: 10px;
  }

  .feedback-add-btn img {
    width: 20px;
    height: 20px;
  }
}

@media(max-width:420px) {
  .about-description {
    width: 80%;
    background-color: rgba(255, 255, 255, 0.825);
    border-radius: 15px;
    padding-bottom: 30px;
    text-align: center;
  }

  .about-description h2 {
    text-align: center;
  }

  .about-description::after {
    display: none;
  }

  .feedback-add-image img {
    width: 150px;
    height: 200px;
  }

  .feedback-add-image div {
    padding: 10px;
  }

  .feedback-add button {
    width: 150px;
  }
}
</style>