
<template>
  <div class="admin-main">

    <div class="animation__2-1">
      <div class="front-small"></div>
      <div class="left-small"></div>
      <div class="right-small"></div>
      <div class="bottom-small"></div>
      <div class="top-small"></div>
      <div class="back-small"></div>
    </div>

    <div class="btn-container">
      <button>
        <router-link to="/admin/products">Редактировать товары</router-link>
      </button>

      <button>
        <router-link to="/admin/gallery">Редактировать Галерею</router-link>
      </button>

      <button>
        <router-link to="/admin/orders">Редактировать заказы</router-link>
      </button>

      <button>
        <router-link to="/admin/discounts">Редактировать Скидки</router-link>
      </button>

      <button>
        <router-link to="/admin/activities">Редактировать активности</router-link>
      </button>

      <button>
        <router-link to="/admin/feedback">Редактировать отзывы</router-link>
      </button>

    </div>

    <div class="animation__2-1">
      <div class="front-small"></div>
      <div class="left-small"></div>
      <div class="right-small"></div>
      <div class="bottom-small"></div>
      <div class="top-small"></div>
      <div class="back-small"></div>
    </div>

  </div>
</template>
  
<script>
// @ is an alias to /src
/* import GalleryModal from '@/components/modals/GalleryModal.vue'
import { mapState } from 'vuex' */
import UserDataService from "@/service/UserDataService";
import { mapState } from 'vuex'
export default {
  name: 'AdminMainView',
  components: {
  },
  data() {
    return {
      /* galleryItems: this.$store.state.galleryItems, */

    }
  },
  computed: {
    // смешиваем результат mapState с внешним объектом
    ...mapState({
      user: state => state.user.all
      // ...
    })
  }, watch: {
    user(newValue) {
      if (newValue?.id) {
        if (newValue.role != 'admin') this.$router.push('/');
      }
      else this.$router.push('/');
    }
  },
  methods: {

  },

  created() {

  },
  mounted() {
    UserDataService.checkSession().then((res) => {
            if (res.data == 'session unset') {
                this.$router.push('/');
            }
        });
    /* this.$store.state.activaPage = 'gallery'; */
    if (this.user?.id) {
      if (this.user.role != 'admin') this.$router.push('/');
    }


  }
}
</script>
  
<style>
.animation__2-1 {
  width: 100px;
  height: 100px;
  /* animation: rotate-cube 1s ease-in-out infinite; */
  position: relative;
  transform-style: preserve-3d;
  transition: all 1s ease;
  animation: rotation-small 40s ease-in-out infinite;
  margin: 30px;
}

.animation__2-1>div {
  position: absolute;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: all 1s ease;
  background-color: azure;
  box-shadow: 0 0 1px #f00, 0 0 1px #f00, 0 0 1px #f00, 0 0 1px #f00, 0 0 10px #d0e9 inset, 0 0 35px #53d5;
}

.back-small {
  transform: rotateX(-180deg) translateZ(50px);
}

.top-small {
  transform: rotateX(90deg) translateZ(50px);
}

.front-small {
  transform: translateZ(50px);
}

.bottom-small {
  transform: rotateX(-90deg) translateZ(50px);
}

.left-small {
  transform: rotateY(-90deg) translateZ(50px);
}

.right-small {
  transform: rotateY(90deg) translateZ(50px);
}

@keyframes rotation-small {
  50% {
    transform: perspective(500px) rotateX(360deg) rotateY(720deg);
  }
}

.admin-main {
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: linear-gradient(45deg, rgb(255, 139, 158), rgb(92, 92, 255), rgb(133, 247, 255));
}

.btn-container {
  border: 1px solid gray;
  width: 600px;
  min-height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.86);
  backdrop-filter: blur(10px);
}

.btn-container h2 {
  text-align: center;
}

.btn-container button {
  padding: 10px;
  width: 150px;
  background-color: white;
  border-radius: 5px;
  border: none;
  margin: 15px;
  cursor: pointer;
  padding: 0px;
  align-self: center;
}

.btn-container a {
  display: block;
  border-radius: 5px;
  border: 1px solid gray;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: black;
  padding: 7px;
  transition: all 0.5s ease-in-out;
}

.btn-container a:hover {
  background-color: rgb(9, 9, 100);
  border: 1px solid white;
  color: white !important;
}
</style>
  