
<template>
    <div class="user">
        <div class="admin-btn">
            <router-link to="/admin">
                <button class="btn-standart">На главную</button>
            </router-link>
        </div>
        <div class="user-actions">
            <h3>Активные заказы</h3>
            <div class="active-orders orders">
                <orderItem v-for="order in activeOrders" :key="order.id" :orderInfo="order" class="orders"></orderItem>
            </div>
            <h3>Завершенные заказы</h3>
            <div class="completed-orders orders">
                <orderItem v-for="order in completedOrders" :key="order.id" :orderInfo="order" class="orders"></orderItem>
            </div>
        </div>
    </div>
</template>
  
<script>
// @ is an alias to /src
import UserDataService from "@/service/UserDataService";
import orderItem from '@/components/admin/OrderItem.vue'
import { mapState } from 'vuex'

export default {
    name: 'OrdersView',
    components: {
        orderItem
    },
    data() {
        return {
            activeOrders: [],
            completedOrders: []
        }
    },
    computed: {
        // смешиваем результат mapState с внешним объектом
        ...mapState({
            orders: state => state.order.all,
            user: state => state.user.all
            // ...
        })
    },
    watch: {
        orders(newValue) {
            if (newValue != "") {
                let arr = JSON.parse(JSON.stringify(newValue));
                arr.sort((a, b) => a.status - b.status);
                arr.forEach(order => {
                    if (order.products != '') {
                        if (order.status <= 4) this.activeOrders.push(order);
                        else this.completedOrders.push(order);
                    }
                });
            }
        },
        user(newValue) {
            if (newValue?.id) {
                if (newValue.role != 'admin') this.$router.push('/');
            }
            else this.$router.push('/');
        }
    },
    methods: {

    },
    created() {
        UserDataService.checkSession().then((res) => {
            if (res.data == 'session unset') {
                this.$router.push('/');
            }
        });
        if (this.user?.id) {
            if (this.user.role != 'admin') this.$router.push('/');
        }

        this.$store.dispatch('order/getAllOrders');

    },
    mounted() {

    }
}

</script>
  
<style scoped>
.admin-btn {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

h3 {
    text-align: center;
    width: 100%;
    border-bottom: 1px solid gray;
    border-top: 1px solid transparent;
    background-color: rgb(0, 0, 42);
    color: white;
    font-weight: 400;
    margin: 0;
    padding: 5px;

}

h3:first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border: 1px solid transparent;
}

.user {
    padding: 80px 20px 80px 20px;
    min-height: 100vh;
    max-width: 100%;
    /* background-image: url("../assets/images/mainPage/11.jpg");*/
    background-color: rgba(251, 243, 255, 0.678);
    background-size: cover;
    position: relative;
    display: flex;
    flex-direction: column;
}



.user-actions {
    width: calc(100%);
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border: 1px solid gray;
    border-radius: 10px;
    padding: 0px;
    background-color: rgb(255, 255, 255);
    position: relative;
}

.user-actions div {
    width: 100%;
}

.orders {
    border-bottom: 1px solid gray;
}

.active-orders:last-child {
    border-bottom: 1px solid transparent;
}

.orders:last-child {
    border-bottom: 1px solid transparent;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.orders:nth-child(odd) {
    background-color: rgba(247, 251, 255, 0.784);
}

.completed-orders {
    background-color: rgb(145, 145, 145);
}
</style>
  