<template>
  <div class="accept-modal" 
  @click="closeModal">

      <div class="accept-modal-container"
      @click="(e)=> e.stopPropagation()">
          <div class="accept-modal-title">Внимание!</div>
          <div class="accept-modal-text">
              {{ message }}
          </div>
          <div class="accept-modal-content">
              <router-link :to="'/sign-in'">Авторизация</router-link>
              <router-link :to="'/sign-up'">Регистрация</router-link>
          </div>
          <div class="accept-modal-btns">
              <button class="cancel" @click="closeModal">Закрыть</button>
          </div>
      </div>

  </div>
</template>

<script>

export default {
  name: 'SignInModal',
  props: ['message'],
  data() {
      return {
          modalState: true
      }
  },
  methods: {
      closeModal() {
          this.modalState = false;
      },
      accept() {
          this.$emit('onAccept');
          this.modalState = false;
      }
  },
  watch: {
      // eslint-disable-next-line
      modalState(newValue, oldValue) {
          if (!newValue) {
              this.$emit('onCloseModal');
          }

      }
  },
  mounted() {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
a{
  text-decoration: none;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #06003e;
  transition: all .5s ease-in-out;
  text-shadow: 5px 5px 10px #1700e84c;
  font-size: 1.4rem;
}

a:hover{
  color: #c800db;
  text-shadow: 5px 5px 10px #c800db;
}
.accept-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.704);
  z-index: 20;
  cursor: no-drop;
  
}

.accept-modal-container {
  min-height: 250px;
  height: 400px;
  max-height: 80vh !important;
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.884);
  backdrop-filter: blur(3px);
  border-radius: 15px;
  cursor:default;
  font-size: 1.3rem;
  overflow: scroll;
}

.accept-modal-title {
  width: 100%;
  height: 80px;
  background: linear-gradient(0deg, #070b0f, #111b29);
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  color: white;
}

.accept-modal-content {
  margin-top: 20px;
  height: 320px;
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.accept-modal-text {
  margin-top: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;

  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  white-space:pre-line;
}

.accept-modal-btns {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}

.accept-modal-btns button {
  padding: 10px;
  width: 150px;
  border-radius: 10px;
  margin-bottom: 10px;
  border: 1px solid gray;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}

.accept {
  background-color: red;
  color: white;
}

.accept-modal-btns button:hover {
  background-color: #070b0f;
  color: white;
}

.accept-modal-content img,
.accept-modal-content video {
  max-width: 100%;
  max-height: 100%;
  border-radius: 10px;
}


@media(max-width:1100px) {

  .accept-modal-container {
      min-width: 50vw;
      width: 500px !important;
      max-width: 80vw;
      overflow: scroll;
  }

  .accept-modal-text{
      padding-top: 20px;
      padding-bottom: 20px;
  }

}

@media(max-width:350px) {

  .accept-modal-container {
      min-width: 50vw;
      min-height: 200px;
      width: 500px !important;
      max-width: 90vw;
  }

}


@media(max-height:300px) {

  .accept-modal-text{
    margin-top: 10px;
    padding-bottom: 10px;
  }
  .accept-modal-content{
    margin-top: 10px;
  }

  .accept-modal-content a{
    margin: 5px;
  }

}
</style>
