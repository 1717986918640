<template>
    <div class="home">

        <div class="go-back">
            <img @click="goBack" src="../../assets/images/icons/go-back.png" alt="">
        </div>

        <form action="">


            <div class="product-container" v-if="name != undefined">

                <div class="images-block">
                    <div class="main-image-container">
                        <div class="main-image">
                            <template v-for="image in images" :key="image">
                                <img v-if="image.type == 'img' && image?.contentFrom != 'fileread'" :style="imagePosition"
                                    :src="$store.state.serverRoot+'images/products/' + image.src" alt="">

                                <!--           <img v-if="image.type == 'img' && image?.contentFrom != 'fileread'" :style="imagePosition"
                                    :src="`../images/products/` + image.src" alt=""> -->

                                <video v-else-if="image?.contentFrom != 'fileread'" autoplay="autoplay" loop controls muted
                                    preload="auto" :style="imagePosition">
                                    <source :src="$store.state.serverRoot+'images/products/' + image.src" type="video/mp4">
                                </video>

                                <img v-if="image.type == 'img' && image?.contentFrom == 'fileread'" :style="imagePosition"
                                    :src="image.url" alt="">

                                <video v-else-if="image?.contentFrom == 'fileread'" autoplay="autoplay" loop controls muted
                                    preload="auto" :style="imagePosition">
                                    <source :src="image.url" type="video/mp4">
                                </video>

                            </template>

                        </div>
                        <div class="current-image">
                            <span v-for="n in images.length" :key="n"
                                :class="{ 'current-image-indicator-active': n == (activeImage + 1) }"
                                class="current-image-indicator"></span>

                        </div>
                        <span @click="prevImage" class="click-left"></span>
                        <span @click="nextImage" class="click-right"></span>
                    </div>

                    <div class="images-admin">
                        <div class="images-admin-card" v-for="(image, index) in images" :key="image">
                            <span>{{ index + 1 }}</span>
                            <div class="image-info">
                                <p>Файл: {{ image.src }}</p>
                                <p>Тип: {{ image.type }}</p>
                            </div>
                            <div class="image-btns">
                                <button class="image-btn edit" @click="editImage(index)">Редактировать</button>
                                <button class="image-btn delete" @click="deleteImage(index)">Удалить</button>
                            </div>
                        </div>

                        <div v-if="isImageAdded" class="image-redact images-admin-card">
                            <span>{{ redactedIndex }}</span>
                            <div class="image-info">
                                <label for="">
                                    <span>Файл</span>
                                    <input @change="newImageChange" class="image-file" type="file"
                                        accept="image/*, video/*">
                                </label>

                                <label for="">
                                    <span>Тип</span>
                                    <select v-model="newImageType">
                                        <option disabled value="">Выберите один из вариантов</option>
                                        <option>img</option>
                                        <option>vid</option>
                                    </select>
                                </label>
                            </div>
                            <div class="image-btns">
                                <button class="image-btn save" @click="saveNewImage">Сохранить</button>
                                <button class="image-btn delete" @click="cancelNewImage">Отменть</button>
                            </div>

                        </div>

                        <div v-else class="image-add-btn">
                            <button @click="imageAddShow">Добавить изображение</button>
                        </div>

                    </div>

                </div>

                <div class="text-block">
                    <div class="description-block">

                        <label for="">
                            <span>Название</span>
                            <input type="text" class="title" v-model="name">
                        </label>
                        <div class="buy-block">
                            <p class="price">
                                <label for="">
                                    <span>Цена</span>
                                    <input type="text" v-model="price">
                                </label>
                                <label for="">
                                    <span>Цена со скидкой</span>
                                    <input type="text" v-model="discountPrice">
                                </label>
                                <!-- <span v-if="discountPrice" class="bold discount">{{ discountPrice }}₽</span>
                                <span :class="{ 'price-old': discountPrice }" class="bold">{{ price
                                }}₽</span> -->
                            </p>
                        </div>


                        <div class="description">
                            <label for="">
                                <span>Категория</span>
                                <select v-model="category">
                                    <option disabled value="">Выберите один из вариантов</option>
                                    <option>Украшения</option>
                                    <option>Одежда</option>
                                </select>
                            </label>

                            <p style="color: rgb(100, 100, 100);">Описание:</p>

                            <label for="">
                                <textarea v-model="description">
                                </textarea>
                            </label>
                        </div>
                        <p style="color: rgb(100, 100, 100);">Характеристики:</p>
                        <div class="params">

                            <div>
                                <p class="service-inscription" style="color: rgb(100, 100, 100);">Размер:</p>
                                <!-- <p class="text-description">{{ size }}</p -->
                                <label for="">
                                    <textarea class="text-description" v-model="size">
                                        </textarea>
                                </label>
                            </div>
                            <div>

                                <p class="service-inscription" style="color: rgb(100, 100, 100);">Состав:</p>
                                <!-- <p class="text-description">{{ composition }}</p> -->
                                <label for="">
                                    <textarea class="text-description" v-model="composition">
                                    </textarea>
                                </label>
                            </div>
                        </div>

                        <div class="product-recomendations">


                            <div @click="changeRecomendationsState"
                                :class="{ 'recomendations-btn-hidden': isRecomendationsShowed }" class="recomendations-btn">
                                <p style="color: rgb(100, 100, 100);">Рекомендации</p>
                                <img src="../../assets/images/icons/slider-right-btn.png" alt="">
                            </div>
                            <div :class="{ 'recomendations-hidden': !isRecomendationsShowed }" class="recomendations-text">
                                <img @click="changeRecomendationsState"
                                    src="../../assets/images/icons/slider-left-btn-white.png" alt="">
                                <div>
                                    <p>Одежду лучше стирать в стиральной машине на режимах ручная стирка и быстрая стирка.
                                    </p>
                                    <p>Украшениям вернут первоначальный блеск теплая ванночка из раствора воды, шампуня и
                                        нашатырного спирта
                                    </p>
                                </div>
                            </div>


                        </div>
                    </div>

                </div>

            </div>
            <div class="save-btn-container">
                <button class="image-btn save-btn" @click="saveChanges">Сохранить</button>
            </div>
        </form>
        <transition name="modal-fade">
            <LoadingModal v-if="loadingState">
            </LoadingModal>
        </transition>
    </div>
</template>
  
<script>
// @ is an alias to /src
import UserDataService from "@/service/UserDataService";
import LoadingModal from '@/components/modals/loadingModal.vue'
import ProductDataService from "@/service/ProductDataService";
import { mapState } from 'vuex'

export default {
    name: 'ProductsView',
    components: {
        LoadingModal
    },
    data() {
        return {
            product: {},
            mainImage: "",
            activeImage: 0,
            isRecomendationsShowed: false,
            name: "",
            id: 0,
            description: "",
            size: "",
            composition: "",
            price: 0,
            discountPrice: 0,
            category: "",
            images: [],
            isImageAdded: false,
            newImageType: "",
            newImageUrl: "",
            newImageSrc: "",
            loadedFile: "",
            redactedIndex: -1,
            loadingState: false
        }
    },
    methods: {
        editImage(index) {
            this.newImageSrc = this.images[index].src;
            this.newImageType = this.images[index].type;
            this.isImageAdded = true;
            this.redactedIndex = index + 1;
            window.scrollBy(0, (this.images.length - 1 - index) * 200);
        },
        deleteImage(index) {
            this.images.splice(index, 1);
        },
        imageAddShow() {
            this.isImageAdded = true;
            this.redactedIndex = this.images.length + 1;
        },
        newImageChange(e) {


            this.newImageSrc = e.target.files[0].name;
            this.loadedFile = e.target.files[0];

            var url = '';
            var fileReader = new FileReader();
            fileReader.onload = function () {
                url = fileReader.result;
            }

            fileReader.readAsDataURL(e.target.files[0]);
            setTimeout(() => this.newImageUrl = url, 1000);

        },
        saveNewImage() {
            if (this.redactedIndex > this.images.length || this.images.length == 0) {
                this.images.push({
                    src: this.newImageSrc,
                    url: this.newImageUrl,
                    type: this.newImageType,
                    contentFrom: "fileread",
                    loadedFile: this.loadedFile
                });
            }
            else {
                this.images[this.redactedIndex - 1] = {
                    src: this.newImageSrc,
                    url: this.newImageUrl,
                    type: this.newImageType,
                    contentFrom: "fileread",
                    loadedFile: this.loadedFile
                };
            }
            this.isImageAdded = false;
        },

        saveChanges() {

            this.loadingState = true;
            let images = "";
            let files = [];
            this.images.forEach(image => {
                images += '|' + image.type + '|' + image.src + ' $;$ '
                if (image?.loadedFile) files.push(image.loadedFile)
            });
            console.log(images);
            let formData = new FormData();
            for (let i = 0; i < files.length; i++) {
                let file = files[i];
                formData.append('files[' + i + ']', file);
            }
            console.log(formData);
            ProductDataService.saveFile(formData).then((res) => {
                console.log(res);
                if (this.productId != -1) {
                    this.$store.dispatch('products/update', {
                        id: this.id,
                        name: this.name,
                        images: images,
                        description: this.description,
                        size: this.size,
                        composition: this.composition,
                        price: this.price,
                        discountPrice: this.discountPrice,
                        category: this.category,
                    }).then(() => {
                        setTimeout(() => {
                            this.loadingState = false;
                            this.$router.push("/admin/products");
                        }, 1000);
                    });
                }
                else {
                    this.$store.dispatch('products/create', {
                        name: this.name,
                        images: images,
                        description: this.description,
                        size: this.size,
                        composition: this.composition,
                        price: this.price,
                        discountPrice: this.discountPrice,
                        category: this.category,
                    }).then(() => {
                        setTimeout(() => {
                            this.loadingState = false;
                            this.$router.push("/admin/products");
                        }, 1000);
                    });
                }

                /*       setTimeout(() => {
                          this.loadingState = false;
                          this.$router.push("/admin/products");
                      }, 1000); */
            });




        },


        cancelNewImage() {
            this.isImageAdded = false;
        },
        nextImage() {
            if (this.activeImage < this.images.length - 1) this.activeImage++

        },
        prevImage() {
            if (this.activeImage > 0) this.activeImage--
        },
        goBack() {
            history.back();
        },
        changeRecomendationsState() {
            this.isRecomendationsShowed = !this.isRecomendationsShowed;
        },
        getProductInfo() {
            ProductDataService.getItem(this.productId).then((res) => {
                let itemImages = [];

                res.data.images?.split(" $;$ ").forEach(image => {

                    let imageItem = new Object();
                    imageItem.type = image?.slice(1, 4);
                    imageItem.src = image?.slice(5, image?.length);

                    if (imageItem.src != "") {
                        itemImages.push(imageItem)
                    }

                });

                this.id = res.data.id;
                this.name = res.data.name.replace(/&quot;/g, '"');
                this.description = res.data.description;
                this.size = res.data.size;
                this.composition = res.data.composition;
                this.price = res.data.price;
                this.discountPrice = res.data.discountPrice;
                this.category = res.data.category;
                this.images = itemImages;

                /*  this.product = storeItem; */


            });
        }
    },
    computed: {
        productId() {
            return +this.$route.params.id;
        },
        /*  isInCart() {
           return this.$store.getters.getCountInCartById(this.productId);
         }, */
        imagePosition() {
            return 'transform:' + 'translateX(-' + this.activeImage * 100 + '%)';
        },
        ...mapState({
            user: state => state.user.all
            // ...
        })
    },
    watch: {
        user(newValue) {
            if (newValue?.id) {
                if (newValue.role != 'admin') this.$router.push('/');
            }
            else this.$router.push('/');
        }
    },
    created() {
        UserDataService.checkSession().then((res) => {
            if (res.data == 'session unset') {
                this.$router.push('/');
            }
        });
        if (this.user?.id) {
            if (this.user.role != 'admin') this.$router.push('/');
            else if (this.productId != -1) {
                this.getProductInfo();
            }
        }

    },
    mounted() {

        window.scrollTo(0, 0);
    }
}
</script>
  
<style scoped>
textarea {
    width: 100%;
    height: 300px;
}

label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

input,
textarea,
select {
    border-radius: 10px;
    padding: 7px;
    border: 1px solid gray;
}

.images-admin {
    width: 90%;
    border-radius: 10px;
    margin-top: 30px;


}

.images-admin-card {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid gray;
    border-radius: 10px;
    padding: 10px;
}

.images-admin-card:nth-child(even) {
    background-color: rgb(254, 254, 255);
    border-top: none;
    border-bottom: none;
}

.images-admin-card:nth-child(odd) {
    background-color: rgb(240, 240, 240);
}

.image-info {
    max-width: 50%;
    word-wrap: break-word;
    word-spacing: normal;
}

.image-btns {
    width: 120px;
    margin-right: 15px;
}

.image-btn {
    padding: 7px;
    margin: 5px;
    border-radius: 10px;
    border: 2px solid gray;
    background-color: rgba(255, 255, 255, 0.834);
    color: #0c022c;
    width: 120px;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
}

.image-btn:hover {
    background-color: #0c022c;
    border: 2px solid rgb(255, 255, 255);
    color: white;
}

.delete {
    background-color: rgb(190, 0, 0);
    color: white;
}

.image-file {
    border: none;
    width: 85%;
}

.image-redact {
    margin-top: 50px;
    background-color: #0c022c9e !important;
    color: white !important;
}

.save-btn-container {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    margin-top: 10px;
}

.save-btn {
    width: 200px;
    height: 40px;
    font-size: 1.2rem;
    background-color: #0e003b;
    color: white;
}

.save-btn:hover {
    background-color: #ffffff;
    color: rgb(0, 0, 0);
}

.home {
    display: flex;
    flex-direction: column;
    padding-top: 70px;
    background-size: cover;
    background-color: rgba(197, 193, 236, 0.205);
}

.product-container {
    width: 100vw;
    min-height: calc(100vh - 200px);
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-bottom: 0px;
    padding-top: 0px;
    /*background-image: url("../assets/images/mainPage/12.jpg");*/
}

.go-back img {
    padding-left: 20px;
    transition: all 0.5s ease-in-out;
    cursor: pointer;
}

.go-back img:hover {
    transform: translateX(-10px);
}

.go-back a {
    display: block;
    width: 32px;
}

.images-block {
    min-width: 40%;
    max-width: 40%;
    min-width: 500px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    margin-left: 30px;

}

.main-image-container {
    position: relative;
    width: 90%;
    height: 100%;
    max-height: 600px;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    border-radius: 20px;

}

.main-image {
    width: 100%;
    height: 100%;
    min-height: 100%;
    max-height: 100%;

    display: flex;
    flex-direction: row;
    flex-basis: 100%;

}

.main-image img {
    min-width: 100%;
    max-width: 100%;
    max-height: 100%;
    max-height: 600px;
    object-fit: cover;
    transition: all 0.5s ease-in-out;

}

.main-image video {
    min-width: 100%;
    max-width: 100%;
    max-height: 100%;
    max-height: 600px;
    object-fit: cover;
    transition: all 0.5s ease-in-out;

}

.current-image {
    width: 100%;
    height: 50px;
    position: absolute;
    background: linear-gradient(to bottom, transparent, rgba(21, 21, 21, 0.897));
    left: 0px;
    top: calc(100% - 50px);
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.current-image-indicator-active {
    background-color: white !important;
}

.current-image-indicator {
    width: 10px;
    height: 10px;
    background-color: rgba(255, 255, 255, 0.511);
    border-radius: 50%;
    margin: 3px;
    margin-bottom: 10px;
}

.click-left {
    position: absolute;
    height: 100%;
    width: 50%;
}

.click-right {
    right: 0;
    position: absolute;
    height: 100%;
    width: 50%;
}


.image-add-btn {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.image-add-btn button {
    padding: 10px;
    border-radius: 10px;
    border: 2px solid gray;
    background-color: rgba(255, 255, 255, 0.834);
    margin-bottom: 30px;
    color: #0c022c;
    width: 200px;
    cursor: pointer;
    box-shadow: 5px 5px 10px #0c022c3e;
    transition: all 0.5s ease-in-out;
}

.image-add-btn button:hover {
    background-color: #0c022c;
    border: 2px solid rgb(255, 255, 255);
    color: white;
}



.text-block {
    display: flex;
    flex-direction: column;
    padding-left: 30px;
    padding-right: 50px;
    height: 100%;
    line-height: 1.5;
    white-space: pre-line;
}

.title {
    width: 90%;
    height: 30px;

}


.description-block {
    background-color: rgba(255, 255, 255, 0.529);
    padding: 30px;
    padding-top: 0;
    border-radius: 10px;
    overflow: hidden;
    transition: all 0.5s ease-in-out;

}


.params {
    display: flex;
    flex-direction: row;
}

.service-inscription {
    text-align: center;
}

.params div {
    padding: 20px;
    border-bottom: none;
    flex-basis: 50%;
}

.params div:first-child {
    border-right: 1px solid gray;
}

.bold {
    font-weight: bold;
    padding-left: 5px;
}

.text-description {
    color: rgb(32, 32, 32);
    height: 150px;
}

.buy-block {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}



.price {
    margin-right: 40px;
}

.product-recomendations {
    display: flex;
    align-items: flex-start;
    position: relative;
}

.recomendations-btn {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    min-width: 100%;
    position: absolute;
    transition: all 0.5s ease-in-out;
}

.recomendations-btn p {
    transition: all 0.5s ease-in-out;
}

.recomendations-btn p:hover {
    margin-right: 10px;
}

.recomendations-btn img {
    height: 30px;
    transition: 0.5s ease-in-out;
}

.recomendations-btn-hidden {
    transform: translateX(150%);
}

.recomendations-text {
    border: 1px solid rgba(128, 128, 128, 0.415);
    min-width: 100%;
    height: 180px;
    color: rgb(32, 32, 32);
    transition: all 0.5s ease-in-out;
    padding: 20px;
    border-radius: 10px;
    background-color: rgb(13, 6, 72);
    color: white;
    display: flex;
    justify-content: space-between;
    padding-left: 0;
    align-items: center;
}

.recomendations-text img {
    height: 20px;
    margin-right: 20px;
    margin-left: 10px;
    cursor: pointer;
    transition: 0.5s ease-in-out;
}

.recomendations-btn img:hover {
    transform: translateX(5px);
}

.recomendations-text img:hover {
    transform: translateX(-5px);
}

.recomendations-hidden {
    height: 30px;
    transform: translateX(-120%);
}
</style>
  