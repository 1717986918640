import DiscountDataService from "@/service/DiscountDataService";
import ProductDataService from "@/service/ProductDataService";



// initial state
const state = () => ({
    all: []
})

// getters
const getters = {

}



// actions
const actions = {


    async getAll({ commit }) {

        let items = [];
        await DiscountDataService.getAll().then((res) => {


            res.data.forEach(item => {
                let storeItem = new Object();
                let productsList = item.products.split(",");
                let productsObjectList = [];

                productsList.forEach(product => {
                    ProductDataService.getItem(product).then((resd) => {

                        let itemProduct = new Object();
                        let itemImages = [];

                        resd.data.images.split(" $;$ ").forEach(image => {

                            let imageItem = new Object();
                            imageItem.type = image.slice(1, 4);
                            imageItem.src = image.slice(5, image.length);

                            if (imageItem.src != "") {
                                itemImages.push(imageItem)
                            }

                        });

                        itemProduct.id = resd.data.id;
                        itemProduct.name = resd.data.name.replace(/&quot;/g, '"');
                        itemProduct.position = resd.data.position;
                        itemProduct.description = resd.data.description;
                        itemProduct.size = resd.data.size;
                        itemProduct.composition = resd.data.composition;
                        itemProduct.price = resd.data.price;
                        itemProduct.discountPrice = resd.data.discountPrice;
                        itemProduct.category = resd.data.category;
                        itemProduct.images = itemImages;

                        productsObjectList.push(itemProduct);
                    });
                });
                storeItem.id = item.id;
                storeItem.title = item.title;
                storeItem.products = productsObjectList;
                storeItem.productsLength = productsList.length;
                items.push(storeItem);
            });
        });
        await commit('setItems', items)

    },
    async updateTitle({ commit }, record) {
        await DiscountDataService.updateTitle({
            id: record.id,
            title: record.title
        }).then(() => {
            commit('setTitleUpdate', record)
        });
    },
    async updateProducts({ commit }, record) {
        console.log(record.productsObjects);
        await DiscountDataService.updateProducts({
            id: record.id,
            products: record.products
        }).then(() => {
            commit('setRecordsUpdate', record.productsObjects)
        });
    },
}

// mutations
const mutations = {
    setItems(state, discount) {
        state.all = discount;
    },

    setTitleUpdate(state, record) {
        state.all[0].title = record.title;
    },

    setRecordsUpdate(state, items) {
        console.log(items);
        state.all[0].products = items;
    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}